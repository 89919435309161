import * as React from "react";
import { FormInputProps, Segment } from "semantic-ui-react";
import * as Yup from "yup";

import { FormField } from "../components/SimpleForm/FormField";

interface IPasswordFieldProps extends FormInputProps {
  schema: Yup.StringSchema<string>;
}

function validatePolicy(
  schema: Yup.StringSchema<string>,
  pw: string
): string[] {
  try {
    schema.validateSync(pw, { abortEarly: false });
  } catch (e : any) {
    return e.errors;
  }
  return [];
}

function getColor(errors: number) {
  if (errors === 0) return "green";
  return "orange";
}

export const PasswordField: React.FC<IPasswordFieldProps> = ({
  schema,
  ...props
}) => {
  const errors = validatePolicy(schema, props.value);
  return (
    <>
      <FormField {...props} />
      {errors.length < 6 && props.value && (
        <Segment
          style={{ padding: "0.5em" }}
          inverted
          color={getColor(errors.length)}
        >
          {errors.length === 0 ? "OK " : props.value ? "zu schwach" : ""}
        </Segment>
      )}
    </>
  );
};