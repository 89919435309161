/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AbwesenheitsModel,
    AbwesenheitsModelFromJSON,
    AbwesenheitsModelFromJSONTyped,
    AbwesenheitsModelToJSON,
    Tagesstempel,
    TagesstempelFromJSON,
    TagesstempelFromJSONTyped,
    TagesstempelToJSON,
} from './';

/**
 * Liste aller Mitarbeiter mit zugeordneten Abwesenheiten für Mobile
 * @export
 * @interface MobileAbwesenheitsUebersichtModel
 */
export interface MobileAbwesenheitsUebersichtModel {
    /**
     * Woche im gewählten Monat
     * @type {number}
     * @memberof MobileAbwesenheitsUebersichtModel
     */
    woche?: number;
    /**
     * Tage pro Woche
     * @type {Array<string>}
     * @memberof MobileAbwesenheitsUebersichtModel
     */
    tage?: Array<string> | null;
    /**
     * Alle Abwesenheiten aller Mitarbeiter
     * @type {Array<AbwesenheitsModel>}
     * @memberof MobileAbwesenheitsUebersichtModel
     */
    abwesenheiten?: Array<AbwesenheitsModel> | null;
    /**
     * Gesetzte Tagesstempel
     * @type {Array<Tagesstempel>}
     * @memberof MobileAbwesenheitsUebersichtModel
     */
    tagesstempel?: Array<Tagesstempel> | null;
    /**
     * Filterung im Web anzeigen
     * @type {boolean}
     * @memberof MobileAbwesenheitsUebersichtModel
     */
    hasAbteilung?: boolean;
    /**
     * Filterung im Web anzeigen
     * @type {boolean}
     * @memberof MobileAbwesenheitsUebersichtModel
     */
    hasStandort?: boolean;
    /**
     * Filterung im Web deaktivieren
     * @type {boolean}
     * @memberof MobileAbwesenheitsUebersichtModel
     */
    showAll?: boolean;
}

export function MobileAbwesenheitsUebersichtModelFromJSON(json: any): MobileAbwesenheitsUebersichtModel {
    return MobileAbwesenheitsUebersichtModelFromJSONTyped(json, false);
}

export function MobileAbwesenheitsUebersichtModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileAbwesenheitsUebersichtModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'woche': !exists(json, 'woche') ? undefined : json['woche'],
        'tage': !exists(json, 'tage') ? undefined : json['tage'],
        'abwesenheiten': !exists(json, 'abwesenheiten') ? undefined : (json['abwesenheiten'] === null ? null : (json['abwesenheiten'] as Array<any>).map(AbwesenheitsModelFromJSON)),
        'tagesstempel': !exists(json, 'tagesstempel') ? undefined : (json['tagesstempel'] === null ? null : (json['tagesstempel'] as Array<any>).map(TagesstempelFromJSON)),
        'hasAbteilung': !exists(json, 'hasAbteilung') ? undefined : json['hasAbteilung'],
        'hasStandort': !exists(json, 'hasStandort') ? undefined : json['hasStandort'],
        'showAll': !exists(json, 'showAll') ? undefined : json['showAll'],
    };
}

export function MobileAbwesenheitsUebersichtModelToJSON(value?: MobileAbwesenheitsUebersichtModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'woche': value.woche,
        'tage': value.tage,
        'abwesenheiten': value.abwesenheiten === undefined ? undefined : (value.abwesenheiten === null ? null : (value.abwesenheiten as Array<any>).map(AbwesenheitsModelToJSON)),
        'tagesstempel': value.tagesstempel === undefined ? undefined : (value.tagesstempel === null ? null : (value.tagesstempel as Array<any>).map(TagesstempelToJSON)),
        'hasAbteilung': value.hasAbteilung,
        'hasStandort': value.hasStandort,
        'showAll': value.showAll,
    };
}


