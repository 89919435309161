import * as React from "react";
import { Message, MessageProps, Transition } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";

interface IToastMessageProps {
  appearance: "success" | "error" | "warning" | "info";
  onDismiss: () => void;
  isRunning: boolean;
  autoDismiss: boolean;
  autoDismissTimeout: number;
  children: MessageProps;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

function getIcon(
  appearance: "success" | "error" | "warning" | "info"
): SemanticICONS | undefined {
  if (appearance === "success") return "checkmark";
  if (appearance === "error") return "times";
  if (appearance === "info") return "info";
  if (appearance === "warning") return "warning";
  return undefined;
}

export const ToastMessage = (props: IToastMessageProps) => {
  const [visible, setVisible] = React.useState(false);
  React.useEffect(() => {
    setVisible(true);
    return () => {
      setVisible(false);
    };
  }, []);
  return (
    <Transition visible={visible} duration={300}>
      <div className="show" style={{ marginBottom: "1em" }}>
        <Message
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
          onDismiss={props.onDismiss}
          positive={props.appearance === "success"}
          error={props.appearance === "error"}
          info={props.appearance === "info"}
          warning={props.appearance === "warning"}
          header={props.children}
          style={{ width: 390 }} // TODO style
          icon={props.children.icon || getIcon(props.appearance)}
          {...props.children}
        />
      </div>
    </Transition>
  );
};
