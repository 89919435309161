/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    TagStempel,
    TagStempelFromJSON,
    TagStempelToJSON,
} from '../models';

export interface GetZeitstempelTagGETRequest {
    date?: Date;
}

export interface StempelErfassenV2POSTRequest {
    tagStempel: TagStempel;
    date?: Date;
}

/**
 * 
 */
export class ZeitstempelApi extends runtime.BaseAPI {

    /**
     * Liefert alle Zeitstempel des heutigen Tages als Paar
     */
    async getZeitstempelTagGETRaw(requestParameters: GetZeitstempelTagGETRequest): Promise<runtime.ApiResponse<TagStempel>> {
        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Zeitstempel/GetZeitstempelTag`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TagStempelFromJSON(jsonValue));
    }

    /**
     * Liefert alle Zeitstempel des heutigen Tages als Paar
     */
    async getZeitstempelTagGET(requestParameters: GetZeitstempelTagGETRequest): Promise<TagStempel> {
        const response = await this.getZeitstempelTagGETRaw(requestParameters);
        return await response.value();
    }

    /**
     * Add, Delete, Modify für alle Zeitstempel
     */
    async stempelErfassenV2POSTRaw(requestParameters: StempelErfassenV2POSTRequest): Promise<runtime.ApiResponse<TagStempel>> {
        if (requestParameters.tagStempel === null || requestParameters.tagStempel === undefined) {
            throw new runtime.RequiredError('tagStempel','Required parameter requestParameters.tagStempel was null or undefined when calling stempelErfassenV2POST.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Zeitstempel/StempelErfassenV2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TagStempelToJSON(requestParameters.tagStempel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TagStempelFromJSON(jsonValue));
    }

    /**
     * Add, Delete, Modify für alle Zeitstempel
     */
    async stempelErfassenV2POST(requestParameters: StempelErfassenV2POSTRequest): Promise<TagStempel> {
        const response = await this.stempelErfassenV2POSTRaw(requestParameters);
        return await response.value();
    }

}
