import { UserManager, WebStorageStateStore } from "oidc-client";
import StorageFactory from './utils/StorageFactory';

const IDENTITY_CONFIG = {
  authority: window.location.origin /*process.env.REACT_APP_AUTH_URL*/, //(string): The URL of the OIDC provider.
  client_id: 'login', //process.env.REACT_APP_IDENTITY_CLIENT_ID, //(string): Your client application's identifier as registered with the OIDC provider.
  redirect_uri: window.location.origin + "/signin-oidc" /*process.env.REACT_APP_REDIRECT_URL*/, //The URI of your client application to receive a response from the OIDC provider.
  login: window.location.origin /*process.env.REACT_APP_AUTH_URL*/ + "/login",
  automaticSilentRenew: true, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
  loadUserInfo: false, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
  silent_redirect_uri: window.location.origin + "/index.html" /*process.env.REACT_APP_SILENT_REDIRECT_URL */, //(string): The URL for the page containing the code handling the silent renew.
  post_logout_redirect_uri: window.location.origin + "/index.html" /*process.env.REACT_APP_LOGOFF_REDIRECT_URL*/, // (string): The OIDC post-logout redirect URI.
  audience: "https://timeWatch.cool-IT.at", //is there a way to specific the audience when making the jwt
  //response_type: "id_token token", //(string, default: 'id_token'): The type of response desired from the OIDC provider.
  response_type: "code",
  //grantType: "password",
  scope: "openid offline_access", //timeWatch.api //(string, default: 'openid'): The scope being requested from the OIDC provider.
  webAuthResponseType: "id_token token"
};

const METADATA_OIDC = {
  issuer: window.location.origin, //"https://identityserver",
  jwks_uri: window.location.origin /*process.env.REACT_APP_AUTH_URL*/ + "/.well-known/openid-configuration/jwks",
  authorization_endpoint: window.location.origin /*process.env.REACT_APP_AUTH_URL*/ + "/connect/authorize",
  token_endpoint: window.location.origin /*process.env.REACT_APP_AUTH_URL*/ + "/connect/token",
  userinfo_endpoint: window.location.origin /*process.env.REACT_APP_AUTH_URL*/ + "/connect/userinfo",
  end_session_endpoint: window.location.origin /*process.env.REACT_APP_AUTH_URL*/ + "/connect/endsession",
  check_session_iframe: window.location.origin /*process.env.REACT_APP_AUTH_URL*/ + "/connect/checksession",
  revocation_endpoint: window.location.origin /*process.env.REACT_APP_AUTH_URL*/ + "/connect/revocation",
  introspection_endpoint: window.location.origin /*process.env.REACT_APP_AUTH_URL*/ + "/connect/introspect"
};

const store = StorageFactory.getStorage();

export const userManager = new UserManager({
  ...IDENTITY_CONFIG,
  userStore: new WebStorageStateStore({ store: store }),
  stateStore: new WebStorageStateStore({ store: store }),
  metadata: {
    ...METADATA_OIDC
  }
});

export default class AuthService {
  UserManager;

  constructor() {
    this.UserManager = userManager;
    // Logger
    //Log.logger = console;
    //Log.level = Log.DEBUG;
    this.UserManager.events.addUserLoaded((user) => {
      if (window.location.href.indexOf("signin-oidc") !== -1) {
        this.navigateToScreen();
      }
    });
    this.UserManager.events.addSilentRenewError((e) => {
      console.log("silent renew error", e.message);
      this.signinRedirect();
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      console.log("token expired");
      this.signinSilent();
    });

    this.UserManager.events.addAccessTokenExpiring(() => {
      console.log("token expiring");
    });
  }

  signinRedirectCallback = async () => {
    console.log("redirect callback")
    try {
      await this.UserManager.signinRedirectCallback();
    } catch (e) {
      console.log("error redirect callback")
      this.UserManager.clearStaleState();
      this.navigateToScreen();
    }
  };


  getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      
      return await this.UserManager.signinRedirectCallback();
    }
    return user;
  };

  parseJwt = (token: string) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    var jwt = JSON.parse(window.atob(base64));
    console.log(jwt);
    return jwt;
  };


  signinRedirect = () => {

    localStorage.setItem("redirectUri", window.location.href);
    this.UserManager.signinRedirect({});
  };


  navigateToScreen = () => {
    //    window.location.replace("/en/dashboard");
    const redirectUri = localStorage.getItem("redirectUri");
    if (redirectUri) {
      localStorage.removeItem("redirectUri");
      window.location.replace(redirectUri);

    }
    else {
      window.location.replace("/");

    }
  };


  isAuthenticated = () => {
    //const oidcStorage = JSON.parse(sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`)!)
    var item = store.getItem(`oidc.user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`)
    const oidcStorage = item && JSON.parse(item)
    //alert(`oidc.user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`);
    //alert(!!oidcStorage && oidcStorage.profile);

    return (!!oidcStorage && !!oidcStorage.profile) //oidcStorage.access_token
  };

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then((user) => {
        console.log("signed in", user);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem("id_token")
    });
    this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace(process.env.REACT_APP_PUBLIC_URL!);
    });
    this.UserManager.clearStaleState();
  };
}