/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ZeitstempelModel,
    ZeitstempelModelFromJSON,
    ZeitstempelModelFromJSONTyped,
    ZeitstempelModelToJSON,
} from './';

/**
 * Zeitstempeln als Paar
 * @export
 * @interface ZeitstempelRow
 */
export interface ZeitstempelRow {
    /**
     * 
     * @type {ZeitstempelModel}
     * @memberof ZeitstempelRow
     */
    von?: ZeitstempelModel;
    /**
     * 
     * @type {ZeitstempelModel}
     * @memberof ZeitstempelRow
     */
    bis?: ZeitstempelModel;
}

export function ZeitstempelRowFromJSON(json: any): ZeitstempelRow {
    return ZeitstempelRowFromJSONTyped(json, false);
}

export function ZeitstempelRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZeitstempelRow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'von': !exists(json, 'von') ? undefined : ZeitstempelModelFromJSON(json['von']),
        'bis': !exists(json, 'bis') ? undefined : ZeitstempelModelFromJSON(json['bis']),
    };
}

export function ZeitstempelRowToJSON(value?: ZeitstempelRow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'von': ZeitstempelModelToJSON(value.von),
        'bis': ZeitstempelModelToJSON(value.bis),
    };
}


