/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AbwesenheitsAnfrageModel,
    AbwesenheitsAnfrageModelFromJSON,
    AbwesenheitsAnfrageModelToJSON,
    AbwesenheitsTypenModel,
    AbwesenheitsTypenModelFromJSON,
    AbwesenheitsTypenModelToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    UrlaubsanfrageModel,
    UrlaubsanfrageModelFromJSON,
    UrlaubsanfrageModelToJSON,
} from '../models';

export interface SetUrlaubsanfragePOSTRequest {
    del?: boolean;
    urlaubsanfrageModel?: UrlaubsanfrageModel;
}

/**
 * 
 */
export class UrlaubsanfrageApi extends runtime.BaseAPI {

    /**
     * API für Abwesenheitstypen
     */
    async getAbwesenheitsTypenGETRaw(): Promise<runtime.ApiResponse<Array<AbwesenheitsTypenModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Urlaubsanfrage/GetAbwesenheitsTypen`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AbwesenheitsTypenModelFromJSON));
    }

    /**
     * API für Abwesenheitstypen
     */
    async getAbwesenheitsTypenGET(): Promise<Array<AbwesenheitsTypenModel>> {
        const response = await this.getAbwesenheitsTypenGETRaw();
        return await response.value();
    }

    /**
     * API zum liefern von Urlaubsanfragen vom eingeloggten Benutzer
     */
    async getUrlaubsanfragenGETRaw(): Promise<runtime.ApiResponse<AbwesenheitsAnfrageModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Urlaubsanfrage/GetUrlaubsanfragen`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AbwesenheitsAnfrageModelFromJSON(jsonValue));
    }

    /**
     * API zum liefern von Urlaubsanfragen vom eingeloggten Benutzer
     */
    async getUrlaubsanfragenGET(): Promise<AbwesenheitsAnfrageModel> {
        const response = await this.getUrlaubsanfragenGETRaw();
        return await response.value();
    }

    /**
     * API zum einfügen einer Urlaubsanfrage
     */
    async setUrlaubsanfragePOSTRaw(requestParameters: SetUrlaubsanfragePOSTRequest): Promise<runtime.ApiResponse<AbwesenheitsAnfrageModel>> {
        const queryParameters: any = {};

        if (requestParameters.del !== undefined) {
            queryParameters['del'] = requestParameters.del;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Urlaubsanfrage/SetUrlaubsanfrage`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UrlaubsanfrageModelToJSON(requestParameters.urlaubsanfrageModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AbwesenheitsAnfrageModelFromJSON(jsonValue));
    }

    /**
     * API zum einfügen einer Urlaubsanfrage
     */
    async setUrlaubsanfragePOST(requestParameters: SetUrlaubsanfragePOSTRequest): Promise<AbwesenheitsAnfrageModel> {
        const response = await this.setUrlaubsanfragePOSTRaw(requestParameters);
        return await response.value();
    }

}
