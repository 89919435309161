import * as React from "react";
import { Button, Input } from 'semantic-ui-react';
import { ProjektKontierungModified } from "../../generated";
import { numberToStringTime, stringTimeToNumber } from "../Helper";

interface IKontierungInputProps {
  projekt: ProjektKontierungModified;
  projekte: ProjektKontierungModified[];
  index: number;
  isReadOnly: boolean;
  fieldName: string;
  restZeit: string;
  tagesSaldo: number;
  datum: Date;
  rows: number;
  rowsUsed: number;
  vonDatum: Date | null | undefined;
  bisDatum: Date | null | undefined;
  setfieldvalue: (field: string, value: any, shouldValidate?: boolean) => void;
}


export const KontierungInput: React.FC<IKontierungInputProps> = (props) => {
  return (
    <>
      <Input
        value={(typeof props.projekt.anzahlMinuten! === 'number' ?
          numberToStringTime(props.projekt.anzahlMinuten!)
          : (props.projekt.anzahlMinuten!.toString().includes(':') && props.projekt.anzahlMinuten!.toString().length === 5)
            ? numberToStringTime(stringTimeToNumber(props.projekt.anzahlMinuten!.toString()))
            : props.projekt.anzahlMinuten!.toString())}
        name={props.fieldName}
        readOnly={props.isReadOnly}
        placeholder={props.isReadOnly ? "" : "Stunden"}
        type="text"
        style={props.projekt.anmerkung !== undefined &&
          props.projekt.anmerkung && (props.projekt.anmerkung.length > 0) &&
          ((!props.projekt.anzahlMinuten ||
          (props.projekt.anzahlMinuten && (props.projekt.anzahlMinuten === 0)) ||
          (props.projekt.anzahlMinuten.toString() === '00:00') ||
          (props.projekt.anzahlMinuten.toString() === '0')))
          ? { border: "solid 2px red", width: '5.71em' } : { width: '5.71em' }}
        onBlur={
          () => {
            let valueString = '00:00';
            if (props.projekt.anzahlMinuten && (typeof props.projekt.anzahlMinuten! === 'string')) {
              valueString = numberToStringTime(stringTimeToNumber(props.projekt.anzahlMinuten));
              props.setfieldvalue(props.fieldName, valueString !== '00:00' ? valueString : '00:00');
              if (valueString !== '00:00') {
                props.projekte[props.index].anzahlMinuten = stringTimeToNumber(valueString);
              }
            }
            else {              
              if (typeof props.projekt.anzahlMinuten === 'string') {
                props.projekte[props.index].anzahlMinuten = stringTimeToNumber(props.projekt.anzahlMinuten);
                props.setfieldvalue(props.fieldName, props.projekt.anzahlMinuten ? props.projekt.anzahlMinuten : '00:00');
              }
            }
          }          
        }
        onChange={(e, data) => {
          var value = data.value;
          if (data.value.length > 5) {
            value = value.substring(0, 5);
          }
          props.setfieldvalue(data.name, value);
        }} />
      {!props.isReadOnly && <Button
        floated='right'
        secondary
        className={"row-button"}
        data-tooltip={"Saldo anpassen"}
        icon={"hourglass outline"}
        size={'tiny'}
        type={'button'}
        onClick={() => {
          var nope = true;
          if (nope) {

          }
          var restZeit = stringTimeToNumber(props.restZeit);
          var projectMinuten = 0;
          var anzahlMinuten = 0;
          var alleProjekte = 0;
          var restKontieren = '';
          
          props.projekte.forEach(f => alleProjekte +=
            f.anzahlMinuten ? (typeof f.anzahlMinuten === 'string') ? stringTimeToNumber(f.anzahlMinuten) :
              f.anzahlMinuten : 0);
          var stundenAlsMinuten = 0;
          var heute = new Date();
          var isToday = ((heute.getMonth() === props.datum.getMonth()) && heute.getDay() === props.datum.getDay());
          // Stempel von vorhanden aber bis nicht vorhanden
          var now = (!isToday && props.bisDatum) ? props.bisDatum : props.bisDatum ? props.bisDatum : new Date();
          if (props.vonDatum) {
            stundenAlsMinuten = (now.getHours() - props.vonDatum.getHours()) * 60;
            anzahlMinuten = (now.getMinutes() - props.vonDatum.getMinutes()) + stundenAlsMinuten;
          }
          projectMinuten = props.projekt.anzahlMinuten ?
            (typeof props.projekt.anzahlMinuten === 'string') ?
              stringTimeToNumber(props.projekt.anzahlMinuten) :
              props.projekt.anzahlMinuten as number : 0;
          var keineKontierung = (props.tagesSaldo && (alleProjekte === 0));
          if (!keineKontierung) {
            var differenz = 0;
          //Fall 1 + 2 keine Ist Zeit aber ein Kommen stempel und kein gehen stempel / ein gehen Stempel
          //props Tagessaldo === 0,  props.vonDatum !== null && !== undefined, props.bisDatum === null || undefined
            if ((props.tagesSaldo === 0) && props.vonDatum) {

              differenz = (anzahlMinuten - alleProjekte);
              if (differenz > 0) {
                restKontieren = numberToStringTime(differenz + projectMinuten);
              }
              else if (differenz < 0) {
                differenz = (differenz + projectMinuten);
                restKontieren = numberToStringTime(differenz);
              }
              else {
                restKontieren = numberToStringTime(projectMinuten);
              }
          }
          //Fall 3 NUR eine Ist Zeit und keine zusätzlichen kommen und gehen Stempel (row === 1).
          //props Tagessaldo > 0,  props.vonDatum !== null && !== undefined, props.bisDatum !== null && !== undefined
          else if ((props.tagesSaldo > 0) && props.rowsUsed === props.rows) {
            differenz = (alleProjekte - props.tagesSaldo);
            if (differenz > 0) {
              differenz = projectMinuten > 0 ? projectMinuten - differenz : projectMinuten;
              restKontieren = numberToStringTime(differenz);
            }
            else if (differenz < 0) {
              differenz = (props.tagesSaldo - alleProjekte) + projectMinuten;
              restKontieren = numberToStringTime(differenz);
            }
            else {
              restKontieren = numberToStringTime(projectMinuten);
            }
          }
          //Fall 4 + 5 eine Ist Zeit und einen zusätzlichen kommen aber keinen gehen Stempel.
          //props Tagessaldo > 0,  props.vonDatum !== null && !== undefined, props.bisDatum === null || undefined
          else if ((props.tagesSaldo >= 0) && props.vonDatum && (props.rows !== props.rowsUsed)) {
             var gesamt = props.tagesSaldo + anzahlMinuten;
              differenz = gesamt - alleProjekte;
              if (differenz > 0) {
                restKontieren = numberToStringTime((projectMinuten + differenz));
              }
              else if (differenz < 0) {
                restKontieren = numberToStringTime((differenz + projectMinuten));
              }
              else {
                restKontieren = numberToStringTime(projectMinuten);
              }

          }
          else {
            var kontierungsZeit = 
              props.projekt.anzahlMinuten ?
                (typeof props.projekt.anzahlMinuten === 'string') ?
                  stringTimeToNumber(props.projekt.anzahlMinuten) :
                  props.projekt.anzahlMinuten as number : 0;
            restKontieren = numberToStringTime(kontierungsZeit);
            }
          }
          //Wenn nur eine IstZeit aber keine zusätzlichen Werte alles Buchen TODO: um + row Time ergänzen
          else {
            restKontieren = numberToStringTime(restZeit);
          }
          //Wenn die Berechnung - ergibt -> auf 00:00 setzen. Es gibt keine - Kontierung
          if (restKontieren.includes('-')) {
            restKontieren = '00:00';
          }
          props.projekte[props.index].anzahlMinuten = stringTimeToNumber(restKontieren);
          props.setfieldvalue(props.fieldName, restKontieren);
        }}
      />}
    </>
  );
};
