import { useEffect } from "react";

const HB_VERWALTUNG_PREFIX = "Hilfe Verwaltung"
const HB_MITARBEITER_PREFIX = "Hilfe Mitarbeiter"

export const DOCUMENT_TITLES = {
    zeiterfassung: `coolTimeWatch`,
    kontierung: `Kontierungen`,
    info: `Info`, 
    abwesenheitsuebersicht: `Abwesenheitsübersicht`,
    abwesenheitsantrag: `Abwesenheitsantrag`, 
    arbeitsplan: `Arbeitsplan`, 
    leistungsnachweis:`Leistungsnachweis`, 
    monatsansicht: `Monatsansicht`,
    neuesPasswort: `Neues Passwort`,
}
export const VERWALTUNG_DOCUMENT_TITLES = {
    index: `${HB_VERWALTUNG_PREFIX} - Übersicht`,
    abwesenheiten: `${HB_VERWALTUNG_PREFIX} - Abwesenheiten`,
    arbeitszeiten: `${HB_VERWALTUNG_PREFIX} - Arbeitszeiten`,
    auswertungen: `${HB_VERWALTUNG_PREFIX} - Auswertungen`,
    ersteSchritte: `${HB_VERWALTUNG_PREFIX} - Erste Schritte`,
    leistungsnachweis: `${HB_VERWALTUNG_PREFIX} - Leistungsnachweise`,
    mitarbeiter: `${HB_VERWALTUNG_PREFIX} - Mitarbeiter`,
    montage: `${HB_VERWALTUNG_PREFIX} - Montage`,
    projekte: `${HB_VERWALTUNG_PREFIX} - Projekte`,
    planung: `${HB_VERWALTUNG_PREFIX} - Ressourcenplanung`,
    stempel: `${HB_VERWALTUNG_PREFIX} - Stempel`,
    einstellungen: `${HB_VERWALTUNG_PREFIX} - Einstellungen`,
}
export const MITARBEITER_DOCUMENT_TITLES = {
    index: `${HB_MITARBEITER_PREFIX} - Übersicht`,
    abwesenheiten: `${HB_MITARBEITER_PREFIX} - Abwesenheiten`,
    abwesenheitsantrag: `${HB_MITARBEITER_PREFIX} - Abwesenheitsantrag`,
    arbeitsplan: `${HB_MITARBEITER_PREFIX} - Arbeitsplan`,
    kontierungen: `${HB_MITARBEITER_PREFIX} - Kontierungen`,
    leistungsnachweis: `${HB_MITARBEITER_PREFIX} - Leistungsnachweise`,
    monatsansicht: `${HB_MITARBEITER_PREFIX} - Monatsansicht`,
    sonstiges: `${HB_MITARBEITER_PREFIX} - Sonstiges`,
    zeiterfassung: `${HB_MITARBEITER_PREFIX} - Zeiterfassung`,
}

export default function useDocumentTitle(title:string){
    useEffect(() => {
        document.title = title
    }, [title])
}