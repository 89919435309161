import * as Yup from "yup";

import { SimpleForm } from "./Simpleform";
import {
  SimpleFormCheckbox,
  SimpleFormCheckBoxFromOptions,
  SimpleFormCheckboxYN,
  SimpleFormDatePicker,
  SimpleFormDatePickerUrlaub,
  SimpleFormDateRangePicker,
  SimpleFormDropdown,
  SimpleFormFieldArray,
  formLabel,
  formInput,
  formHeader
} from "./FormComponents";
import { ColProps } from "react-flexbox-grid";

export interface optionsItem {
  key: number;
  text: string;
  value: string;
  colSize: ColProps;
}

export interface IFormField {
  label?: string;
  props: IFormFieldProps;
  validation?: Yup.Schema<unknown> | Yup.Ref;
  component?: any;
  fields?: IFormField[];
  colSize?: ColProps;
  fieldArrayFields?: IFormField[];
}

export interface IFormFieldProps {
  [propName: string]: any;
}


export default SimpleForm;
export {
  SimpleFormCheckbox,
  SimpleFormCheckBoxFromOptions,
  SimpleFormCheckboxYN,
  SimpleFormDatePicker,
  SimpleFormDatePickerUrlaub,
  SimpleFormDateRangePicker,
  SimpleFormDropdown,
  SimpleFormFieldArray,
  formLabel,
  formInput,
  formHeader
};
