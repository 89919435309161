/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EOptions {
    None = 'None',
    Projekte = 'Projekte',
    Taggeld = 'Taggeld',
    Montage = 'Montage',
    RessourcenPlanung = 'RessourcenPlanung',
    Leistungsnachweise = 'Leistungsnachweise',
    GPSDaten = 'GPSDaten',
    ZeitzettelInfo = 'ZeitzettelInfo'
}

export function EOptionsFromJSON(json: any): EOptions {
    return EOptionsFromJSONTyped(json, false);
}

export function EOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): EOptions {
    return json as EOptions;
}

export function EOptionsToJSON(value?: EOptions | null): any {
    return value as any;
}

