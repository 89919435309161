import Cookies from 'js-cookie';

const options: any = {
  secure: true,
  sameSite: "Strict"
};

export default class CookieStorage {
  setItem = (key: string, value: string) => Cookies.set(key, value, options);

  getItem = (key: string) => Cookies.get(key);

  removeItem = (key: string) => Cookies.remove(key, options);

  key = (index: number) => {
    let allKeys = Object.keys(Cookies.get());
    return index > -1 && index <= allKeys.length
      ? allKeys[index]
      : '';
  };

}