/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AbwesenheitsInfo,
    AbwesenheitsInfoFromJSON,
    AbwesenheitsInfoFromJSONTyped,
    AbwesenheitsInfoToJSON,
    UrlaubsanfrageModel,
    UrlaubsanfrageModelFromJSON,
    UrlaubsanfrageModelFromJSONTyped,
    UrlaubsanfrageModelToJSON,
} from './';

/**
 * Info und Liste der Anträge eines Mitarbeiters
 * @export
 * @interface AbwesenheitsAnfrageModel
 */
export interface AbwesenheitsAnfrageModel {
    /**
     * 
     * @type {AbwesenheitsInfo}
     * @memberof AbwesenheitsAnfrageModel
     */
    info?: AbwesenheitsInfo;
    /**
     * Liste aller Urlaubsanfragen(Urlaub, Sonderurlaub, Zeitausgleich,...)
     * @type {Array<UrlaubsanfrageModel>}
     * @memberof AbwesenheitsAnfrageModel
     */
    urlaube?: Array<UrlaubsanfrageModel> | null;
}

export function AbwesenheitsAnfrageModelFromJSON(json: any): AbwesenheitsAnfrageModel {
    return AbwesenheitsAnfrageModelFromJSONTyped(json, false);
}

export function AbwesenheitsAnfrageModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AbwesenheitsAnfrageModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'info': !exists(json, 'info') ? undefined : AbwesenheitsInfoFromJSON(json['info']),
        'urlaube': !exists(json, 'urlaube') ? undefined : (json['urlaube'] === null ? null : (json['urlaube'] as Array<any>).map(UrlaubsanfrageModelFromJSON)),
    };
}

export function AbwesenheitsAnfrageModelToJSON(value?: AbwesenheitsAnfrageModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'info': AbwesenheitsInfoToJSON(value.info),
        'urlaube': value.urlaube === undefined ? undefined : (value.urlaube === null ? null : (value.urlaube as Array<any>).map(UrlaubsanfrageModelToJSON)),
    };
}


