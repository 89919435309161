import * as React from 'react';
import { useQuery } from 'react-query';
import MitarbeiterApi from '../../api/MitarbeiterApi';
import { Header, Segment, Table, Icon, Popup, Button } from 'semantic-ui-react';
import { numberToStringTime } from "../Helper";
import CommonApi from '../../api/CommonApi';
import { EOptions } from '../../generated';
import { useHistory } from 'react-router-dom';
import { MonthPicker } from '../MonthPicker';
import useDocumentTitle, { DOCUMENT_TITLES } from '../../hooks/useDocumentTitle';

interface KontierungsuebersichtProps {
}

export const Kontierungsuebersicht: React.FC<KontierungsuebersichtProps> = (props) => {
  useDocumentTitle(DOCUMENT_TITLES.kontierung)
  const startFilter = new Date();
  let history = useHistory();
  const commonVersions = useQuery(["getVersionGET"],
    () => CommonApi.getVersionGET({clearCache: false}));
  const [monatsFilter, setMonatsFilter] = React.useState((startFilter.getMonth() + 1)); // Jänner Monat wäre 0 etc
  const [jahresFilter, setJahresFilter] = React.useState(startFilter.getFullYear());
  const [showAllDetails, setShowAllDetails] = React.useState(false);
  const [showDetails, setShowDetails] = React.useState(false);
  const [showProjectDetails, setShowProjectDetails] = React.useState(-1);

  function details(project: number) { 
    if (project === showProjectDetails) {
      setShowDetails(false);
      setShowProjectDetails(-1);
      setShowAllDetails(false);
    }
    else if (project >= 0) {
      setShowDetails(true);
      setShowProjectDetails(project);
      setShowAllDetails(false);
    }

  }
  const kontierungsUebersicht = useQuery(["getKontierungssuebersichtGET", jahresFilter, monatsFilter], () =>
    MitarbeiterApi.getKontierungsuebersichtGET({ jahr: jahresFilter, monat: monatsFilter })
  );

  const allDetails = () => {
    return (
      <Popup
        content={() => { return ('Details ein -/ ausblenden') }}
        trigger={<Icon name={showAllDetails ? 'arrow alternate circle up outline' :'arrow alternate circle down outline'}
          checked={showAllDetails}
          onClick={() => {
            let details = showAllDetails;
            setShowAllDetails(!details);
            setShowProjectDetails(-1);
          }}
        />}
      />
    );
  };
  if (!commonVersions.isLoading && commonVersions.data && commonVersions.data.options && !commonVersions.data.options.includes(EOptions.Projekte) ) {
    history.push({
      pathname: `/`
    });
  }

  return (
    <div>
      <MonthPicker 
        isLoading={kontierungsUebersicht.isLoading && commonVersions.isLoading}
        monatsFilter={monatsFilter}
        setMonatsFilter={setMonatsFilter}
        jahresFilter={jahresFilter}
        setJahresFilter={setJahresFilter}
        showAll={undefined}
        setShowAll={() => { } }
        title="Kontierungsübersicht">
      </MonthPicker>
      {kontierungsUebersicht.data && kontierungsUebersicht.data.kontierungen && kontierungsUebersicht.data.kontierungen.length > 0 ?
        <>
            <Table>
              <Table.Header>
                <Table.Row>
                <Table.HeaderCell collapsing>Projekt</Table.HeaderCell>
                <Table.HeaderCell collapsing>Tätigkeit</Table.HeaderCell>
                <Table.HeaderCell collapsing>Anmerkung</Table.HeaderCell>
                <Table.HeaderCell collapsing width='1'>Gebucht</Table.HeaderCell>
                <Table.HeaderCell collapsing width='1' textAlign='center'>{React.createElement(allDetails, [])}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {kontierungsUebersicht.data.kontierungen.map((kontierung, index: number) => {
                  return (
                    <>
                      <Table.Row style={{ backgroundColor: (index % 2) ? 'rgb(250 250 251)' : 'white' }}>                      
                          {kontierung.projektBeschreibung ?
                            <Popup
                              content={() => { return (kontierung.projektBeschreibung) }}
                              key={index}
                              trigger={<Table.Cell>{kontierung.projektname}</Table.Cell>}
                            />
                            : <Table.Cell>{kontierung.projektname}</Table.Cell>
                          }
                        {kontierung.taetigkeitBeschreibung ?
                          <Popup
                            content={() => { return (kontierung.taetigkeitBeschreibung) }}
                            key={index}
                            trigger={
                              <Table.Cell className={'nowrap'}>{kontierung.file ?
                                <img
                                  alt={kontierung.taetigkeitName ? kontierung.taetigkeitName : "Tätigkeit"}
                                  className={'tabletaetigkeiticon'}
                                  src={`data:${kontierung.contentType};base64,${kontierung.file}`}
                                />
                                : ''} {kontierung.taetigkeitName}</Table.Cell>
                            }
                          />
                          : <Table.Cell className={'nowrap'}>{kontierung.file ?
                            <img
                              alt={kontierung.taetigkeitName ? kontierung.taetigkeitName : "Tätigkeit"  }
                            className={'tabletaetigkeiticon'}
                            src={`data:${kontierung.contentType};base64,${kontierung.file}`}
                          />
                            : ''} {kontierung.taetigkeitName}</Table.Cell>
                          }
                        <Table.Cell>{kontierung.anmerkung}</Table.Cell>
                        <Table.Cell ><b>{numberToStringTime(kontierung.anzahlMinuten!)}</b></Table.Cell>
                        <Table.Cell data-tooltip={kontierung.detailKontierungen && kontierung.detailKontierungen.length > 0 ?
                          showAllDetails ? undefined : index === showProjectDetails ? "Details verbergen" : "Details anzeigen" : undefined}
                         textAlign='center' >{kontierung.detailKontierungen && kontierung.detailKontierungen.length > 0 && 
                            <Button
                          className="table-button"
                          size="mini"
                          icon={showAllDetails ? 'folder open' : index === showProjectDetails ? 'folder open outline' : 'folder outline'}
                          primary
                            onClick={() => details(index)}
                          />
                        }</Table.Cell>
                    </Table.Row>
                      {((showDetails && showProjectDetails === index) || (showAllDetails))
                        && kontierung.detailKontierungen && kontierung.detailKontierungen.length > 0 && kontierung.detailKontierungen.map((details, index: number) => {
                        return (
                          <Table.Row style={{ backgroundColor: 'rgba(0,0,0,.05)'}}>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell>{details.anmerkung}</Table.Cell>
                            <Table.Cell>{numberToStringTime(details.anzahlMinuten!)}</Table.Cell>
                            <Table.Cell ></Table.Cell>
                          </Table.Row>
                        )
                      })}
                      </>
                )})
                }
                <Table.Row className="border-top">
                  <Table.Cell colSpan="3" textAlign="left" >
                    <Header size="tiny" content="Gesamt" />
                  </Table.Cell>
                  <Table.Cell ><b>{numberToStringTime(kontierungsUebersicht.data.summe!)}</b></Table.Cell>
                  <Table.Cell ></Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
        </>
        : <Segment > <h3>Keine Kontierungen für diesen Zeitraum vorhanden.</h3> </Segment>
      }
    </div>
  )
}