import * as React from "react";
import CommonApi from "../api/CommonApi";
import { useQuery } from "react-query";
import { Button, Dropdown, Form, Grid, Header, Input, Segment, StrictMessageProps, TextArea, Label } from "semantic-ui-react";
import useMessages from "../hooks/useMessages";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import ProjektKontierungApi from "../api/ProjektKontierungApi";
import { Formik } from "formik";
import useAsyncEdit from "../hooks/useAsyncEdit";
import { EditLeistungsnachweisPOSTRequest, ELAttachmentTyp, EOptions, LeistungsnachweisModel } from "../generated";
import ReactSignatureCanvas from "react-signature-canvas";
import useDocumentTitle, { DOCUMENT_TITLES } from "../hooks/useDocumentTitle";

interface ILeistungsnachweisProps {
}
interface IDictaphoneProps {
  value?: string | null | undefined;
  fieldName?: string;
  dictKey?: string;
  setfieldvalue: (field: string, value: any, shouldValidate?: boolean) => void;
  readonly?: boolean;
  notiz: boolean;
  setNotiz: (notiz: boolean) => void;
  nachweis: boolean;
  setNachweis: (notiz: boolean) => void;
  hideNotiz?: (hide: boolean) => void;
  show?: boolean;
}
interface ISignatureProps {
  projectID?: string | null | undefined;
  ID?: string | null | undefined;
  setReadOnly: (readOnly: boolean) => void;
  name?: string | null;
  mail?: string | null;
  setMailError: (error: string) => void;
  setNameError: (error: string) => void;
  canSendMail?: boolean;
  reload: () => void;
  nachricht: { addMessage: (message: StrictMessageProps, appearance?: "error" | "success" | "warning" | "info", autoClose?: boolean, pauseOnHover?: boolean) => void };
}

const DictaphoneNotiz: React.FC<IDictaphoneProps> = (props) => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  if (!browserSupportsSpeechRecognition) {
    return <span>Ihr Browser unterstützt keine Spracherkennung.</span>;
  }

  const stop = async () => {
    await SpeechRecognition.stopListening();
    let newText = `${props.value} ${transcript}`;
    props.setfieldvalue(`${props.fieldName}`, newText);
    await props.setNachweis(false);
    await props.setNotiz(false);
    await resetTranscript();
  }
  const reset = async () => {
    await resetTranscript();
    props.setfieldvalue(`${props.fieldName}`, '');
  }
  const startListeningNotiz = async () => {
    await props.setNotiz(true);
    await props.setNachweis(false);
    if (transcript.length > 0) {
      await resetTranscript();
    }
    await SpeechRecognition.startListening({ continuous: true, language: 'de-DE' });
  };

  return (<>
    <div className={'dictGrid'} key={props.dictKey} style={{ display: 'flow-root' }}>
      
      <p>{listening ?
        <><Button hidden={props.show} icon={"microphone"} key={`${props.dictKey}Stop`} className="speechBtn" primary content="Notizen" type="button"
            onClick={stop} disabled={props.nachweis} /> </> :
        <><Button hidden={props.show} icon={"microphone slash"} key={`${props.dictKey}Start`} className="speechBtn" secondary content="Notizen" type="button"
          onClick={startListeningNotiz} disabled={props.nachweis} /> </>}
        <Button
          floated="right"
          type={'button'}
          className="table-button"
          size="mini"
          icon={props.show ? 'arrow alternate circle up' : 'arrow alternate circle down'}
          primary
          onClick={() => props.hideNotiz && props.hideNotiz(props.show !== undefined ? props.show : false)}
        />      </p>
      <Grid hidden={props.show} key={`${props.dictKey}fieldGrid`} rows="1">
          <Grid.Column computer="8" mobile="16">
            <div className="field">
              {props.notiz ?
                <TextArea
                  fluid={"true"}
                  key={`${props.dictKey}DictaphoneTrue`}
                  label={'Aufnahme'}
                  readOnly={true}
                  name={`${props.fieldName}`}
                  type="text"
                  rows={"6"}
                  value={transcript}
                  placeholder={'Starten Sie bitte die Aufnahme.'}
                  onChange={(e, data) => {
                    props.setfieldvalue(`${props.fieldName}`, data.value);
                  }}
                />
                : <TextArea
                  fluid={"true"}
                  key={`${props.dictKey}DictaphoneFalse`}
                  label={'Aufnahme'}
                  readOnly={true}
                  name={`${props.fieldName}`}
                  type="text"
                  rows={"6"}
                  value={""}
                  placeholder={'Starten Sie bitte die Aufnahme.'}
                  onChange={(e, data) => {
                    
                  }}
                />}
            </div>
          </Grid.Column>
        <Grid.Column computer={"8"} mobile="16">
      <div className="field">
            <TextArea
              fluid={"true"}
              key={`${props.dictKey}Editable`}
              label={'Nachricht'}
              name={`${props.fieldName}`}
              type="text"
              rows={"6"}
              value={props.value as string | number | undefined}
              placeholder={'Geben Sie bitte einen Text ein.'}
              readOnly={listening}
              onChange={(e, data) => {
                props.setfieldvalue(`${props.fieldName}`, data.value);
              }}
              onBlur={(data: any) =>
              {
                props.setfieldvalue(`${props.fieldName}`, props.value);
              }}
        />
          </div>
        </Grid.Column>
      </Grid>
      <div hidden={props.show}>
        <br />
        <Button key={`${props.dictKey}Reset`} size="small" floated="left" primary type="button" secondary icon={"trash"} content="Löschen" onClick={reset} />
        <Button key={`${props.dictKey}Speichern`} size="small" floated="right" disabled={listening} primary icon={"save"} content="Speichern" />
      </div>
    </div>
  </>
  );
};
const DictaphoneNachweis: React.FC<IDictaphoneProps> = (props) => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  if (!browserSupportsSpeechRecognition) {
    return <span>Ihr Browser unterstützt keine Spracherkennung.</span>;
  }

  const stop = async () => {
    await SpeechRecognition.stopListening();
    let newText = `${props.value} ${transcript}`;
    props.setfieldvalue(`${props.fieldName}`, newText);
    await props.setNachweis(false);
    await props.setNotiz(false);
    await resetTranscript();
  }
  const reset = async () => {
    await resetTranscript();
    props.setfieldvalue(`${props.fieldName}`, '');
  }

  const startListeningNachweis = async () => {
    await props.setNachweis(true);
    await props.setNotiz(false);
    if (transcript.length > 0) {
      await resetTranscript();
    }
    await SpeechRecognition.startListening({ continuous: true, language: 'de-DE' });
  };
  return (<>
    <div key={props.dictKey} style={{ display: 'flow-root' }}>
      {!props.readonly &&
        <p>{listening ?
          <> <Button icon={"microphone"} key={`${props.dictKey}Stop`} className="speechBtn" primary content="Nachweis" type="button"
            onClick={stop} disabled={props.notiz}/> </> :
          <><Button icon={"microphone slash"} key={`${props.dictKey}Start`} className="speechBtn" secondary content="Nachweis" type="button"
            onClick={startListeningNachweis} disabled={props.notiz} /> </>}</p>
      }
      <Grid key={`${props.dictKey}fieldGrid`} rows="1">
        {!props.readonly &&
          <Grid.Column computer="8" mobile="16">
            <div className="field">
              {props.nachweis ? <TextArea
                fluid={"true"}
                key={`${props.dictKey}DictaphoneTrue`}
                label={'Aufnahme'}
                readOnly={true}
                name={`${props.fieldName}`}
                type="text"
                rows={"6"}
                value={transcript}
                placeholder={'Starten Sie bitte die Aufnahme.'}
                onChange={(e, data) => {
                  props.setfieldvalue(`${props.fieldName}`, data.value);
                }}
              />
                :
                <TextArea
                  fluid={"true"}
                  key={`${props.dictKey}DictaphoneFalse`}
                  label={'Aufnahme'}
                  readOnly={true}
                  name={`${props.fieldName}`}
                  type="text"
                  rows={"6"}
                  value={""}
                  placeholder={'Starten Sie bitte die Aufnahme.'}
                  onChange={(e, data) => {
                  }}
                />
                }
            </div>
          </Grid.Column>
        }
        <Grid.Column computer={props.readonly ? "16" : "8"} mobile="16">
          <div className="field">
            <TextArea
              fluid={"true"}
              key={`${props.dictKey}Editable`}
              label={'Nachricht'}
              name={`${props.fieldName}`}
              type="text"
              rows={"6"}
              value={props.value as string | number | undefined}
              placeholder={'Geben Sie bitte einen Text ein.'}
              readOnly={(props.readonly || listening)}
              onChange={(e, data) => {
                props.setfieldvalue(`${props.fieldName}`, data.value);
              }}
              onBlur={(data: any) => {
                props.setfieldvalue(`${props.fieldName}`, props.value);
              }}
            />
          </div>
        </Grid.Column>
      </Grid>
      {!props.readonly &&
        <>
          <br />
        <Button key={`${props.dictKey}Reset`} size="small" floated="left" primary type="button" secondary icon={"trash"} content="Löschen" onClick={reset} />
        <Button key={`${props.dictKey}Speichern`} size="small" floated="right" disabled={listening} primary icon={"save"} content="Speichern" />
        </>
      }
    </div>
  </>
  );
}
export const SignaturePage: React.FC<ISignatureProps> = (props) => {
  const [trimmedURL, setTrimmedURL] = React.useState<string | null>(null);
  const signCanvas = React.useRef() as React.MutableRefObject<ReactSignatureCanvas>;
  const sigContainerRef = React.useRef<HTMLDivElement>(null)
  const [initialWidth, setInitialWidth] = React.useState(0)
  const [initialHeight, setInitialHeight] = React.useState(0)    
  const timeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(null)

  /* Overwriting width & height attributes of canvas-element set by ReactSignatureCanvas 
    setting initial width & height, which are needed for re-calculating on resize 
  */
  React.useEffect(() => { 
    signCanvas.current.getCanvas().setAttribute('width', signCanvas.current.getCanvas().clientWidth+"px")
    signCanvas.current.getCanvas().setAttribute('height', signCanvas.current.getCanvas().clientHeight+"px")
    setInitialWidth(signCanvas.current.getCanvas().clientWidth)
    setInitialHeight(signCanvas.current.getCanvas().clientHeight)
  }, [])

  const clear = () => {
    signCanvas.current.clear();
    signCanvas.current.fromData([])
  };

  /*
  Re-calculates all points on canvas based on old and new width/height 
  */
  const redrawCanvas = React.useCallback(() => {
    let oldData = signCanvas.current.toData()
    let canvas = signCanvas.current.getCanvas()
    canvas.setAttribute('width', canvas.clientWidth + "px")
    canvas.setAttribute('height', canvas.clientHeight + "px")

    let lines: SignaturePad.Point[][] = []
    let widthRatio = initialWidth / canvas.clientWidth
    let heightRatio = initialHeight / canvas.clientHeight
    oldData.forEach((line, i) => {
      let points:SignaturePad.Point[] = []
      line.forEach(point => {
        points.push({ 
          x: Math.round(point.x / widthRatio * 100) / 100,
          y: Math.round(point.y / heightRatio *100)/100,
          velocityFrom: point.velocityFrom,
          time: point.time,
          color:'black',
          distanceTo:point.distanceTo
        })
      })
      lines.push(points)
    })
    signCanvas.current.clear()
    setInitialWidth(canvas.clientWidth)
    setInitialHeight(canvas.clientHeight)
    signCanvas.current.fromData(lines)
  }, [initialWidth, initialHeight]) 

  const resizeDebounce = React.useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      redrawCanvas()
    }, 500)
  }, [redrawCanvas])

  React.useEffect(() => {
    window.addEventListener('resize', resizeDebounce)
    return () => { window.removeEventListener('resize', resizeDebounce)}
  }, [resizeDebounce])

  const trim = async () => {
    await setTrimmedURL(signCanvas.current.getTrimmedCanvas().toDataURL());
    await save();
  };
  const save = async () => {
    if ((props.canSendMail === true) && props.mail && !props.mail?.includes('.') && !props.mail?.includes('@!')) {
      await props.setMailError('error');
      props.nachricht.addMessage({ header: "Keine gültige Mail eingegeben" }, "warning", true, true);
    }
    else if (!props.name) {
      await props.setNameError('error');
      props.nachricht.addMessage({ header: "Kein Name für Signatur eingegeben" }, "warning", true, true);
    }
    else {
      if (signCanvas.current.isEmpty()) {
        props.nachricht.addMessage({ header: "Keine Signatur vorhanden" }, "warning", true, true);
      }
      else if (trimmedURL) {
        signCanvas.current.getTrimmedCanvas().toBlob(async (blob: any) => {
          try {
            await ProjektKontierungApi.addSignaturePOST({
              signature: blob,
              leistungsnachweisID: props.ID ? props.ID : undefined,
              projectID: props.projectID ? props.projectID : undefined,
              name: props.name ? props.name : undefined,
              email: props.mail ? props.mail : undefined,
            })
            props.nachricht.addMessage({ header: "Signatur wurde erfolgreich gespeichert!" }, "success", true, true);
            props.reload();
          } catch (e) {
            props.nachricht.addMessage({ header: "Signatur konnte nicht gespeichert werden!" }, "error", true, true);
          }
        });
      }
    }
  }
  return (
    <div className={'containerSig'}>
      <div className={'sigContainer'} ref={sigContainerRef}>
        <ReactSignatureCanvas
          ref={signCanvas}
          canvasProps={{ className: 'sigPad'}}
          key={props.ID}          
          onBegin={()=>{(document.activeElement as any).blur()}}
          clearOnResize={false}          
        />
      </div>
      <div className={'buttonsSig'}>
        <Button secondary
          className="sigButton"
          content={<>L&ouml;schen</>}
          icon={"trash"}
          floated="left"
          size="tiny"          
          onClick={clear}
          type="button"
        />
        <Button primary
          className="sigButton"
          content={<>Best&auml;tigen</>}
          icon={"check"}
          floated="right"
          size="tiny"
          onClick={trim}
          type="button"
        />
      </div>
    </div>
  )
};

export const Leistungsnachweis: React.FC<ILeistungsnachweisProps> = (props) => {
  useDocumentTitle(DOCUMENT_TITLES.leistungsnachweis)
  const [projekt, setProjekt] = React.useState<string | undefined>(undefined);
  const datum = new Date();
  const [error, setError] = React.useState<string>('');
  const [mailError, setMailError] = React.useState<string>('');
  const [nameError, setNameError] = React.useState<string>('');
  const [show, setShow] = React.useState<boolean>(false);
  const [hasSubmitted, setHasSubmitted] = React.useState(false)

  const projekteAuswahlModel: {
    key: string;
    text: string;
    value: string;
  }[] = [{ key: '0', text: 'Bitte auswählen', value: '0' }];
  const projekteDropDown = useQuery(["getProjekteGET"], () =>
    ProjektKontierungApi.getProjekteGET({ date: datum })
  );
  if (projekteDropDown.data) {
    projekteDropDown.data.forEach((projekt) => {
      projekteAuswahlModel.push({
        key: projekt.id!,
        text: projekt.bezeichnung!,
        value: projekt.id!
      });
    });
    if (projekteDropDown.data.length === 1 && projekt !== projekteDropDown.data[0].id) {
      setProjekt(projekteDropDown.data[0].id);
    }
  }
  const projekteAuswahl = (values: any) => {
    return (
      <>
        <Header as="h5">Projekt</Header>
        <Dropdown
          search
          onChange={(e, { value }) => {
            if (value) {
              projekteDropDown.data && projekteDropDown.data.forEach((projekt) => {
                if (projekt.id === value.toString()) {
                  setProjekt(projekt.id)
                  hideNotiz(false)
                }
              });
            }
          }}
          value={projekt ?? '0'}
          fluid
          selection
          options={projekteAuswahlModel}
        />
      </>
    );
  };
  const msg = useMessages();
  const [notizUsed, setNotizUsed] = React.useState<boolean>(false);
  const [nachweisUsed, setNachweisUsed] = React.useState<boolean>(false);
  const leistungsnachweisModel = useAsyncEdit<
    LeistungsnachweisModel,
    EditLeistungsnachweisPOSTRequest
  >({
    queryKey: `getLeistungsnachweisGET/${projekt}`,
    default: {},
    load: (id) => ProjektKontierungApi.getLeistungsnachweisGET({ projektID: projekt }),
    save: async (values, prev) => {
      try {
        const result = await ProjektKontierungApi.editLeistungsnachweisPOST({
          leistungsnachweisModel:
          {
            id: values.leistungsnachweisModel?.id,
            zeit: values.leistungsnachweisModel?.zeit?.toString() ?? "00:00",
            projektID: projekt,
            nachweis: values.leistungsnachweisModel?.nachweis ?? "",
            notizen: values.leistungsnachweisModel?.notizen ?? "",
            kundenMail: values.leistungsnachweisModel?.kundenMail ?? "",
            name: values.leistungsnachweisModel?.name ?? ""
          }
        });
        msg.addMessage({ header: "Leistungsnachweis erfolgreich gespeichert!" }, "success", true, true);
        return result;
      } catch (e) {
        return prev;
      }
    },
  });
  const commonVersions = useQuery(["getVersionGET"], () =>
    CommonApi.getVersionGET({clearCache: false})
  );
  const leistungsNachweis = React.useMemo<LeistungsnachweisModel>(() => leistungsnachweisModel.result || {}, [leistungsnachweisModel.result]);
  const saveLeistungsnachweis = React.useCallback(async (v) => {
    await leistungsnachweisModel.save({ leistungsnachweisModel: v });
  }, [leistungsnachweisModel]);
  const onSubmit = React.useCallback(async (v) => {
    await saveLeistungsnachweis(v) ;
    setHasSubmitted(true)
  }, [saveLeistungsnachweis]);
  function workDone(zeit: string | null | undefined) {
    return (zeit !== null) && (zeit !== undefined) && (zeit !== "00:00")
  }
  function reload () {
    leistungsnachweisModel.reload();
  }
  function hideNotiz(showNotiz: boolean) {
    setShow(showNotiz);
  }

  return (commonVersions.data && commonVersions.data.options && commonVersions.data.options.includes(EOptions.Leistungsnachweise) ?
    <div key={`firstDiv-${projekt}`} className={
      commonVersions.data?.hostEnvironment === "Test" ? "brand" : ""
    }>
      <div key={`secondDiv-${projekt}`}>
        <Segment key={`firstSegment-${projekt}`}>
            <Grid>
                <Grid.Column mobile='16' tablet='6' computer='6'>
                  {React.createElement(projekteAuswahl)}
                </Grid.Column>
            </Grid>
          <div key={`div-${projekt}`}>
          {projekt && leistungsnachweisModel && leistungsnachweisModel.result && leistungsNachweis &&

            <Formik key={`formik-${projekt}`}
                initialValues={leistungsNachweis}
                enableReinitialize
          onSubmit={onSubmit}
        >
          {({
            dirty,
            values,
            handleSubmit,
            setFieldValue,
            setValues,
            isSubmitting,
                }) => (
                  <Form loading={leistungsnachweisModel.loading} key={`Form-${projekt}`} autoComplete="off" onSubmit={handleSubmit}>
                    <br />
                    <div className={"nachweisBorder"}>
                    <Segment style={{borderTop: '1px solid black' }}>
                      <h3>Leistungsnachweis</h3>
                      <Grid key={`Grid-${values.projektID}-hh`} rows="1">
                        <Grid.Column computer={'4'} mobile={'8'}>
                          <div className="field">
                          <label>Durchgeführt von</label>
                          <Input
                            style={{ border: 'lightgrey 1px solid' }}
                            className="inputdiv"
                            readOnly={true}
                            value={leistungsNachweis.durchgefuehrtVon}
                            type={'string'}
                          />
                          </div>
                        </Grid.Column>
                        <Grid.Column computer={'4'} mobile="8">
                          <div className="field">
                            <label>Dauer</label>
                            <Input
                              style={(error ? { border: 'red 1px solid' } : { border: 'lightgrey 1px solid' })}
                              className={(error ? 'errorboarder' : '') + ' inputdiv' }
                              error={error.length > 0 ? true : false}  
                              name={`zeit`}
                              type={'time'}
                              value={values.zeit}
                              readOnly={values.hasSignature}
                              onChange={(e, data) => {
                                const time = data.value.split(":");
                                if ((time[0] as unknown as number < 0)) {
                                  setError('Stundenanzahl fehlerhaft.');
                                }
                                if ((time[1] as unknown as number < 0)) {
                                  setError('Minutenanzahl fehlerhaft.');
                                }
                                  if ((time[0] as unknown as number >= 0) && (time[1] as unknown as number >= 0)) {
                                  setError('');
                                }
                                if (data.value.length === 0) {
                                  setFieldValue('zeit', "00:00");
                                }
                                else {
                                  setFieldValue('zeit', data.value);
                                }
                              }}
                            />
                            {hasSubmitted && !values.canSignature && values.zeit==="00:00" && <Label style={{marginTop:"6px"}} color="red" pointing="above" basic>Zeitspanne angeben.</Label>}
                          </div>
                        </Grid.Column>
                        <Grid.Column with={'12'}></Grid.Column>
                      </Grid>
                      <Grid key={`Grid-DictNachweis-${values.projektID}`} rows="1">
                        <Grid.Column width={'16'}>
                          <DictaphoneNachweis key={"dictNachweis"} value={values.nachweis} fieldName={"nachweis"} dictKey={"nachweisDict"}
                            setfieldvalue={setFieldValue} readonly={values.hasSignature}
                            notiz={notizUsed} setNotiz={setNotizUsed} nachweis={nachweisUsed} setNachweis={setNachweisUsed}/>
                        </Grid.Column>
                        </Grid>
                        {values.id && !values.hasSignature && values.canSignature && (values.zeit &&
                        workDone(values.zeit)) &&
                        <Grid key={`Grid-Sig-${values.projektID}`} rows="2">
                          <Grid.Column computer={'6'} mobile={'16'}>
                            <div className={"field"}>
                              <label>Name</label>
                              <Input
                              style={nameError ? { border: 'red 1px solid' } : { border: 'lightgrey 1px solid' }}
                              className={nameError ? 'errorboarder' : ''}
                              readOnly={values.hasSignature}
                              name={`name`}
                              type={"text"}
                              placeholder="Name des Kunden"
                              value={values.name}
                              onBlur={
                                () => {
                                  setFieldValue('name', values.name);
                              }}
                              onChange={(e, data) => {
                                setFieldValue('name', data.value);
                              }}
                              />
                              </div>
                          </Grid.Column>
                          <Grid.Column computer={'6'} mobile={'16'}>
                            {values.canSendMail && <div className={"field"}>
                              <label>Zu senden an</label>
                              <Input
                                style={mailError ? { border: 'red 1px solid' } : { border: 'lightgrey 1px solid' }}
                                className={mailError ? 'errorboarder' : ''}
                              readOnly={values.hasSignature}
                                name={"kundenMail"}
                              type={"text"}
                              placeholder="Geben Sie eine Email ein."
                              value={values.kundenMail}
                              onBlur={
                                () => {
                                  setFieldValue('kundenMail', values.kundenMail);
                                }}
                                onChange={(e, data) => {
                                  setFieldValue('kundenMail', data.value);
                              }}
                              />
                            </div>}


                          </Grid.Column>
                            <Grid.Column computer={'16'} tablet={'16'} mobile={'16'}>
                              <SignaturePage ID={values.id} projectID={values.projektID} name={values.name}
                                nachricht={msg} setReadOnly={setShow} reload={reload} canSendMail={values.canSendMail}
                                setNameError={setNameError} setMailError={setMailError} mail={values.kundenMail} />
                          </Grid.Column>
                        </Grid>
                      }
                      { values.attachments &&
                        <Grid key={`Grid-Img-${values.projektID}`} rows="1">
                            {values.attachments && values.attachments.map && values.attachments.map(file => {
                              return (<> {file.anhangTyp === ELAttachmentTyp.Signature &&
                                <>
                                <Grid.Column key={`SigFile-${file.name}-${file.contentType}`} computer={'8'} mobile={'16'}>
                                  <div className="field">
                                  <Input
                                    key={`SigInputName-${file.name}-${file.contentType}` }
                                    readOnly={values.hasSignature}
                                    name={`name`}
                                    type="text"
                                    placeholder="Name des Kunden"
                                    value={values.name}
                                    onBlur={
                                      () => {
                                        setFieldValue('name', values.name);
                                      }}
                                    onChange={(e, data) => {
                                      setFieldValue('name', data.value);
                                    }}
                                  />
                                  </div>
                                </Grid.Column>
                                {values.canSendMail && values.kundenMail &&
                                  <Grid.Column computer={'8'} mobile={'16'}>
                                    <div className="field">
                                    <Input
                                      key={`SigInputMail-${file.name}-${file.contentType}`}
                                      readOnly={values.hasSignature}
                                      name={`kundenMail`}
                                      type="text"
                                      value={values.kundenMail}
                                      onBlur={
                                        () => {
                                          setFieldValue('kundenMail', values.kundenMail);
                                        }}
                                      onChange={(e, data) => {
                                        setFieldValue('kundenMail', data.value);
                                      }}
                                    />
                                    </div>
                                  </Grid.Column>}
                                <Grid.Column computer={'16'} mobile={'16'} key={`File-${file.name}-${file.contentType}`}>
                                  <div className={"field"}
                                    key={`Div-${file.name}-${file.contentType}`}
                                  ><img
                                      alt={file.name ?? "Signature"}
                                      className={'sigImage'}
                                      src={`data:${file?.contentType};base64,${file?.file}`}
                                    />
                                  </div>
                                </Grid.Column>

                                </>
                              }
                              </>)
                            })}
                        </Grid>
                      }
                      <div>
                        <Grid key={`Grid-DictNotiz-${values.projektID}`} rows="2">
                        <Grid.Column width={'16'}>
                          <DictaphoneNotiz key={"dictNotiz"} value={values.notizen} fieldName={"notizen"} dictKey={"notizDict"}
                              setfieldvalue={setFieldValue} readonly={values.hasSignature} hideNotiz={hideNotiz} show={!show}
                            notiz={notizUsed} setNotiz={setNotizUsed} nachweis={nachweisUsed} setNachweis={setNachweisUsed} />
                        </Grid.Column>
                        </Grid>
                      </div>
                    </Segment>
                    </div>
                  </Form>
              )}
                </Formik>

            }
          </div>
          </Segment>
        <br />
      </div>
      
    </div>
    : <><h3>Feature nicht freigeschaltet</h3></>
  );
};
