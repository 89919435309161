// Wandelt ein Date parameter in ein String Uhrzeit (hh:mm)
export const dateToStringTime = (time?: Date | null) => {
  if (!time || (typeof time.getTime !== 'function')) {
    return "";
  }
  let hour = time && time.getHours() ? time.getHours().toLocaleString() : "00";
  let minute = time && time.getMinutes() ? time.getMinutes().toLocaleString() : "00";
  if (minute.length > 2) {
    minute = minute.substring(0, 2);
  }
  if (time.getHours() < 10) {
    hour = "0" + time.getHours().toLocaleString();
  }
  if (time.getMinutes() < 10) {
    minute = "0" + time.getMinutes().toLocaleString();
  }
  return hour + ":" + minute;
};

// wandelt eine string Uhrzeit in ein Date.
export const stringTimeToDate = (value: any, date: Date) => {
  const todayDate = new Date(date); 
  var index = value.indexOf(":"); // replace with ":" for differently displayed time.
  var hours = value.substring(0, index);
  var minutes = value.substring(index + 1);
  todayDate.setHours(hours);
  todayDate.setMinutes(minutes);
  return todayDate;
};

// wandelt eine Number in eine string Uhrzeit (hh:mm). Gilt auch für negative Zahlen
export const numberToStringTime = (min: number) => {
  let hh = Math.floor(min / 60);
  const mm = min % 60;
  if (min < 0) {
    hh = (mm === -0) ? hh : hh + 1  // Damit bei 60 minuten / geraden stunden keine zusätzliche Stunde abgezogen wird.
  }

  if (min < 0) {
    return `${-hh < 10 ? "-0" + -hh : hh}:${-mm < 10 ? "0" + -mm : -mm}`;
  }
  else {
    return `${hh < 10 ? "0" + hh : hh}:${mm < 10 ? "0" + mm : mm}`;
  }
  
};

// wandelt eine string Uhrzeit (hh:mm) in eine Zahl (minuten)
export const stringTimeToNumber = (time: string) => {
  var timeSplit = time.replace('-', '').split(":")
  var hours = 0;
  var minutes = 0;
  var regex = /[a-z][A-Z]/;
  if (regex.test(time)) {
    return 0;
  }
  if (timeSplit[0]) {
    hours = parseInt(timeSplit[0]);
    if (isNaN(hours)) {
      hours = 0;
    }
  }
  if (timeSplit[1]) {
    minutes = parseInt(timeSplit[1]);
    if (isNaN(minutes)) {
      minutes = 0;
    }
  }
  return minutes + (hours * 60);
};

// Umwandeln eines Datums in short Datestring (AT: dd.mm.yyyy oder ISO: yyyy-mm-dd)
export const dateToShortDateString = (datum: Date, iso: boolean) => {
  let tag = datum.getDate() < 10 ? `0${datum.getDate()}` : `${datum.getDate()}`;
  let monat = datum.getMonth() + 1 < 10 ? `0${datum.getMonth() + 1}` : `${datum.getMonth() + 1}`;
  let jahr = `${datum.getFullYear()}`;
  if (iso) {
    return `${jahr}-${monat}-${tag}`;
  }
  else {
    return `${tag}.${monat}.${jahr}`;
  }
};

export const stringToDateString = (value: any, date: Date) => {
  if (!value) {
    return "";
  }
  var subValue = "";
  if ((typeof value.getTime !== 'function') && value.toString().length !== 5) {
    subValue = value.substring(0, 5);
    return subValue;
  }
  if ((typeof value.getTime !== 'function') && value.toString().length >= 5 && !value.toString().includes(":")) {
    subValue = value.substring(0, 5);
    return subValue;
  }

  var timeString = value.toString();
  var index = timeString.toString().indexOf(":"); // replace with ":" for differently displayed time.
  var hours = timeString.substring(0, index);
  var minutes = timeString.substring(index + 1);
  var seconds = hours === 24 ? 59 : 0;
  if (((index === -1) || (isNaN(parseInt(hours)) && isNaN(parseInt(minutes))))) {
    return value;
  }
  if ((typeof value.getTime === 'function') && value.toString().length !== 5) {
    return dateToStringTime(value);
  }
  if (!validateTimestring(value)) {
    return value;
  }
  const stempelDatum = new Date(date);
  stempelDatum.setHours(hours);
  stempelDatum.setMinutes(minutes);
  stempelDatum.setSeconds(seconds);
  return dateToStringTime(stempelDatum);
};

export const validateTimestring = (timeString: string) => {
  if (!timeString) {
    return true;
  }
  if (timeString.toString().includes(":") && timeString.length === 5) {
    var index = timeString.toString().indexOf(":"); // replace with ":" for differently displayed time.
    if (index !== 2) {
      return false;
    }
    var hour = timeString.substring(index + 1);
    var minute = timeString.substring(0, index);
    var regExNumber = /[0-9][0-9]/;
    var regExAlpha = /[a-z][A-Z]/;
    var valide = false;
    valide = regExNumber.test(hour);
    if (minute) {
      valide = regExNumber.test(minute);
    }
    if (!valide) {
      return false;
    }
    else {
      valide = regExAlpha.test(hour);
      if (minute) {
        valide = regExAlpha.test(minute);
      }
      if (valide) {
        return false;
      }
    }
    var hours = parseInt(timeString.substring(0, index));
    var minutes = parseInt(timeString.substring(index + 1));
    if (!(isNaN(hours) && isNaN(minutes)) && (hours >= 0 && hours <= 23) && (minutes >= 0 && minutes <= 59))
      {
        return true;
      }
  }
  else {
    return false;
  }
};

// wandelt eine string Uhrzeit in ein Date.
export const extendStringTimeToDate = (value: any) => {
  var index = value.indexOf(":"); // replace with ":" for differently displayed time.
  var regExNumber = /[0-9]{1,2}/;
  if (index > 0 && index < 3) {
    var hours = value.substring(0, index);
    var minutes = value.substring(index + 1);
    if (hours && hours.length === 1 && regExNumber.test(hours)) {
      hours = `0${hours}`;
    }
    if (minutes && minutes.length === 1 && regExNumber.test(minutes)) {
      minutes = `0${minutes}`;
    }
    else if (minutes.length > 1 && regExNumber.test(minutes)) {
      var minute = parseInt(minutes);
      if (minute >= 0 && minute < 10) {
        minutes = `0${minute}`;
      }
      else {
        minutes = `${minute}`;
      }
    }
    else if (!minutes) {
      minutes = '00'
    }
    return `${hours}:${minutes}`;
  }
  else if (index === -1 && regExNumber.test(value)) {
    if (value.length === 1) {
      hours = `0${value}`;
      return `${hours}:00`;
    }
    else if (value.length === 2) {
      return `${value}:00`;
    }
  }
  return value;
};