import { userManager } from "../authService";

export const FETCH_API_ERROR = "FETCH-API-ERROR";
export const FETCH_API_WARNING = "FETCH-API-WARNING";
function EmitErrorEvent(error: string) {
  const apiErrorEvent = new CustomEvent(FETCH_API_ERROR, {
    detail: error,
  });
  window.dispatchEvent(apiErrorEvent);
}
function EmitWarningEvent(warning: string) {
  const apiWarningEvent = new CustomEvent(FETCH_API_WARNING, {
    detail: warning,
  });
  window.dispatchEvent(apiWarningEvent);
}


export const fetchApiFactory = (signal?: AbortSignal) => async (
  input: RequestInfo,
  init?: RequestInit | undefined
): Promise<Response> => {
  try {
    let accessToken: string | null = null;
    try {
      const user = await userManager.getUser();
      accessToken = user && user.access_token;
    } catch (e) {
      console.log(e);
    }
    let headers: HeadersInit = (init && init.headers) ? { ...init.headers, Authorization: "Bearer " + accessToken } : {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json"
    };
    let response = await fetch(input, {
      ...init,
      headers,
      signal
    });
    if (!response.ok) {
      let errorResponse = response.clone();
      const contentType = errorResponse.headers.get("content-type");
      if (contentType && contentType.includes("json")) {
        const error = await errorResponse.json();
        if (error.status === 424) {
          EmitWarningEvent(
            `${error.detail}`
          );
        } else {
          EmitErrorEvent(
            `${error.statuscode ? error.statuscode : ""} ${error.title}`
          );
        }

      } else {
        const error = await errorResponse.text(); //TODO: Andi Error handling mit statuscode
        EmitErrorEvent(error);
      }
    }
    if (response.status === 204) {
      // workaround because generated API clients cannot handle empty response
      response = new Response("{}",
        {
          status: 200,
          statusText: response.statusText,
          headers: response.headers
        }
      )
    }
    if (response.status === 401) { // HTTP Unauthorized -> user not logged in
      userManager.signinRedirect();
    }
    return response;
  } catch (e : any) {
    if (e.name === 'AbortError') {
      throw e;
    }
    EmitErrorEvent("Netzwerkfehler! Überprüfen Sie ihre Internetverbindung.");
    throw e;
  }
};

export const fetchApi = fetchApiFactory();

