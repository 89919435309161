/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ERolle,
    ERolleFromJSON,
    ERolleFromJSONTyped,
    ERolleToJSON,
    EUIMode,
    EUIModeFromJSON,
    EUIModeFromJSONTyped,
    EUIModeToJSON,
} from './';

/**
 * Model: Mitarbeiter
 * @export
 * @interface MitarbeiterModel
 */
export interface MitarbeiterModel {
    /**
     * ID des Mitarbeiters
     * @type {string}
     * @memberof MitarbeiterModel
     */
    id?: string;
    /**
     * Personalnummer des Mitarbeiters
     * @type {string}
     * @memberof MitarbeiterModel
     */
    personalNr?: string | null;
    /**
     * Vorname
     * @type {string}
     * @memberof MitarbeiterModel
     */
    vorname?: string | null;
    /**
     * Zuname
     * @type {string}
     * @memberof MitarbeiterModel
     */
    nachname?: string | null;
    /**
     * 
     * @type {ERolle}
     * @memberof MitarbeiterModel
     */
    rolle?: ERolle;
    /**
     * ID der Abteilung in welcher sich Mitarbeiter befindet
     * @type {string}
     * @memberof MitarbeiterModel
     */
    abteilungID?: string | null;
    /**
     * Gibt an ob Benutzer für System freigeschalten ist, bzw bei Arbeitgeber angestellt ist
     * @type {boolean}
     * @memberof MitarbeiterModel
     */
    isActive?: boolean;
    /**
     * 
     * @type {EUIMode}
     * @memberof MitarbeiterModel
     */
    uiMode?: EUIMode;
    /**
     * Datum wann Mitarbeiter bei Unternehmen angefangen hat zu arbeiten
     * @type {Date}
     * @memberof MitarbeiterModel
     */
    eintrittsdatum?: Date;
    /**
     * Datum wann Mitarbeiter das Unternehmen verlassen hat
     * @type {Date}
     * @memberof MitarbeiterModel
     */
    austrittsdatum?: Date | null;
    /**
     * Urlaubsanspruch der den Mitarbeiter zusteht
     * @type {number}
     * @memberof MitarbeiterModel
     */
    urlaubsanspruch?: number;
    /**
     * Status, ob Mitarbeiter Urlaubsanspruch mit Eintrittsdatum oder mit Jahresbeginn berechnet werden soll
     * false = Jahresbeginn; true = Dienstantritt
     * @type {boolean}
     * @memberof MitarbeiterModel
     */
    urlaubsanspruchTyp?: boolean;
    /**
     * Zeigt den Resturlaub des Mitarbeiters
     * @type {number}
     * @memberof MitarbeiterModel
     */
    resturlaub?: number;
    /**
     * Benutzername / Passwort als login?
     * @type {boolean}
     * @memberof MitarbeiterModel
     */
    hasLogin?: boolean;
    /**
     * Zeigt den Resturlaub des Mitarbeiters formattiert an
     * @type {string}
     * @memberof MitarbeiterModel
     */
    readonly resturlaubFormatted?: string | null;
}

export function MitarbeiterModelFromJSON(json: any): MitarbeiterModel {
    return MitarbeiterModelFromJSONTyped(json, false);
}

export function MitarbeiterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MitarbeiterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'personalNr': !exists(json, 'personalNr') ? undefined : json['personalNr'],
        'vorname': !exists(json, 'vorname') ? undefined : json['vorname'],
        'nachname': !exists(json, 'nachname') ? undefined : json['nachname'],
        'rolle': !exists(json, 'rolle') ? undefined : ERolleFromJSON(json['rolle']),
        'abteilungID': !exists(json, 'abteilungID') ? undefined : json['abteilungID'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'uiMode': !exists(json, 'uiMode') ? undefined : EUIModeFromJSON(json['uiMode']),
        'eintrittsdatum': !exists(json, 'eintrittsdatum') ? undefined : (new Date(json['eintrittsdatum'])),
        'austrittsdatum': !exists(json, 'austrittsdatum') ? undefined : (json['austrittsdatum'] === null ? null : new Date(json['austrittsdatum'])),
        'urlaubsanspruch': !exists(json, 'urlaubsanspruch') ? undefined : json['urlaubsanspruch'],
        'urlaubsanspruchTyp': !exists(json, 'urlaubsanspruchTyp') ? undefined : json['urlaubsanspruchTyp'],
        'resturlaub': !exists(json, 'resturlaub') ? undefined : json['resturlaub'],
        'hasLogin': !exists(json, 'hasLogin') ? undefined : json['hasLogin'],
        'resturlaubFormatted': !exists(json, 'resturlaubFormatted') ? undefined : json['resturlaubFormatted'],
    };
}

export function MitarbeiterModelToJSON(value?: MitarbeiterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'personalNr': value.personalNr,
        'vorname': value.vorname,
        'nachname': value.nachname,
        'rolle': ERolleToJSON(value.rolle),
        'abteilungID': value.abteilungID,
        'isActive': value.isActive,
        'uiMode': EUIModeToJSON(value.uiMode),
        'eintrittsdatum': value.eintrittsdatum === undefined ? undefined : (value.eintrittsdatum.toISOString()),
        'austrittsdatum': value.austrittsdatum === undefined ? undefined : (value.austrittsdatum === null ? null : value.austrittsdatum.toISOString()),
        'urlaubsanspruch': value.urlaubsanspruch,
        'urlaubsanspruchTyp': value.urlaubsanspruchTyp,
        'resturlaub': value.resturlaub,
        'hasLogin': value.hasLogin,
    };
}


