import * as React from "react";
import { ZeitStempel } from "./Zeiterfassung/ZeitStempel";
import { Kontierung } from "./Kontierung";
import { TagStempel, ZeitstempelRow, StempelModel, EAZTyp, ZeitstempelModel, Tagesstempel, } from "../generated";
import { dateToShortDateString, stringTimeToNumber } from "./Helper";

import { Button, Checkbox, Form, Grid, Header, HeaderSubheader, Segment } from "semantic-ui-react";
import { FieldArray, Field, FieldProps, } from "formik";
import { Prompt } from "react-router-dom";
import { MontageStempel } from "./Zeiterfassung/MontageStempel";

const wochentag = new Array(7);
wochentag[0] = "Sonntag";
wochentag[1] = "Montag";
wochentag[2] = "Dienstag";
wochentag[3] = "Mittwoch";
wochentag[4] = "Donnerstag";
wochentag[5] = "Freitag";
wochentag[6] = "Samstag";

const newZeitstempel: ZeitstempelRow =
{
  bis: { stempel: undefined, info: undefined, typ: EAZTyp.Gehen },
  von: { stempel: undefined, info: undefined, typ: EAZTyp.Kommen }
};

export const timeConvertToHoursAndMinutes = (datum: Date, num: number) => {
  const hours = Math.floor(num / 60);
  const minutes = num % 60;
  const time = new Date(datum);
  time.setHours(hours);
  time.setMinutes(minutes);
  return time;
};


interface IZeiterfassungFormProps {
  loading: boolean;
  today: boolean;
  dirty: boolean;
  values: TagStempel;
  handleSubmit: () => void;
  setValues: (values: React.SetStateAction<TagStempel>, shouldValidate?: boolean) => void;
  setfieldvalue: (field: string, value: any, shouldValidate?: boolean) => void;
  rows: number;
  tagWechseln: (vor: boolean) => void;
  isSubmitting: boolean;
  readonly: boolean;
  datum: Date;
}

export const ZeiterfassungForm: React.FC<IZeiterfassungFormProps> = ({ loading, readonly, dirty, values, today, handleSubmit, setfieldvalue, rows, setValues, isSubmitting, tagWechseln, datum}) => {
  const [row, SetRow] = React.useState<number>(rows); //Form aktualisieren für neuen Stempel
  const [montageReload, setMontageReload] = React.useState(false) //Form aktualisieren bei neuem Montagestempel 
  function addRow(zeitstempel: ZeitstempelRow[]) {
    zeitstempel.push(newZeitstempel);
    SetRow(zeitstempel.length);  //für reload  list state erkennt keine änderungen
  };
  function addMontageRow(montagestempel: ZeitstempelRow[]){
    montagestempel.push(newZeitstempel) 
    setMontageReload(prev => !prev) // für reload
  } 
  function changeTagesStempel(values: StempelModel, tagesStempel: Tagesstempel, changedTo: boolean) {
    if (changedTo) {
      values && values!.stempelAktiv!.push(tagesStempel);
    }
    else {
      let arrIndex = values && values!.stempelAktiv!.findIndex(f => f.id === tagesStempel.id);
      if (arrIndex >= 0) {
        values && values!.stempelAktiv!.splice(arrIndex, 1);
      }
    }
    SetRow(row + values!.stempelAktiv!.length)
  };
  const onEntfernen = React.useCallback(() => {
    setValues((prev: TagStempel) => {
      prev.deleteKontierungen = true;
      return prev;
    });
    handleSubmit();
  }, [setValues, handleSubmit])

  return (
    <div key={`Formdiv-${values.formID}-${montageReload}`}>
      <Form loading={loading} key={`Form-${values.formID}`} autoComplete="off" onSubmit={handleSubmit}>
        <br />
        <Segment key={`Segment-${values.formID}`}>
          <h3 key={`Header-${values.formID}`} style={{ textAlign: "center" }}>
            <Button key={`ButtonLeft-${values.formID}`} className={"prev-next"} icon={"angle left"} primary type={'button'} data-tooltip={"Zum Vortag wechseln"} disabled={loading || (dirty)}
              onClick={() => { tagWechseln(false); }} /> &nbsp;
            Zeiterfassung {dateToShortDateString(datum, false)}
            &nbsp; <Button key={`ButtonRight-${values.formID}`} className={"prev-next"} icon={"angle right"} primary type={'button'} data-tooltip={"Zum nächsten Tag wechseln"} disabled={loading || (dirty)}
              onClick={() => { tagWechseln(true); }} /> &nbsp;

            <HeaderSubheader key={`SubHeader-${values.formID}`} content={<div>{wochentag[datum.getDay()]}</div>} />

          </h3>

          {/* ZEITSTEMPEL */}
          {
            (values.showStempel === EAZTyp.Null) &&
              values && values.zeitstempel && values.zeitstempel.stempelRow
              ?
                <Grid key={`Grid-${values.formID}`} rows="2" >
                  {values && values.zeitstempel && values.zeitstempel.stempelRow && values.zeitstempel.stempelRow.length > 0 &&
                  <FieldArray
                  key={`FA-${values.formID}`}
                      name="zeitstempel.stempelRow"
                      render={(arrayHelpers) =>
                        values && values.zeitstempel && values.zeitstempel.stempelRow && values.zeitstempel.stempelRow.length > 0 &&
                        values.zeitstempel.stempelRow.map((zeitstempel: ZeitstempelRow, index: number) => (
                            <Grid.Column key={`GridColum-${index}-${values.formID}`} className={(index === 0) ? "" : "stempel-bordertop"} width="16">
                              <ZeitStempel key={`Zeitstempel-${index}-von-${values.formID}`}
                                sonstigeDropdown={values.sonstigeDropdown} datum={datum} kommenGehen={'von'}
                                eazTyp={EAZTyp.Kommen} edit={(readonly || zeitstempel.von!.typ!.includes(EAZTyp.ReadOnly))} index={index}
                                rows={values.zeitstempel!.stempelRow!.length} indexKey={`${index}von`} zeitstempel={zeitstempel.von! as ZeitstempelModel} 
                                kommen={true} gehen={false} setfieldvalue={setfieldvalue} />

                            <ZeitStempel key={`Zeitstempel-${index}-bis-${values.formID}`}
                              sonstigeDropdown={values.sonstigeDropdown} datum={datum} kommenGehen={'bis'}
                              eazTyp={EAZTyp.Gehen} edit={(readonly || zeitstempel.bis!.typ!.includes(EAZTyp.ReadOnly))} index={index}
                              rows={values.zeitstempel!.stempelRow!.length} indexKey={`${index}bis`} zeitstempel={zeitstempel.bis! as ZeitstempelModel}
                              kommen={false} gehen={true} setfieldvalue={setfieldvalue} />
                            </Grid.Column>
                        ))
                      }
                    />
                  }
                  {/* TAGESSTEMPEL */}
                  {values && values.zeitstempel && values.zeitstempel.tagesStempelAuswahl && values && values.zeitstempel && values.zeitstempel.stempelRow && values.zeitstempel.stempelRow.length > 0
                    && values.zeitstempel.tagesStempelAuswahl.map((tagStempel, index: number) => {
                      return (
                        <div key={`ZeiterfassungsForm-${values.formID}cbdiv${index}`}>
                          <Grid.Column key={`ZeiterfassungsForm-${values.formID}cbcolumn${index}`} mobile="5" tablet={"5"} computer="3" style={{ paddingBottom: 'unset' }}>
                            <Field key={`ZeiterfassungsForm-${values.formID}cbfield${index}`}>
                              {(field: FieldProps) => {
                                return <>
                                    <Checkbox
                                      key={`ZeiterfassungsForm-${values.formID}checkbox${index}`}
                                    data-tooltip={tagStempel.beschreibung ? `${tagStempel.beschreibung}` : null}
                                    label={`${tagStempel.name}`}
                                    name={`${index}.${tagStempel.id}`}
                                    disabled={(
                                      (values && values.zeitstempel && values.zeitstempel.stempelRow && values.zeitstempel.stempelRow.length >= 0 &&
                                        values.zeitstempel.stempelRow[0].von !== undefined ? false : true)
                                    )}
                                    checked={values && values.zeitstempel && values.zeitstempel.stempelAktiv && values.zeitstempel.stempelAktiv.find(f => f.id === tagStempel.id) ? true : false}
                                    onChange={(e, { checked }) => {
                                      if (checked) {
                                        changeTagesStempel(values.zeitstempel!, tagStempel, true);
                                        field.form.setFieldValue(`${index}.${tagStempel.id}`, true);
                                      }
                                      else {
                                        changeTagesStempel(values.zeitstempel!, tagStempel, false);
                                        field.form.setFieldValue(`${index}.${tagStempel.id}`, false);
                                      }
                                    }}
                                  />
                                </>
                                
                              }}
                            </Field>
                          </Grid.Column>
                        </div>
                      )
                    })
                  }
                
                {/* ZEITERFASSUNG BUTTONS */}
                <Grid.Column key={`ZeiterfassungsForm-${values.formID}btncolumn`} mobile="16" computer="16">
                    {readonly ? <></>
                      :
                    <div key={`ZeiterfassungsForm-${values.formID}btndiv`}>
                      <Button
                        key={`ZeiterfassungsForm-${values.formID}stempeln`}
                          secondary
                          content={'Stempeln'}
                          className={'icon-button'}
                          data-tooltip={"Normalarbeitszeit übernehmen"}
                          floated="left"
                          icon='clock'
                          type="button"
                          disabled={(isSubmitting ||
                            ((values !== undefined) && (values.zeitstempel === undefined))
                            || ((values.zeitstempel.sollBis === undefined) && (values.zeitstempel.sollVon === undefined))
                            || (values && values.zeitstempel && values.zeitstempel.stempelRow && (values.zeitstempel.stempelRow.length > 1)))
                            ? true : false}
                          onClick={() => {
                            let vonDate = values.zeitstempel!.sollVon;
                            let bisDate = values.zeitstempel!.sollBis;
                            setfieldvalue(`zeitstempel.stempelRow[0].von.stempel`, vonDate)
                            setfieldvalue(`zeitstempel.stempelRow[0].bis.stempel`, bisDate)
                          }}

                        />
                      <Button
                        key={`ZeiterfassungsForm-${values.formID}save`}
                          primary
                          content={'Speichern'}
                          className={'icon-button'}
                          data-tooltip={"Speichern"}
                          floated="right"
                          icon="save"
                          type="submit"
                          disabled={isSubmitting || (!dirty)}
                        />
                      <Button
                        key={`ZeiterfassungsForm-${values.formID}add`}
                          secondary
                          content={'Hinzufügen'}
                          className={'icon-button'}
                          data-tooltip={"Hinzufügen"}
                          floated="left"
                          icon="add"
                          disabled={isSubmitting}
                          type="button"
                          onClick={() => addRow(values && values.zeitstempel! && values.zeitstempel.stempelRow!)}
                        />
                      </div>
                    }
                  </Grid.Column>
                </Grid>
              : values.showStempel && values.showStempel !== EAZTyp.Null && <div key={`ZeiterfassungsForm-${values.formID}subheaderdiv`} style={{ textAlign: 'center' }}>Ganztägige Abwesenheit: {values.showStempel}
                <HeaderSubheader key={`ZeiterfassungsForm-${values.formID}subheader`} content={<h4>Für Buchungen wenden Sie sich bitte an die Administration</h4>} />
              </div>}
          <Prompt
            when={!loading && (dirty) && !isSubmitting}
            message="Daten verwerfen ohne zu speichern?"
          />

        </Segment>

        {/* MONTAGEZEITEN */}
        { values.montage && 
        <Segment>
          <Header as="h5">Montagezeiten</Header>

          <Grid>
            <FieldArray
                key={`FA-${values.formID}`}
                name="zeitstempel.stempelRow"
                render={(arrayHelpers) =>
                  values?.zeitstempel?.montageRow?.length && values.zeitstempel.montageRow.length > 0 && values.zeitstempel.montageRow.map((montagestempel: ZeitstempelRow, index: number) => {
                    return (
                    <>
                      {index !== 0 && <Grid.Column key={`GridColum-${index}-montage1${values.formID}`} className="stempel-bordertop" width="16" />} {/* Separation Bar */}
                      <Grid.Column key={`GridColum-${index}-montage1${values.formID}`} tablet="8" mobile="16" computer="8">
                        <MontageStempel 
                          datum={datum} 
                          kommenGehen={'von'}
                          eazTyp={EAZTyp.Montage} 
                          edit={(readonly || montagestempel.von!.typ!.includes(EAZTyp.ReadOnly))} 
                          index={index}
                          rows={values.zeitstempel!.montageRow?.length ?? 0} 
                          indexKey={`${index}von`} 
                          montagestempel={montagestempel.von as ZeitstempelModel} 
                          kommen={true} 
                          gehen={false} 
                          setfieldvalue={setfieldvalue} 
                        />
                      </Grid.Column>
                      <Grid.Column key={`GridColum-${index}-montage2${values.formID}`} tablet="8" mobile="16" computer="8">
                        <MontageStempel 
                          datum={datum} 
                          kommenGehen={'bis'}
                          eazTyp={EAZTyp.Montage} 
                          edit={(readonly || montagestempel.bis!.typ!.includes(EAZTyp.ReadOnly))} 
                          index={index}
                          rows={values.zeitstempel!.montageRow?.length ?? 0} 
                          indexKey={`${index}bis`} 
                          montagestempel={montagestempel.bis as ZeitstempelModel} 
                          kommen={false} 
                          gehen={true} 
                          setfieldvalue={setfieldvalue} 
                        />
                      </Grid.Column>
                    </>
                  )})
                }
              />
           <Grid.Column mobile="16" computer="16">
              <Button
                secondary
                content={'Hinzufügen'}
                className={'icon-button'}
                data-tooltip={"Hinzufügen"}
                floated="left"
                icon="add"
                disabled={isSubmitting}
                type="button"
                onClick={() => addMontageRow(values.zeitstempel!.montageRow!)}
              />
            </Grid.Column>
          </Grid>
        </Segment>
        }

        {/* KONTIERUNG */}
        {!loading 
          && (values.showStempel === EAZTyp.Null) &&
          values && values.zeitstempel && values.zeitstempel.projektKontierungen &&
          <Kontierung
          key={`ZeiterfassungsForm-${values.formID}kontierungen`}
          isReadOnly={readonly}
          offeneMinuten={timeConvertToHoursAndMinutes(datum, stringTimeToNumber(values.info && values.info.istArbeitsZeit ? values.info.istArbeitsZeit : "00:00"))}
          sonstigeMinuten={timeConvertToHoursAndMinutes(datum, stringTimeToNumber(values.info && values.info.sonstigeArbeitsZeit ? values.info.sonstigeArbeitsZeit : "00:00"))}
          datum={datum}
          rows={row}
          vonDatum={values.zeitstempel && values.zeitstempel.stempelRow && values.zeitstempel.stempelRow[rows - 1].von?.stempel}
          bisDatum={values.zeitstempel && values.zeitstempel.stempelRow && values.zeitstempel.stempelRow[rows - 1].bis?.stempel}
          model={values}
          notToday={!today}
          setValues={setValues}
          dirty={(!dirty)}
          setfieldvalue={setfieldvalue}
          onEntfernen={onEntfernen}
          rowsUsed={values.zeitstempel.rowsUsed ?? 0}
            />
        }

       
      </Form>

    </div>
  )
}