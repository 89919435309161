
/**
 * path - can be used to directly link to 
 * title - will be displayed in the Sidebar and in Headings. This will also be used in search, except when a searchTitle exists for that item 
 * id - used to generate anchor-links - MUST BE UNIQUE 
 * searchTitle? - if provided, the search function will use this param instead of title 
 * htmlTitle? - to use when heading-tags should contain some html-tags (e.g. a <wbr> somewhere)
 */
export type SearchLink = {
    path: string 
    title: string 
    id: string
    searchTitle?: string
    htmlTitle?: string 
}
type MainLink = {
    path: string 
    title: string
    id:string
    htmlTitle?: string
}
type LinkContainer = {
    searchable: ()=>SearchLink[]
}
export const VerwaltungHauptlinks:LinkContainer&{
    arbeitszeiten: MainLink,
    auswertungen: MainLink,
    index: MainLink,
    mitarbeiter: MainLink,
    stempel: MainLink,
    einstellungen: MainLink,
    abwesenheiten: MainLink,
    leistungsnachweis: MainLink,
    projekte: MainLink,
    ressourcen: MainLink,
    montage: MainLink,
    ersteSchritte: MainLink
    searchable: ()=>SearchLink[]
} = {
    arbeitszeiten: { path:"/handbuch/verwaltung/arbeitszeiten",title:"Arbeitszeiten", id:"hb-arbeitszeiten"},
    auswertungen: { path: "/handbuch/verwaltung/auswertungen", title:"Auswertungen", id:"hb-auswertungen"},
    index: {path: "/handbuch/verwaltung/",title: "Übersicht", id:"hb-uebersicht"},
    mitarbeiter: {path: "/handbuch/verwaltung/mitarbeiter",title:"Mitarbeiter", id:"hb-mitarbeiter"},
    stempel: {path: "/handbuch/verwaltung/stempel",title:"Stempel", id:"hb-stempel"},
    einstellungen: {path:"/handbuch/verwaltung/einstellungen",title:"Unternehmenseinstellungen", id:"hb-einstellungen", htmlTitle:"Unternehmens<wbr>einstellungen"}, 
    abwesenheiten: {path: "/handbuch/verwaltung/abwesenheiten", title: "Abwesenheiten", id:"hb-abwesenheiten"},
    leistungsnachweis: {path: "/handbuch/verwaltung/leistungsnachweis",title: "Leistungsnachweis", id:"hb-leistungsnachweis", htmlTitle:"Leistungs<wbr>nachweis"},
    projekte: {path: "/handbuch/verwaltung/projekte", title: "Projektverwaltung", id:"hb-projekte", htmlTitle:"Projekt<wbr>verwaltung"},
    ressourcen: {path: "/handbuch/verwaltung/ressourcen", title: "Ressourcenplanung", id:"hb-ressourcen", htmlTitle:"Ressourcen<wbr>planung"},
    montage: {path: "/handbuch/verwaltung/montage", title: "Montage", id:"hb-montage"},
    ersteSchritte: {path:"/handbuch/verwaltung/erste-schritte", title:"Erste Schritte", id:"hb-erste-schritte"},
    searchable: function(){ return Object.values(this).filter(value => "path" in value) as SearchLink[]}
}   

export const MitarbeiterHauptlinks: LinkContainer&{
    index:MainLink,
    zeiterfassung: MainLink,
    abwesenheitsantrag: MainLink,
    abwesenheiten: MainLink,
    kontierungen: MainLink,
    sonstiges: MainLink,
    arbeitsplan: MainLink,
    leistungsnachweis: MainLink,
    monatsansicht: MainLink,
} = {
    index: {path:'/handbuch/mitarbeiter/', title:"Übersicht", id:"hb-uebersicht"},
    zeiterfassung: {path:'/handbuch/mitarbeiter/zeiterfassung', title:"Zeiterfassung", id:"hb-zeiterfassung", htmlTitle:"Zeit<wbr>erfassung"},
    abwesenheitsantrag: {path:'/handbuch/mitarbeiter/abwesenheitsantrag', title:"Abwesenheitsantrag", id:"hb-abwesenheitsantrag", htmlTitle:"Abwesenheits<wbr>antrag"},
    abwesenheiten: {path:'/handbuch/mitarbeiter/abwesenheiten', title:"Abwesenheiten", id:"hb-abwesenheiten"},
    kontierungen: {path:'/handbuch/mitarbeiter/kontierungen', title:"Kontierungen", id:"hb-kontierungen"},
    sonstiges: {path:'/handbuch/mitarbeiter/sonstiges', title:"Sonstiges", id:"hb-sonstiges"},
    arbeitsplan: {path:'/handbuch/mitarbeiter/arbeitsplan', title:"Arbeitsplan", id:"hb-arbeitsplan"},
    leistungsnachweis: {path:'/handbuch/mitarbeiter/leistungsnachweis', title:"Leistungsnachweis", id:"hb-leistungsnachweis", htmlTitle:"Leistungs<wbr>nachweis"},
    monatsansicht: {path:'/handbuch/mitarbeiter/monatsansicht', title:"Monatsansicht", id:"hb-monatsansicht", htmlTitle:"Monats<wbr>ansicht"},
    searchable: function(){ return Object.values(this).filter(value => "path" in value) as SearchLink[]}
}

export const MitarbeiterZeiterfassungSublinks:LinkContainer&{
    pauseInArbeitszeit:SearchLink
    projektkontierung:SearchLink, 
    sonstigeAbwesenheiten:SearchLink, 
    montagezeiten:SearchLink
} = {
    pauseInArbeitszeit: { id: "pause-in-arbeitszeit", title: "Pause in Arbeitszeit", path: MitarbeiterHauptlinks.zeiterfassung.path + "#pause-in-arbeitszeit" },
    projektkontierung: {id:"projektkontierung", title:"Projektkontierung", path: MitarbeiterHauptlinks.zeiterfassung.path+"#projektkontierung"},
    sonstigeAbwesenheiten: {id:"sonstige", title:"Sonstige Abwesenheiten", path: MitarbeiterHauptlinks.zeiterfassung.path+"#sonstige"},
    montagezeiten: {id:"montage", title:"Montagezeiten", path: MitarbeiterHauptlinks.zeiterfassung.path+"#montage"},
    searchable: function(){
        return Object.values(this).filter(value => "path" in value) as SearchLink[]
    },
}
export const MitarbeiterSonstigesSublinks:LinkContainer&{
    homeScreen:SearchLink,
    ios:SearchLink,
    android:SearchLink,
    passwort:SearchLink 
} = {
    homeScreen: {id:"home-screen", title:"Auf Home-Screen speichern", path: MitarbeiterHauptlinks.sonstiges.path+"#home-screen"},
    ios: {id:"ios", title:"iOS (Safari)", path: MitarbeiterHauptlinks.sonstiges.path+"#ios"},
    android: {id:"android", title:"Android (Chrome-Browser)", path: MitarbeiterHauptlinks.sonstiges.path+"#android"},
    passwort: {id:"passwort", title:"Neues Passwort setzen", path: MitarbeiterHauptlinks.sonstiges.path+"#passwort"},
    searchable: function(){
        return Object.values(this).filter(value => "path" in value) as SearchLink[]
    },
}



export const VerwaltungErsteSchritteSublinks:LinkContainer&{
    ersteSchritte: SearchLink,
    mitarbeiterVerwaltung: SearchLink,
    aufbauVerwaltung: SearchLink,
    azModelle: SearchLink,
    azModelleAnsehen: SearchLink,
    azModelleErstellen: SearchLink,
    azModellEingaben: SearchLink,
    azModellMehrwoechig: SearchLink,
    azModellBspVollzeit: SearchLink,
    maAnlegen: SearchLink,
    maAnlegenRolle: SearchLink,
    maAnlegenUrlaub: SearchLink,
    maAnlegenZeiten: SearchLink,
    maAnlegenZeiten1: SearchLink,
    maAnlegenZeiten2: SearchLink,
    web: SearchLink,
}={
    ersteSchritte:{id:"erste-schritte", title:"Erste Schritte", path:VerwaltungHauptlinks.ersteSchritte.path+"#/erste-schritte"},
    mitarbeiterVerwaltung:{id:"ma-vw", title:"1 - Mitarbeiter & Verwaltung", searchTitle:"Erste Schritte - Mitarbeiter & Verwaltung", path:VerwaltungHauptlinks.ersteSchritte.path+"#/ma-vw"},
    aufbauVerwaltung:{id:"aufbau-verwaltung", title:"2 - Aufbau der Verwaltung", searchTitle:"Erste Schritte - Aufbau der Verwaltung", path:VerwaltungHauptlinks.ersteSchritte.path+"#/aufbau-verwaltung"},
    azModelle:{id:"arbeitszeitmodelle", title:"2 - Arbeitszeitmodelle", searchTitle:"Erste Schritte - Arbeitszeitmodelle", path:VerwaltungHauptlinks.ersteSchritte.path+"#/arbeitszeitmodelle"},
    azModelleAnsehen:{id:"arbeitszeitmodelle-ansehen", title:"2 - Arbeitszeitmodelle ansehen", searchTitle:"Erste Schritte - Arbeitszeitmodelle ansehen", path:VerwaltungHauptlinks.ersteSchritte.path+"#/arbeitszeitmodelle-ansehen"},
    azModelleErstellen:{id:"arbeitszeitmodelle-erstellen", title:"2 - Arbeitszeitmodell erstellen", searchTitle:"Erste Schritte - Arbeitszeitmodell erstellen", path:VerwaltungHauptlinks.ersteSchritte.path+"#/arbeitszeitmodelle-erstellen"},
    azModellEingaben:{id:"eingaben", title:"Eingaben", searchTitle:"Erste Schritte - Eingaben bei Arbeitszeitmodellen", path:VerwaltungHauptlinks.ersteSchritte.path+"#/eingaben"},
    azModellMehrwoechig:{id:"mehrwoechig", title:"Mehrwöchige Arbeitszeitmodelle", searchTitle:"Erste Schritte - Mehrwöchige Arbeitszeitmodelle", path:VerwaltungHauptlinks.ersteSchritte.path+"#/mehrwoechig"},
    azModellBspVollzeit:{id:"bsp-vollzeit", title:"Beispiel: Vollzeit", searchTitle:"Erste Schritte - Arbeitszeitmodell Beispiel:Vollzeit", path:VerwaltungHauptlinks.ersteSchritte.path+"#/bsp-vollzeit"},
    maAnlegen:{id:"ma-anlegen", title:"3 - Mitarbeiter anlegen", searchTitle:"Erste Schritte - Mitarbeiteranlegen", path:VerwaltungHauptlinks.ersteSchritte.path+"#/ma-anlegen"},
    maAnlegenRolle:{id:"rolle", title:"Rolle festlegen", searchTitle:"Erste Schritte - Mitarbeiterrolle festlegen", path:VerwaltungHauptlinks.ersteSchritte.path+"#/rolle"},
    maAnlegenUrlaub:{id:"urlaub", title:"Urlaub anpassen", searchTitle:"Erste Schritte - Urlaub anpassen", path:VerwaltungHauptlinks.ersteSchritte.path+"#/urlaub"},
    maAnlegenZeiten:{id:"zeiten", title:"Arbeitszeiten eintragen", searchTitle:"Erste Schritte - Arbeitszeiten eintragen", path:VerwaltungHauptlinks.ersteSchritte.path+"#/zeiten"},
    maAnlegenZeiten1:{id:"zeiten-einzeln", title:"Möglichkeit 1 - Einzelne Tage eintragen", searchTitle:"Erste Schritte - Einzelne Tage eintragen", path:VerwaltungHauptlinks.ersteSchritte.path+"#/zeiten-einzeln"},
    maAnlegenZeiten2:{id:"zeiten-mehrere", title:"Möglichkeit 2 - Normalarbeitszeit für mehrere Tage eintragen", searchTitle:"Erste Schritte - Mehrere Tage eintragen", path:VerwaltungHauptlinks.ersteSchritte.path+"#/zeiten-mehrere"},
    web:{id:"web", title:"4 - Weboberfläche", searchTitle:"Erste Schritte - Weboberfläche", path:VerwaltungHauptlinks.ersteSchritte.path+"#/web"},
    searchable: function(){
        return Object.values(this).flatMap(value => "id" in value ? {title:value.searchTitle??value.title, path:value.path} : []) as SearchLink[]
    },
}

export const VerwaltungStempelSublinks:LinkContainer&{
    tagesstempel: SearchLink,
    tagesstempelBearbeiten: SearchLink
} = {
    tagesstempel: { id: "tagesstempel", path: VerwaltungHauptlinks.stempel.path+"/handbuch/verwaltung/#tagesstempel",title: "Tagesstempel"},    
    tagesstempelBearbeiten: { id: "tagesstempelBearbeiten", path: VerwaltungHauptlinks.stempel.path + "/handbuch/verwaltung/#tagesstempelBearbeiten", title: "Tagesstempel bearbeiten" },
    searchable: function () {
        return Object.values(this).flatMap(value => "id" in value ? { title: value.searchTitle ?? value.title, path: value.path } : []) as SearchLink[]
    },
}

export const VerwaltungAbwesenheitenSublinks:LinkContainer&{
    uebersicht: SearchLink,
    beantragt: SearchLink,
    tagesstempelBeantragung: SearchLink,
    regeln: SearchLink,
    krankenstand: SearchLink,
    sonstige: SearchLink
} = {
    uebersicht: { id: "uebersicht", title:"Übersicht", searchTitle:"Übersicht über Abwesenheiten",path:VerwaltungHauptlinks.abwesenheiten.path+"#uebersicht"},
    beantragt: { id: "beantragt", title:"Beantragte Abwesenheiten",path:VerwaltungHauptlinks.abwesenheiten.path+"#beantragt"},
    tagesstempelBeantragung: { id: "tagesstempelBeantragt", title: "Tagesstempel beantragen", searchTitle:"Tagesstempel beantragen (Home-Office, ...)", path: VerwaltungHauptlinks.abwesenheiten.path +"#tagesstempelBeantragt"},
    regeln: { id: "regeln", title:"Regeln", searchTitle: "Regeln bei Abwesenheitsanträgen",path:VerwaltungHauptlinks.abwesenheiten.path+"#regeln"},
    krankenstand: { id: "krankenstand", title:"Krankenstand & Dienstfreistellung",path:VerwaltungHauptlinks.abwesenheiten.path+"#krankenstand"},
    sonstige: { id: "sonstige", title:"Sonstige Abwesenheiten",path:VerwaltungHauptlinks.abwesenheiten.path+"#sonstige"},
    searchable: function(){
        return Object.values(this).flatMap(value => "id" in value ? {title:value.searchTitle??value.title, path:value.path} : []) as SearchLink[]
    },
}

export const VerwaltungEinstellungenSublinks:LinkContainer&{
    arbeitszeitmodelle: SearchLink,
    "arbeitszeitmodelle-ansehen": SearchLink,
    "arbeitszeitmodelle-erstellen": SearchLink,
    "arbeitszeitmodelle-mehrwoechig": SearchLink,
    zyklus: SearchLink,
    "bsp-vollzeit": SearchLink,
    konfiguration: SearchLink,
    stichtag: SearchLink,
    logo: SearchLink,
    authentifizierungsmethoden: SearchLink,
    mailservice: SearchLink,
    mailvorlagen: SearchLink,
    "freie-tage": SearchLink
    "abteilungen-standorte": SearchLink
    bilder: SearchLink
} = {
    arbeitszeitmodelle: {id: "arbeitszeitmodelle", title:"Arbeitszeitmodelle", path: VerwaltungHauptlinks.einstellungen.path+"#arbeitszeitmodelle"},
    "arbeitszeitmodelle-ansehen": {id: "arbeitszeitmodelle-ansehen", title:"Arbeitszeitmodelle ansehen", path: VerwaltungHauptlinks.einstellungen.path+"#arbeitszeitmodelle-ansehen"},
    "arbeitszeitmodelle-erstellen": {id: "arbeitszeitmodelle-erstellen", title:"Arbeitszeitmodelle erstellen", path: VerwaltungHauptlinks.einstellungen.path+"#arbeitszeitmodelle-erstellen"},
    "arbeitszeitmodelle-mehrwoechig": {id: "arbeitszeitmodelle-mehrwoechig", title:"Mehrwöchige Arbeitszeitmodelle", path: VerwaltungHauptlinks.einstellungen.path+"#arbeitszeitmodelle-mehrwoechig"},
    zyklus: {id: "zyklus", title:"Zyklus", path: VerwaltungHauptlinks.einstellungen.path+"#zyklus"},
    "bsp-vollzeit": {id: "bsp-vollzeit", title:"Beispiel: Vollzeit", searchTitle: "Arbeitszeitmodell-Beispiel: Vollzeit", path: VerwaltungHauptlinks.einstellungen.path+"#bsp-vollzeit"},
    konfiguration: {id: "konfiguration", title:"Konfiguration", path: VerwaltungHauptlinks.einstellungen.path+"#konfiguration"},
    stichtag: {id: "stichtag", title:"Stichtag", path: VerwaltungHauptlinks.einstellungen.path+"#stichtag"},
    logo: {id: "logo", title:"Logo", path: VerwaltungHauptlinks.einstellungen.path+"#logo"},
    authentifizierungsmethoden: {id: "authentifizierungsmethoden", title:"Authentifizierungsmethoden", path: VerwaltungHauptlinks.einstellungen.path+"#authentifizierungsmethoden"},
    mailservice: {id: "mailservice", title:"Mailservice", path: VerwaltungHauptlinks.einstellungen.path+"#mailservice"},
    mailvorlagen: {id: "mailvorlagen", title:"Mailvorlagen", path: VerwaltungHauptlinks.einstellungen.path+"#mailvorlagen"},
    "freie-tage": {id: "freie-tage", title:"Freie Tage", path: VerwaltungHauptlinks.einstellungen.path+"#freie-tage"},
    "abteilungen-standorte": {id: "abteilungen-standorte", title:"Abteilungen & Standorte", path: VerwaltungHauptlinks.einstellungen.path+"#abteilungen-standorte"},
    bilder: {id: "bilder", title:"Bilder", path: VerwaltungHauptlinks.einstellungen.path+"#bilder"},
    searchable: function(){
        return Object.values(this).flatMap(value => "id" in value ? {title:value.searchTitle??value.title, path:value.path} : []) as SearchLink[]
    },
}

export const VerwaltungMitarbeiterSublinks:LinkContainer&{
    neu: SearchLink,
    "neu-rollen": SearchLink,
    abteilungsleiter: SearchLink,
    "neu-login": SearchLink,
    austragen:SearchLink,
    inaktiv: SearchLink,
    loeschen: SearchLink,
    austrittsdatum: SearchLink,
    "urlaubssaldo-korrigieren": SearchLink,
    urlaubssaldo: SearchLink,
    einstellungen: SearchLink,
    "arbeitszeitmodell-wechseln": SearchLink,
    login: SearchLink,
    logindaten: SearchLink,
    "single-sign-on": SearchLink,
    finger: SearchLink,
    nfc: SearchLink,
    "simpel-standard": SearchLink,
    "abteilung-standort": SearchLink,
    "startbildschirm": SearchLink,
    "sonstiges": SearchLink
} = {
    neu: {id:"neu", title:"Mitarbeiter anlegen", path:VerwaltungHauptlinks.mitarbeiter.path+"#neu"},
    "neu-rollen": {id:"neu-rollen", title:"Rolle", searchTitle:"Mitarbeiter erstellen - Rollen festlegen", path:VerwaltungHauptlinks.mitarbeiter.path+"#neu-rollen"},
    abteilungsleiter: {id:"abteilungsleiter", title:"Abteilungsleiter", path:VerwaltungHauptlinks.mitarbeiter.path+"#abteilungsleiter"},
    "neu-login": {id:"neu-login", title:"Login", searchTitle:"Mitarbeiter erstellen - Login festlegen", path:VerwaltungHauptlinks.mitarbeiter.path+"#neu-login"},
    austragen: {id:"austragen", title:"Mitarbeiter austragen", path:VerwaltungHauptlinks.mitarbeiter.path+"#austragen"},
    inaktiv: {id:"inaktiv", title:"Mitarbeiter inaktiv setzen", path:VerwaltungHauptlinks.mitarbeiter.path+"#inaktiv"},
    loeschen: {id:"loeschen", title:"Mitarbeiter löschen", path:VerwaltungHauptlinks.mitarbeiter.path+"#loeschen"},
    austrittsdatum: {id:"austrittsdatum", title:"Austrittsdatum setzen", path:VerwaltungHauptlinks.mitarbeiter.path+"#austrittsdatum"},
    urlaubssaldo: {id:"urlaubssaldo", title:"Urlaubssaldo", path:VerwaltungHauptlinks.mitarbeiter.path+"#urlaubssaldo"},
    "urlaubssaldo-korrigieren": {id:"urlaubssaldo-korrigieren", title:"Urlaubssaldo korrigieren", path:VerwaltungHauptlinks.mitarbeiter.path+"#urlaubssaldo-korrigieren"},
    einstellungen: {id:"einstellungen", title:"Mitarbeitereinstellungen", path:VerwaltungHauptlinks.mitarbeiter.path+"#einstellungen"},
    "arbeitszeitmodell-wechseln": {id:"arbeitszeitmodell-wechseln", title:"Arbeitszeitmodell wechseln", path:VerwaltungHauptlinks.mitarbeiter.path+"#arbeitszeitmodell-wechseln"},
    login: {id:"login", title:"Logindaten", searchTitle:"Mitarbeitereinstellungen - Login", path:VerwaltungHauptlinks.mitarbeiter.path+"#login"},
    logindaten: {id:"logindaten", title:"Benutzername und Passwort einstellen", path:VerwaltungHauptlinks.mitarbeiter.path+"#"},
    "single-sign-on": {id:"single-sign-on", title:"Single Sign-On", searchTitle:"Mitarbeiter - Single Sign On konfigurieren", path:VerwaltungHauptlinks.mitarbeiter.path+"#logindaten"},
    finger: {id:"finger", title:"Fingerabdrücke", searchTitle:"Mitarbeiter - Fingerabdrücke konfigurieren", path:VerwaltungHauptlinks.mitarbeiter.path+"#finger"},
    nfc: {id:"nfc", title:"NFC", searchTitle:"Mitarbeiter - NFC konfigurieren", path:VerwaltungHauptlinks.mitarbeiter.path+"#nfc"},
    "simpel-standard": {id:"simpel-standard", title:"Simpel & Standard-Modus", path:VerwaltungHauptlinks.mitarbeiter.path+"#simpel-standard"},
    "abteilung-standort": {id:"abteilung-standort", title:"Abteilung & Standort", searchTitle:"Mitarbeiter - Abteilung & Standort zuordnen", path:VerwaltungHauptlinks.mitarbeiter.path+"#abteilung-standort"},
    "startbildschirm": {id:"startbildschirm", title:"Startbildschirm & Standard für Doppelklick", path:VerwaltungHauptlinks.mitarbeiter.path+"#startbildschirm"},
    "sonstiges": {id:"sonstiges", title:"Sonstiges - Notizen", searchTitle: "Mitarbeiter - Sonstiges (Notizen)", path:VerwaltungHauptlinks.mitarbeiter.path+"#sonstiges"},
    searchable: function(){
        return Object.values(this).flatMap(value => "id" in value ? {title:value.searchTitle??value.title, path:value.path} : []) as SearchLink[]
     },
}

export const VerwaltungArbeitszeitenSublinks:LinkContainer&{
    prozessablauf: SearchLink,
    uebersicht: SearchLink,
    mitarbeiterliste: SearchLink,
    "stempel-ansicht": SearchLink,
    zeiten: SearchLink,
    zeitenbuttons: SearchLink,
    "zeiten-fehlerhaft": SearchLink,
    "zeiten-bearbeiten": SearchLink,
    zeitsaldo: SearchLink,
    ueberstunden: SearchLink,
    mehrarbeitszeit: SearchLink,
    auszahlungsarten: SearchLink
} = {
    prozessablauf: {id:"prozessablauf", title:"Prozessablauf", path:VerwaltungHauptlinks.arbeitszeiten.path+"#prozessablauf"},
    uebersicht: {id: "uebersicht", title:"Übersicht über alle Mitarbeiter", path:VerwaltungHauptlinks.arbeitszeiten.path+"#uebersicht"},
    mitarbeiterliste: {id: "mitarbeiterliste", title:"Mitarbeiterliste", path:VerwaltungHauptlinks.arbeitszeiten.path+"#mitarbeiterliste"},
    "stempel-ansicht": {id: "stempel-ansicht", title:"Stempel-Ansicht", path:VerwaltungHauptlinks.arbeitszeiten.path+"#stempel-ansicht"},
    zeiten: {id: "zeiten", title:"Zeiten einzelner Mitarbeiter", path:VerwaltungHauptlinks.arbeitszeiten.path+"#zeiten"},
    zeitenbuttons: {id: "zeitenbuttons", title:"Erklärung der Buttons", searchTitle:"Arbeitszeiten - Zeitausgleich ergänzen, Arbeitszeit ergänzen, Tagesstempel ergänzen, Neu berechnen, Mehrarbeitszeit", path:VerwaltungHauptlinks.arbeitszeiten.path+"#zeitenbuttons"},
    "zeiten-fehlerhaft": {id: "zeiten-fehlerhaft", title:"Fehlerhafte Einträge anzeigen", path:VerwaltungHauptlinks.arbeitszeiten.path+"#zeiten-fehlerhaft"},
    "zeiten-bearbeiten": {id: "zeiten-bearbeiten", title:"Arbeitszeiten anzeigen, bearbeiten, korrigieren", path:VerwaltungHauptlinks.arbeitszeiten.path+"#zeiten-bearbeiten"},
    zeitsaldo: {id: "zeitsaldo", title:"Zeitsaldo", path:VerwaltungHauptlinks.arbeitszeiten.path+"#zeitsaldo"},
    ueberstunden: {id: "ueberstunden", title:"Überstunden", path:VerwaltungHauptlinks.arbeitszeiten.path+"#ueberstunden"},
    mehrarbeitszeit: {id: "mehrarbeitszeit", title:"Auszahlung", searchTitle: "Mehrarbeitszeit / Überstunden auszahlen", path:VerwaltungHauptlinks.arbeitszeiten.path+"#mehrarbeitszeit"},
    auszahlungsarten: {id: "auszahlungsarten", title:"Auszahlungsarten", searchTitle: "Mehrarbeitszeit - Auszahlungsarten", path:VerwaltungHauptlinks.arbeitszeiten.path+"#auszahlungsarten"},
    searchable: function(){
        return Object.values(this).flatMap(value => "id" in value ? {title:value.searchTitle??value.title, path:value.path} : []) as SearchLink[]
     }
}

export const VerwaltungAuswertungenSublinks:LinkContainer&{
    zeitzettel: SearchLink,
    "stempel-auswertung": SearchLink
} = {
    zeitzettel: {id:"zeitzettel", title:"Zeitzettel", path:VerwaltungHauptlinks.auswertungen.path+"#zeitzettel"},
    "stempel-auswertung": {id:"stempel-auswertung", title:"Stempel-Auswertung", path:VerwaltungHauptlinks.auswertungen.path+"#stempel-auswertung"},
    searchable: function(){
        return Object.values(this).flatMap(value => "id" in value ? {title:value.searchTitle??value.title, path:value.path} : []) as SearchLink[]
     }
}

export const VerwaltungMontageSublinks:LinkContainer&{
    verwaltung: SearchLink,
    "montagezeiten-export": SearchLink
    taggeld: SearchLink
} = {
    verwaltung: {id:"verwaltung", title:"Verwaltung", path:VerwaltungHauptlinks.montage.path+"#verwaltung"},
    "montagezeiten-export": {id:"montagezeiten-export", title:"Montagezeiten-Export", path:VerwaltungHauptlinks.montage.path+"#montagezeiten-export"},
    taggeld: {id:"taggeld", title:"Taggeldverwaltung", path:VerwaltungHauptlinks.montage.path+"#taggeld"},
    searchable: function(){
        return Object.values(this).flatMap(value => "id" in value ? {title:value.searchTitle??value.title, path:value.path} : []) as SearchLink[]
     }
}

export const VerwaltungLeistungsnachweisSublinks: LinkContainer&{
    mitarbeiterzugriff: SearchLink,
    verwaltung: SearchLink,
} = {
    mitarbeiterzugriff: {id:"mitarbeiterzugriff", title:"Zugriff für Mitarbeitende", searchTitle:"Mitarbeiterzugriff auf Leistungsnachweise", path:VerwaltungHauptlinks.leistungsnachweis.path+"#mitarbeiterzugriff"},
    verwaltung: {id:"verwaltung", title:"Verwaltung", searchTitle:"Leistungsnachweise in der Verwaltung", path:VerwaltungHauptlinks.leistungsnachweis.path+"#verwaltung"},
    searchable: function(){
        return Object.values(this).flatMap(value => "id" in value ? {title:value.searchTitle??value.title, path:value.path} : []) as SearchLink[]
     },
}

export const VerwaltungProjekteSublinks: LinkContainer&{
    funktionen: SearchLink,
    verwaltung: SearchLink,
    projektAnsicht: SearchLink
    projektNeu: SearchLink
    eingaben: SearchLink,
    mitarbeiterZuordnen: SearchLink,
    taetigkeitZuordnen: SearchLink,
    subZuordnen: SearchLink,
    taetigkeitVerwalten: SearchLink,
    kontierung: SearchLink,
    kontierungVerwaltung: SearchLink,
    projektkontierungen: SearchLink,
    kontierungenBearbeiten: SearchLink,
    berufsschule: SearchLink,
} = {
    funktionen: {id:"funktionen", title:"Funktionsübersicht", searchTitle:"Funktionen der Projektverwaltung", path:VerwaltungHauptlinks.projekte.path+"#funktionen"},
    verwaltung: {id:"verwaltung", title:"Verwaltung", searchTitle:"Projekte in der Verwaltung", path:VerwaltungHauptlinks.projekte.path+"#verwaltung"},
    projektAnsicht: {id:"projekt-ansicht", title:"Projekt-Ansicht", path:VerwaltungHauptlinks.projekte.path+"#projekt-ansicht"},
    projektNeu: {id:"projekt-neu", title:"Projekt anlegen", path:VerwaltungHauptlinks.projekte.path+"#projekt-neu"},
    eingaben: {id:"eingaben", title:"Eingaben", searchTitle:"Projekt anlegen - Eingaben", path:VerwaltungHauptlinks.projekte.path+"#eingaben"},
    mitarbeiterZuordnen: {id:"ma-zuordnen", title:"Mitarbeiter zuordnen", searchTitle:"Mitarbeiter zu Projekt zuordnen", path:VerwaltungHauptlinks.projekte.path+"#ma-zuordnen"},
    taetigkeitZuordnen: {id:"taetigkeit-zuordnen", title:"Tätigkeiten zuordnen", searchTitle:"Tätigkeiten zu Projekt zuordnen", path:VerwaltungHauptlinks.projekte.path+"#taetigkeit-zuordnen"},
    subZuordnen: {id:"sub-zuordnen", title:"Sub-Projekt zuordnen", path:VerwaltungHauptlinks.projekte.path+"#sub-zuordnen"},
    taetigkeitVerwalten: {id:"taetigkeit-verwaltung", title:"Tätigkeiten verwalten", path:VerwaltungHauptlinks.projekte.path+"#taetigkeit-verwaltung"},
    kontierung: {id:"kontierung", title:"Kontierungen", path:VerwaltungHauptlinks.projekte.path+"#kontierung"},
    kontierungVerwaltung: {id:"kontierung-verwaltung", title:"Verwaltung", searchTitle:"Kontierungen in der Verwaltung", path:VerwaltungHauptlinks.projekte.path+"#kontierung-verwaltung"},
    projektkontierungen: {id:"projektkontierungen", title:"Projektkontierungen", path:VerwaltungHauptlinks.projekte.path+"#projektkontierungen"},
    kontierungenBearbeiten: {id:"kontierung-bearbeiten", title:"Kontierungen bearbeiten", path:VerwaltungHauptlinks.projekte.path+"#kontierung-bearbeiten"},
    berufsschule: {id:"berufsschule", title:"Berufsschule", path:VerwaltungHauptlinks.projekte.path+"#berufsschule"},
    searchable: function(){
        return Object.values(this).flatMap(value => "id" in value ? {title:value.searchTitle??value.title, path:value.path} : []) as SearchLink[]
    }
}

export const VerwaltungPlanungSublinks: LinkContainer & {
    ressourcenplanung: SearchLink,
} = {
    ressourcenplanung: {id:"ressourcenplanung", title:"Ressourcenplanung", path:VerwaltungHauptlinks.ressourcen.id+"#ressourcenplanung"},
    searchable: function () {
        return Object.values(this).flatMap(value => "id" in value ? { title: value.searchTitle ?? value.title, path: value.path } : []) as SearchLink[]
    }
}


export const VerwaltungSearchItems = [...VerwaltungHauptlinks.searchable(), ...VerwaltungAbwesenheitenSublinks.searchable(), ...VerwaltungAuswertungenSublinks.searchable(), ...VerwaltungArbeitszeitenSublinks.searchable(), ...VerwaltungEinstellungenSublinks.searchable(), ...VerwaltungProjekteSublinks.searchable(), ...VerwaltungPlanungSublinks.searchable(), ...VerwaltungLeistungsnachweisSublinks.searchable(), ...VerwaltungMitarbeiterSublinks.searchable(), ...VerwaltungMontageSublinks.searchable()]

export const MitarbeiterSearchItems = [...MitarbeiterHauptlinks.searchable(), ...MitarbeiterZeiterfassungSublinks.searchable()]