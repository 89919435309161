/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TaetigkeitenModel,
    TaetigkeitenModelFromJSON,
    TaetigkeitenModelFromJSONTyped,
    TaetigkeitenModelToJSON,
} from './';

/**
 * View-Model: Dropdown für Projekte
 * @export
 * @interface ProjekteModel
 */
export interface ProjekteModel {
    /**
     * Projekt-ID
     * @type {string}
     * @memberof ProjekteModel
     */
    id?: string;
    /**
     * NamePath
     * @type {string}
     * @memberof ProjekteModel
     */
    bezeichnung?: string | null;
    /**
     * Projektbeschreibung
     * @type {string}
     * @memberof ProjekteModel
     */
    beschreibung?: string | null;
    /**
     * Anmerkung erforderlich?
     * @type {boolean}
     * @memberof ProjekteModel
     */
    anmerkungErforderlich?: boolean;
    /**
     * Projekt Favorit?
     * @type {boolean}
     * @memberof ProjekteModel
     */
    favorit?: boolean;
    /**
     * Tätigkeiten des Projekts
     * @type {Array<TaetigkeitenModel>}
     * @memberof ProjekteModel
     */
    taetigkeiten?: Array<TaetigkeitenModel> | null;
}

export function ProjekteModelFromJSON(json: any): ProjekteModel {
    return ProjekteModelFromJSONTyped(json, false);
}

export function ProjekteModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjekteModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'bezeichnung': !exists(json, 'bezeichnung') ? undefined : json['bezeichnung'],
        'beschreibung': !exists(json, 'beschreibung') ? undefined : json['beschreibung'],
        'anmerkungErforderlich': !exists(json, 'anmerkungErforderlich') ? undefined : json['anmerkungErforderlich'],
        'favorit': !exists(json, 'favorit') ? undefined : json['favorit'],
        'taetigkeiten': !exists(json, 'taetigkeiten') ? undefined : (json['taetigkeiten'] === null ? null : (json['taetigkeiten'] as Array<any>).map(TaetigkeitenModelFromJSON)),
    };
}

export function ProjekteModelToJSON(value?: ProjekteModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'bezeichnung': value.bezeichnung,
        'beschreibung': value.beschreibung,
        'anmerkungErforderlich': value.anmerkungErforderlich,
        'favorit': value.favorit,
        'taetigkeiten': value.taetigkeiten === undefined ? undefined : (value.taetigkeiten === null ? null : (value.taetigkeiten as Array<any>).map(TaetigkeitenModelToJSON)),
    };
}


