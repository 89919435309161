import * as React from "react";
import { TimePicker } from "../TimePicker"
import { dateToStringTime, extendStringTimeToDate, stringTimeToDate, stringToDateString, validateTimestring } from "../Helper";
import { Button, Grid, Icon, Input, Popup } from "semantic-ui-react";
import { Field, FieldProps } from "formik";
import { EAZTyp, } from "../../generated/models/EAZTyp";
import { ZeitstempelModel } from "../../generated/models/ZeitstempelModel";

interface IMontageStempelProps {
  montagestempel: ZeitstempelModel;
  kommenGehen: string;
  eazTyp: EAZTyp;
  rows: number;
  indexKey: string;
  index: number;
  edit: boolean;
  kommen: boolean;
  gehen: boolean;
  datum: Date;
  setfieldvalue: (field:string, value:any, shouldValidate?: boolean) => void;
}

export const MontageStempel: React.FC<IMontageStempelProps> = (props) => {
  let readonly = props.edit;
  let hasError = (props.montagestempel && props.montagestempel.fehler && props.montagestempel.fehler.length >= 0) ? true : false;
  return (
    <Grid>
      <Grid.Row className='stempel-nopadding-bottom'>
        <Grid.Column>
            <div style={{ float: 'left' }} className={"timeinput-block"}>
              <Field disabled={readonly}>
                {(field: FieldProps) => {
                  return (
                    <Button
                      key={`${props.indexKey}montagebutton`}
                      content={props.kommen ? "Beginn" : "Ende"}
                      size={'mini'}
                      type={'button'}
                      className={"btn-grey"}
                      onClick={() => {
                        var kDate = new Date();
                        if (!readonly) {
                          props.setfieldvalue(`zeitstempel.montageRow[${props.index}].${props.kommenGehen}.stempel`, kDate);
                        }
                      }}
                    />
                  )
                }}
              </Field>
            </div>
            <Field disabled={readonly}>
            {() => {
              var value = stringToDateString(props.montagestempel && props.montagestempel.stempel, props.datum);
                var name = `zeitstempel.montageRow[${props.index}].${props.kommenGehen}.stempel`;
                var validate = validateTimestring(value);
              var timeValue = dateToStringTime(
                props.montagestempel && props.montagestempel.stempel);
              return (
                <div key={`${props.indexKey}montagedivPicker`} className={(hasError || !validate) ? "timeinput-block errorBorder" : "timeinput-block"}>
                  <TimePicker
                    disabled={readonly}
                    clearable={!readonly}
                    setfieldvalue={props.setfieldvalue}
                    onChange={(e, data) => props.setfieldvalue}
                    name={`zeitstempel.montageRow[${props.index}].${props.kommenGehen}.stempel`}
                    date={props.datum}
                    value={timeValue}
                    />
                  <Input
                    readOnly={readonly}
                    className={"noLeftBorder left-right-padding fill-up"}
                    name={`zeitstempel.montageRow[${props.index}].${props.kommenGehen}.stempel`}
                    type="text"
                    placeholder="hh:mm"
                    value={value}
                    onBlur={
                      () => {
                        if (typeof value === 'string' && value.length > 0) {
                          var stringValue = extendStringTimeToDate(value);
                          validate = validateTimestring(stringValue);
                          if (validate) {
                            props.setfieldvalue(name, stringTimeToDate(stringValue, props.datum));
                          }
                        }
                      }}
                    onChange={(e, data) => {
                      var value = data.value;
                      if (value.length === 6) {
                        value = data.value.substring(0, 5);
                      }
                      validate = validateTimestring(value);
                      if (!validate) {
                        props.setfieldvalue(data.name, value);
                      }
                      else if (!data.value) {
                        props.setfieldvalue(data.name, null);
                      }
                      else {
                        props.setfieldvalue(data.name, stringTimeToDate(value, props.datum));
                      }
                    }}
                    />
                {!validate && value && value.length >= 5 ?
                    <div>
                      <Popup
                        content={() => { return (<div>Bitte überprüfen Sie ihre Zeiteingabe. (zb.: 12:35)</div>) }}
                        trigger={
                          <Icon
                            style={{ marginLeft: '0.2em' }}
                            name={!validate ? 'info circle' : undefined}>
                          </Icon>
                        }
                      />
                    </div> :
                    hasError ?
                      <div>
                          <Popup
                            content={() => { return (<div>{props.montagestempel && props.montagestempel.fehler}</div>) }}
                            trigger={
                            <Icon
                              style={{ marginLeft: '0.2em' }}
                              name={'info circle'}>
                            </Icon>
                          }
                        />
                      </div> : <div key={`${props.indexKey}divPickerelse`}> </div>
                    } 
                    </div>)
            }}
            </Field>
          </Grid.Column>  
      </Grid.Row>
    </Grid>
  );
};
