import * as React from "react";
import { TimeInput, TimeInputProps } from "semantic-ui-calendar-react";
import { stringTimeToDate } from "./Helper";

export const convertDateToString = (time?: Date | null) => {
  if (!time) {
    return "";
  }
  let hour = time.getHours().toLocaleString();
  let minute = time.getMinutes().toLocaleString();
  if (time.getHours() < 10) {
    hour = "0" + time.getHours().toLocaleString();
  }
  if (time.getMinutes() < 10) {
    minute = "0" + time.getMinutes().toLocaleString();
  }
  return hour + ":" + minute;
};

export const numberToHourMinute = (min: number) => {
  const hh = Math.floor(min / 60);
  const mm = min % 60;

  return `${hh < 10 ? "0" + hh : hh}:${mm < 10 ? "0" + mm : mm}`;
};

export const stringTimeToInt = (time: string) => {
  var timeSplit = time.split(":")
  var hours = parseInt(timeSplit[0]);
  var minutes = parseInt(timeSplit[1]);

  return minutes + hours * 60;
}

export const TimePicker = (props: TimeInputProps) => {
  const { disabled, clearable, onChange, ...restProps } = props;
  return (
    <div className="pickerSize">
    <TimeInput
        {...restProps}
      disabled={disabled}
      placeholder="Time"
      popupPosition="bottom center"
      animation="drop"
        duration={0}
        className="pickerSize"
      closable={true}
      clearable={clearable}
      iconPosition="left"
        onChange={(e, data) => {          
        if (e.type === 'click') {
          props.setfieldvalue(props.name, null);
        }
        else {
          var value = stringTimeToDate(data.value, props.date);
          props.setfieldvalue(props.name, value);
        }
      }}
      />
      </div>
  );
};