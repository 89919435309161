/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EOptions,
    EOptionsFromJSON,
    EOptionsFromJSONTyped,
    EOptionsToJSON,
    EUIMode,
    EUIModeFromJSON,
    EUIModeFromJSONTyped,
    EUIModeToJSON,
} from './';

/**
 * ViewModel: VersionInfo
 * @export
 * @interface VersionViewModel
 */
export interface VersionViewModel {
    /**
     * Produkt Name
     * @type {string}
     * @memberof VersionViewModel
     */
    produkt?: string | null;
    /**
     * Beschreibung
     * @type {string}
     * @memberof VersionViewModel
     */
    beschreibung?: string | null;
    /**
     * Name des Unternehmens
     * @type {string}
     * @memberof VersionViewModel
     */
    unternehmen?: string | null;
    /**
     * App-Name des Host-Environment
     * @type {string}
     * @memberof VersionViewModel
     */
    hostAppName?: string | null;
    /**
     * Environment des Host-Environment
     * @type {string}
     * @memberof VersionViewModel
     */
    hostEnvironment?: string | null;
    /**
     * Version des Host-Environment
     * @type {string}
     * @memberof VersionViewModel
     */
    hostVersion?: string | null;
    /**
     * Version der Web-Application
     * @type {string}
     * @memberof VersionViewModel
     */
    webAppVersion?: string | null;
    /**
     * Version der Utils-Assembly
     * @type {string}
     * @memberof VersionViewModel
     */
    utilsVersion?: string | null;
    /**
     * 
     * @type {EOptions}
     * @memberof VersionViewModel
     */
    options?: EOptions;
    /**
     * Database-Server-Name
     * @type {string}
     * @memberof VersionViewModel
     */
    dbServer?: string | null;
    /**
     * Database-Name
     * @type {string}
     * @memberof VersionViewModel
     */
    dbName?: string | null;
    /**
     * Version des DB-Schema
     * @type {string}
     * @memberof VersionViewModel
     */
    readonly dbVersion?: string | null;
    /**
     * Database-Error
     * @type {string}
     * @memberof VersionViewModel
     */
    dbError?: string | null;
    /**
     * User is Admin?
     * @type {boolean}
     * @memberof VersionViewModel
     */
    isAdmin?: boolean;
    /**
     * User is Logged in?
     * @type {boolean}
     * @memberof VersionViewModel
     */
    isLoggedIn?: boolean;
    /**
     * 
     * @type {EUIMode}
     * @memberof VersionViewModel
     */
    uiMode?: EUIMode;
    /**
     * UI mit Benutzer / Passwort?
     * @type {boolean}
     * @memberof VersionViewModel
     */
    loginUsed?: boolean;
}

export function VersionViewModelFromJSON(json: any): VersionViewModel {
    return VersionViewModelFromJSONTyped(json, false);
}

export function VersionViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): VersionViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'produkt': !exists(json, 'produkt') ? undefined : json['produkt'],
        'beschreibung': !exists(json, 'beschreibung') ? undefined : json['beschreibung'],
        'unternehmen': !exists(json, 'unternehmen') ? undefined : json['unternehmen'],
        'hostAppName': !exists(json, 'hostAppName') ? undefined : json['hostAppName'],
        'hostEnvironment': !exists(json, 'hostEnvironment') ? undefined : json['hostEnvironment'],
        'hostVersion': !exists(json, 'hostVersion') ? undefined : json['hostVersion'],
        'webAppVersion': !exists(json, 'webAppVersion') ? undefined : json['webAppVersion'],
        'utilsVersion': !exists(json, 'utilsVersion') ? undefined : json['utilsVersion'],
        'options': !exists(json, 'options') ? undefined : EOptionsFromJSON(json['options']),
        'dbServer': !exists(json, 'dbServer') ? undefined : json['dbServer'],
        'dbName': !exists(json, 'dbName') ? undefined : json['dbName'],
        'dbVersion': !exists(json, 'dbVersion') ? undefined : json['dbVersion'],
        'dbError': !exists(json, 'dbError') ? undefined : json['dbError'],
        'isAdmin': !exists(json, 'isAdmin') ? undefined : json['isAdmin'],
        'isLoggedIn': !exists(json, 'isLoggedIn') ? undefined : json['isLoggedIn'],
        'uiMode': !exists(json, 'uiMode') ? undefined : EUIModeFromJSON(json['uiMode']),
        'loginUsed': !exists(json, 'loginUsed') ? undefined : json['loginUsed'],
    };
}

export function VersionViewModelToJSON(value?: VersionViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'produkt': value.produkt,
        'beschreibung': value.beschreibung,
        'unternehmen': value.unternehmen,
        'hostAppName': value.hostAppName,
        'hostEnvironment': value.hostEnvironment,
        'hostVersion': value.hostVersion,
        'webAppVersion': value.webAppVersion,
        'utilsVersion': value.utilsVersion,
        'options': EOptionsToJSON(value.options),
        'dbServer': value.dbServer,
        'dbName': value.dbName,
        'dbError': value.dbError,
        'isAdmin': value.isAdmin,
        'isLoggedIn': value.isLoggedIn,
        'uiMode': EUIModeToJSON(value.uiMode),
        'loginUsed': value.loginUsed,
    };
}


