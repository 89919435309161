/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ELookup {
    None = 'None',
    Flag01 = 'Flag01',
    Flag02 = 'Flag02',
    Flag03 = 'Flag03',
    Flag04 = 'Flag04',
    Flag05 = 'Flag05',
    Flag06 = 'Flag06',
    Flag07 = 'Flag07',
    Flag08 = 'Flag08',
    Flag09 = 'Flag09',
    Flag10 = 'Flag10',
    Flag11 = 'Flag11',
    Flag12 = 'Flag12',
    Flag13 = 'Flag13',
    Flag14 = 'Flag14',
    Flag15 = 'Flag15',
    Flag16 = 'Flag16',
    Flag17 = 'Flag17',
    Flag18 = 'Flag18',
    Flag19 = 'Flag19',
    Flag20 = 'Flag20',
    Flag21 = 'Flag21',
    Flag22 = 'Flag22',
    Flag23 = 'Flag23',
    Flag24 = 'Flag24',
    Flag25 = 'Flag25',
    Flag26 = 'Flag26',
    Flag27 = 'Flag27',
    Flag28 = 'Flag28',
    Flag29 = 'Flag29',
    Flag30 = 'Flag30',
    Flag31 = 'Flag31'
}

export function ELookupFromJSON(json: any): ELookup {
    return ELookupFromJSONTyped(json, false);
}

export function ELookupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ELookup {
    return json as ELookup;
}

export function ELookupToJSON(value?: ELookup | null): any {
    return value as any;
}

