import * as React from "react";
import {
  Dropdown,
  DropdownProps,
  Checkbox,
  CheckboxProps,
  Button,
  FormProps,
  Form,
  Radio,
  Label,
  Header,
  FormFieldProps,
  TextAreaProps,
  TextArea,
  Segment,
} from "semantic-ui-react";
import {
  DateInputProps,
  DateInput,
  DatesRangeInputProps,
  DatesRangeInput,
  DateTimeInputProps,
  DateTimeInput,
} from "semantic-ui-calendar-react";
import { Col, Row } from "react-flexbox-grid";
import { FieldArray, ArrayHelpers } from "formik";
import * as Yup from "yup";
import { renderFormField } from "./Simpleform";
import { IFormField } from ".";
import { FormField } from "./FormField";
import 'moment/locale/de'

export const SimpleFormDropdown = React.memo(
  (props: DropdownProps & FormFieldProps) => {
    const { onChange, ...restProps } = props;
    return (
      <FormField
        {...restProps}
        control={(cprops: DropdownProps) => (
          <Dropdown
            {...cprops}
            fluid
            onChange={(e, { value }) => {
              props.setfieldvalue(props.name, value);
            }}
          />
        )}
      />
    );
  }
);

class TAComp extends React.Component<TextAreaProps> {
  render = () => {
    return (
      <TextArea
        {...this.props}
        onChange={(e, { value }) => {
          this.props.setfieldvalue(this.props.name, value);
        }}
      />
    );
  };
}

export const SimpleFormTextArea = React.memo(
  (props: TextAreaProps & FormFieldProps) => {
    const { onChange, ...restProps } = props;
    return <FormField {...restProps} control={TAComp} />;
  },
  (prevProps, nextProps) => {
    return (
      prevProps.name === nextProps.name && prevProps.value === nextProps.value
    );
  }
);

export const SimpleFormDateTimePicker = React.memo(
  (props: DateTimeInputProps & FormFieldProps) => {
    const { onChange, dateTimeFormat, ...restProps } = props;
    const formfieldProps: FormFieldProps = restProps;
    return (
      <FormField
        {...formfieldProps}
        control={(cprops: DateTimeInputProps) => {
          const { ...restProps } = cprops;
          return (
            <DateTimeInput
              {...restProps}
              animation="fade"
              dateTimeFormat="YYYY-MM-DD HH:mm"
              initialDate={undefined}
              onChange={(e, { value }) => {
                props.setfieldvalue(                  
                  props.name,
                  value && value.length > 1
                    ? new Date(value).toLocaleString(undefined, {
                        hour12: false,
                      })
                    : ""
                );
              }}
            />
          );
        }}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.name === nextProps.name && prevProps.value === nextProps.value
    );
  }
);

export const SimpleFormCheckbox = (props: CheckboxProps) => {
  const { checked, onChange, ...restProps } = props;
  return (
    <Form.Checkbox
      {...restProps}
      checked={!!props.value}
      onChange={(e, { checked }) => {
        props.setfieldvalue(props.name, checked);
      }}
    />
  );
};

export const SimpleFormDatePickerUrlaub = (props: DateInputProps) => {
  const { onChange, label, popupPosition, dateFormat, iconPosition, hideMobileKeyboard, newBis, bisDate, newVon, ...restProps } = props;
  return (
    <FormField
      label={label}
      {...restProps}
      control={(cprops: DateInputProps) => (
        <DateInput
          {...cprops}
          iconPosition="right"
          localization="de"
          popupPosition={popupPosition}
          dateFormat={dateFormat}
          hideMobileKeyboard={hideMobileKeyboard}
          onChange={(e, data) => {
            if ((data.value && data.value.length === 10)) {
              var dateStringParts: number[] = data.value.split('.'); //Date undefined if day on first place
              var newDatum = new Date(dateStringParts[2], (dateStringParts[1] - 1), dateStringParts[0],4); // sind the format gives wrong timeformat we take some hour to avoid wrong dates in the controller
              props.setfieldvalue(`${data.name}`, newDatum.toDateString());
              if (data.name === 'von') {
                props.newVon(newDatum);
              }
              if (data.name === 'bis') {
                props.newBis(newDatum);
              }
              if ((data.name === 'von') && (props.bisDate === undefined)) {
                props.newBis(newDatum);
              }
              else if ((data.name === 'von') && (props.bisDate !== undefined) && newDatum > props.bisDate) {
                props.newBis(newDatum);
              }
            }
          }}
        />
      )}
    />
  );
};

export const SimpleFormDatePicker = (props: DateInputProps) => {
  const { onChange, label, iconPosition, ...restProps } = props;
  return (
    <FormField
      label={label}
      {...restProps}
      control={(cprops: DateInputProps) => (
        <DateInput
          {...cprops}
          iconPosition={iconPosition}
          localization="de"
          onChange={(e, data) => {
            if ((data.value && data.value.length === 10)) {
              props.setfieldvalue(`${data.name}`, new Date(data.value));
            }
          }}
        />
      )}
    />
  );
};

export const SimpleFormTimePicker = (props: DateTimeInputProps) => {
  const { onChange, label, iconPosition, ...restProps } = props;
  return (
    <FormField
      label={label}
      {...restProps}
      control={(cprops: DateTimeInputProps) => (
        <DateTimeInput
          {...cprops}
          iconPosition={iconPosition}
          onChange={(e, data) => {
              props.setfieldvalue(data.name, new Date(data.value));
          }}
        />
      )}
    />
  );
};


export const SimpleFormDateRangePicker = (props: DatesRangeInputProps) => {
  const { onChange, label, iconPosition, ...restProps } = props;
  return (
    <FormField
      label={label}
      {...restProps}
      control={(cprops: DatesRangeInputProps) => (
        <DatesRangeInput
          {...cprops}
          animation={"fade"}
          iconPosition={iconPosition}
          onChange={(e, { name, value }) => {
            props.setfieldvalue(name, value);
          }}
        />
      )}
    />
  );
};

export const SimpleFormCheckboxYN = (props: DropdownProps) => {
  return (
    props.options &&
    props.options.map((option) => {
      const componentNameValue: any = props.value;
      return (
        <Radio
          disabled={props.disabled}
          readOnly={props.readOnly}
          key={props.name + option.key}
          label={option.text}
          onChange={() => {
            props.setfieldvalue(props.name, option.value);
          }}
          checked={componentNameValue === option.value}
        />
      );
    })
  );
};

export const SimpleFormCheckBoxFromOptions = (props: DropdownProps) => {
  const checkedOptions: string[] = props.value as any || [];
  return props.options ? (
    <Row>
      {props.options.map((option) => {
        if (!option.value) {
          return null;
        }
        const isChecked = checkedOptions.includes(option.value.toString());
        return (
          <Col key={option.value + option.key} {...option.colSize}>
            <Checkbox
              disabled={props.disabled}
              readOnly={props.readOnly}
              name={"" + option.value}
              label={option.text}
              defaultChecked={isChecked}
              checked={isChecked}
              className="checkbox-options"
              onChange={(e, { checked }) => {
                if (checked) {
                  option.value && props.setfieldvalue(props.name, [...checkedOptions, option.value.toString()]);
                } else {
                  option.value && props.setfieldvalue(props.name, checkedOptions.filter(op => option.value && op !== option.value.toString()));
                }
                
              }}
            />
          </Col>
        );
      })}
    </Row>
  ) : null;
};

export const formInput = (
  name: string,
  colSizeLg: number,
  colSizeClassName: string,
  placeholder?: string,
  validation?: Yup.Schema<unknown> | Yup.Ref,
  defaultValue?: string
) => ({
  validation: validation,
  component: Form.Input,
  props: {
    name: name,
    defaultValue: defaultValue,
    placeholder: placeholder || "",
  },
  colSize: {
    lg: colSizeLg,
    className: colSizeClassName,
  },
});

export const SimpleFormFieldArray = (props: FormProps) => (
  <Segment>
    <FieldArray
      name={props.name}
      render={(arrayHelper: ArrayHelpers) => (
        <Row>
          <>
            {props.fields
              ? props.fields.map((field: IFormField, index: number) => {
                  return (
                    <Col key={index} {...field.colSize}>
                      {renderFormField(
                        field.component,
                        props.disabled,
                        props.readonly || field.props.readonly,
                        field.props
                      )}
                    </Col>
                  );
                })
              : null}
          </>
          {props.value &&
            props.value.map((rowData: any, rowIndex: number) => {
              return (
                <>
                  {props.fieldArrayFields
                    ? props.fieldArrayFields.map(
                        (field: IFormField, index: number) => {
                          return (
                            <Col key={index} {...field.colSize}>
                              {renderFormField(
                                field.component,
                                props.disabled,
                                props.readonly || field.props.readonly,
                                {
                                  ...field.props,
                                  value: rowData[field.props.name], // field.props || "",
                                  name: `${props.name}.${rowIndex}.${field.props.name}`,
                                },
                                undefined,
                                undefined,
                                rowData[field.props.name]
                              )}
                            </Col>
                          );
                        }
                      )
                    : null}
                </>
              );
            })}

          {!props.disabled && !props.readonly && (
            <Button
              type="button"
              content="add Row"
              onClick={() => {
                arrayHelper.push({});
              }}
            />
          )}
        </Row>
      )}
    />
  </Segment>
);

export const formLabel = (
  labelName: string,
  content: string,
  labelLg?: number,
  labelClassName?: string,
  defaultValue?: string
): IFormField => ({
  component: Label,
  props: {
    name: labelName,
    content: content,
    defaultValue: defaultValue,
  },
  colSize: {
    lg: labelLg,
    className: labelClassName,
  },
});

export const formHeader = (
  name: string,
  content: string,
  size: string,
  className?: string,
  colSizeLG?: number,
  colSizeClassName?: string,
  defaultValue?: string
) => ({
  component: Header,
  props: {
    name: name,
    size: size,
    className: className,
    content: content,
    defaultValue: defaultValue,
  },
  colSize: {
    lg: colSizeLG,
    className: colSizeClassName,
  },
});
