/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EUrlaubsantragStatus {
    None = 'none',
    Offen = 'offen',
    Genehmigt = 'genehmigt',
    Abgewiesen = 'abgewiesen',
    Krank = 'krank',
    Zeitausgleich = 'zeitausgleich',
    Dienstfrei = 'dienstfrei'
}

export function EUrlaubsantragStatusFromJSON(json: any): EUrlaubsantragStatus {
    return EUrlaubsantragStatusFromJSONTyped(json, false);
}

export function EUrlaubsantragStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EUrlaubsantragStatus {
    return json as EUrlaubsantragStatus;
}

export function EUrlaubsantragStatusToJSON(value?: EUrlaubsantragStatus | null): any {
    return value as any;
}

