import { lazy } from "react";
import { WithSuspense } from "../components/SuspenseWrapper";
import { MitarbeiterHauptlinks, VerwaltungHauptlinks } from "../handbuch/util/links";

const HandbuchIndex = lazy(() =>import("../handbuch/pages/index"));

const VerwaltungMitarbeiter = lazy(() =>import("../handbuch/pages/verwaltung/Mitarbeiter"));
const VerwaltungAbwesenheiten = lazy(() =>import("../handbuch/pages/verwaltung/Abwesenheiten"));
const VerwaltungArbeitszeiten = lazy(() =>import("../handbuch/pages/verwaltung/Arbeitszeiten"));
const VerwaltungAuswertungen = lazy(() =>import("../handbuch/pages/verwaltung/Auswertungen"));
const VerwaltungErsteSchritte = lazy(() =>import("../handbuch/pages/verwaltung/ErsteSchritte"));
const VerwaltungIndex = lazy(() =>import("../handbuch/pages/verwaltung/index"));
const VerwaltungLeistungsnachweis = lazy(() =>import("../handbuch/pages/verwaltung/Leistungsnachweis"));
const VerwaltungMontage = lazy(() =>import("../handbuch/pages/verwaltung/Montage"));
const VerwaltungProjekte = lazy(() =>import("../handbuch/pages/verwaltung/Projekte"));
const VerwaltungRessourcen = lazy(() =>import("../handbuch/pages/verwaltung/Ressourcen"));
const VerwaltungStempel = lazy(() =>import("../handbuch/pages/verwaltung/Stempel"));
const VerwaltungUnternehmenseinstellungen = lazy(() =>import("../handbuch/pages/verwaltung/Unternehmenseinstellungen"));

const MitarbeiterAbwesenheiten = lazy(() =>import("../handbuch/pages/mitarbeiter/Abwesenheiten"));
const MitarbeiterAbwesenheitsantrag = lazy(() =>import("../handbuch/pages/mitarbeiter/Abwesenheitsantrag"));
const MitarbeiterArbeitsplan = lazy(() =>import("../handbuch/pages/mitarbeiter/Arbeitsplan"));
const MitarbeiterIndex = lazy(() =>import("../handbuch/pages/mitarbeiter/index"));
const MitarbeiterKontierungen = lazy(() =>import("../handbuch/pages/mitarbeiter/Kontierungen"));
const MitarbeiterLeistungsnachweis = lazy(() =>import("../handbuch/pages/mitarbeiter/Leistungsnachweis"));
const MitarbeiterMonatsansicht = lazy(() =>import("../handbuch/pages/mitarbeiter/Monatsansicht"));
const MitarbeiterSonstiges = lazy(() =>import("../handbuch/pages/mitarbeiter/Sonstiges"));
const MitarbeiterZeiterfassung = lazy(() =>import("../handbuch/pages/mitarbeiter/Zeiterfassung"));

/* 
The order of routes is important here! 
From most specific to least specific 

i.e. 
/handbuch/verwaltung/abwesenheiten/ 
/handbuch/verwaltung/                 => | Otherwise,  
/handbuch/                            => | these won't work 
*/
export const handbuch_routes = [
  {path:MitarbeiterHauptlinks.abwesenheiten.path, component:WithSuspense(MitarbeiterAbwesenheiten)},
  {path:MitarbeiterHauptlinks.abwesenheitsantrag.path, component:WithSuspense(MitarbeiterAbwesenheitsantrag)},
  {path:MitarbeiterHauptlinks.arbeitsplan.path, component:WithSuspense(MitarbeiterArbeitsplan)},
  {path:MitarbeiterHauptlinks.kontierungen.path, component:WithSuspense(MitarbeiterKontierungen)},
  {path:MitarbeiterHauptlinks.leistungsnachweis.path, component:WithSuspense(MitarbeiterLeistungsnachweis)},
  {path:MitarbeiterHauptlinks.monatsansicht.path, component:WithSuspense(MitarbeiterMonatsansicht)},
  {path:MitarbeiterHauptlinks.sonstiges.path, component:WithSuspense(MitarbeiterSonstiges)},
  {path:MitarbeiterHauptlinks.zeiterfassung.path, component:WithSuspense(MitarbeiterZeiterfassung)},
  {path:MitarbeiterHauptlinks.index.path, component:WithSuspense(MitarbeiterIndex)},
  
  {path:VerwaltungHauptlinks.mitarbeiter.path, component:WithSuspense(VerwaltungMitarbeiter)},
  {path:VerwaltungHauptlinks.abwesenheiten.path, component:WithSuspense(VerwaltungAbwesenheiten)},
  {path:VerwaltungHauptlinks.arbeitszeiten.path, component:WithSuspense(VerwaltungArbeitszeiten)},
  {path:VerwaltungHauptlinks.auswertungen.path, component:WithSuspense(VerwaltungAuswertungen)},
  {path:VerwaltungHauptlinks.ersteSchritte.path, component:WithSuspense(VerwaltungErsteSchritte)},
  {path:VerwaltungHauptlinks.leistungsnachweis.path, component:WithSuspense(VerwaltungLeistungsnachweis)},
  {path:VerwaltungHauptlinks.projekte.path, component:WithSuspense(VerwaltungProjekte)},
  {path:VerwaltungHauptlinks.montage.path, component:WithSuspense(VerwaltungMontage)},
  {path:VerwaltungHauptlinks.ressourcen.path, component:WithSuspense(VerwaltungRessourcen)},
  {path:VerwaltungHauptlinks.stempel.path, component:WithSuspense(VerwaltungStempel)},
  {path:VerwaltungHauptlinks.einstellungen.path, component:WithSuspense(VerwaltungUnternehmenseinstellungen)},
  {path:VerwaltungHauptlinks.index.path, component:WithSuspense(VerwaltungIndex)},

  {path:"/handbuch/", component:WithSuspense(HandbuchIndex)},
]