/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KontierungenModel,
    KontierungenModelFromJSON,
    KontierungenModelFromJSONTyped,
    KontierungenModelToJSON,
} from './';

/**
 * Model Kontierungsübersicht
 * @export
 * @interface KontierungsuebersichtModel
 */
export interface KontierungsuebersichtModel {
    /**
     * Summer der Kontierungen
     * @type {number}
     * @memberof KontierungsuebersichtModel
     */
    summe?: number;
    /**
     * Liste der Kontierungen für Detailansicht
     * @type {Array<KontierungenModel>}
     * @memberof KontierungsuebersichtModel
     */
    kontierungen?: Array<KontierungenModel> | null;
    /**
     * Fehler vorhanden
     * @type {string}
     * @memberof KontierungsuebersichtModel
     */
    error?: string | null;
}

export function KontierungsuebersichtModelFromJSON(json: any): KontierungsuebersichtModel {
    return KontierungsuebersichtModelFromJSONTyped(json, false);
}

export function KontierungsuebersichtModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): KontierungsuebersichtModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'summe': !exists(json, 'summe') ? undefined : json['summe'],
        'kontierungen': !exists(json, 'kontierungen') ? undefined : (json['kontierungen'] === null ? null : (json['kontierungen'] as Array<any>).map(KontierungenModelFromJSON)),
        'error': !exists(json, 'error') ? undefined : json['error'],
    };
}

export function KontierungsuebersichtModelToJSON(value?: KontierungsuebersichtModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'summe': value.summe,
        'kontierungen': value.kontierungen === undefined ? undefined : (value.kontierungen === null ? null : (value.kontierungen as Array<any>).map(KontierungenModelToJSON)),
        'error': value.error,
    };
}


