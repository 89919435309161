import * as React from 'react';
import { Container } from 'reactstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Menu, Responsive } from 'semantic-ui-react';
import {ReactComponent as Icon} from "../LogoWebUI.svg"
import './NavMenu.css';
import { useQuery } from 'react-query';
import CommonApi from '../api/CommonApi';
import MitarbeiterApi from "../api/MitarbeiterApi";
import { Dropdown} from 'semantic-ui-react';
import { ResponsiveBreakpoints } from "../config";
import { useAuth } from '../authProvider';
import { EOptions, EUIMode } from '../generated';

interface INavMenuProps { }

export const NavMenu: React.FC<INavMenuProps> = () => {

  let location = useLocation();
  let history = useHistory();
  const auth = useAuth();
  const activeItem = location.pathname?.replace("/", "");
  let desktopVersion = false;
  const commonVersions = useQuery(["getVersionGET"],
    () => CommonApi.getVersionGET({clearCache: false}));
  const isTestEnv = commonVersions.data?.hostEnvironment === "Test";
  const benutzername = useQuery(["getBenutzerVollnameGET"], () =>
    MitarbeiterApi.getBenutzerVollnameGET()
    ,{ enabled: auth.isAuthenticated() });
  const menueOptionsMobile = [
    { key: 3, text: 'Monatsansicht', value: 'monatsuebersicht' },
    { key: 5, text: 'Abwesenheiten', value: 'abwesenheiten' },
    { key: 6, text: 'Abwesenheitsantrag', value: 'abwesenheit' }, 
  ];
  const menueOptionsTablet = [
    { key: 3, text: 'Monatsansicht', value: 'monatsuebersicht' },
    { key: 5, text: 'Abwesenheiten', value: 'abwesenheitsuebersicht' },
    { key: 6, text: 'Abwesenheitsantrag', value: 'abwesenheit' },
  ];
  if (!commonVersions.isLoading && menueOptionsMobile.length === 3 && commonVersions.data) {
    let calcOption = 3;
    if (commonVersions.data.uiMode && commonVersions.data.uiMode.includes(EUIMode.Expert)) {
      menueOptionsMobile.push({ key: 1, text: 'Zeiterfassung', value: 'zeiterfassung' });
      menueOptionsTablet.push({ key: 1, text: 'Zeiterfassung', value: 'zeiterfassung' });
      calcOption += 1;
    }
    if (commonVersions.data.uiMode && commonVersions.data.uiMode.includes(EUIMode.Simple)) {
      menueOptionsMobile.push({ key: 2, text: 'Stempeln', value: 'stempeln' });
      menueOptionsTablet.push({ key: 2, text: 'Stempeln', value: 'stempeln' });
      calcOption += (calcOption === 4 ? 10 : 1);
    }
    if (commonVersions.data.options && (commonVersions.data.options.includes(EOptions.Projekte) || commonVersions.data.options.includes(EOptions.RessourcenPlanung))) {
      if (commonVersions.data.options.includes(EOptions.RessourcenPlanung)) {
        menueOptionsMobile.push({ key: 7, text: "Arbeitsplan", value: 'arbeitsplan' });
        menueOptionsTablet.push({ key: 7, text: "Arbeitsplan", value: 'ressourcenuebersicht' });
        calcOption += 1;
      }
      if (commonVersions.data.options.includes(EOptions.Projekte)) {
        menueOptionsMobile.push({ key: 4, text: 'Kontierungen', value: 'kontierungsuebersicht' });
        menueOptionsTablet.push({ key: 4, text: 'Kontierungen', value: 'kontierungsuebersicht' });
        calcOption += 1;
      }
      if (commonVersions.data.options.includes(EOptions.Leistungsnachweise)) {
        menueOptionsMobile.push({ key: 8, text: 'Leistungsnachweis', value: 'leistungsnachweis' });
        menueOptionsTablet.push({ key: 8, text: 'Leistungsnachweis', value: 'leistungsnachweis' });
        calcOption += 1;
      }
    }
    menueOptionsMobile.sort((a, b) => a.key > b.key ? 1 : -1);
    menueOptionsTablet.sort((a, b) => a.key > b.key ? 1 : -1);
    desktopVersion = (calcOption <= 6);
  }
  if (commonVersions.data && (commonVersions.data.uiMode === EUIMode.Simple) && activeItem === '') {
    history.push({
      pathname: `/stempeln`
    });
  }
  const [myStyle, setMyStyle] = React.useState("displayBlockNone");
  let noStyle = (myStyle === "displayBlockNone");

  return (
    <Menu className={"hide-print top-nav" + (isTestEnv ? "" : " menu-background")} inverted={true/*commonVersions.data?.hostEnvironment === "Test"*/}>
        <Container>
          <Menu.Item>
            <Icon width={"40px"} height={"40px"} />
          </Menu.Item>

          {/* MOBILE */}
          <Responsive
          as={React.Fragment}
          minWidth={ResponsiveBreakpoints.MinMobile}
          maxWidth={ResponsiveBreakpoints.MaxMobile}
          >
          <Menu.Menu position='right'>
            { benutzername && benutzername.data ? <>
            <Menu.Item style={{padding: '0'}} className='menu-dropdown'>
                <Dropdown
                  onChange={(e, { value, text }) => {
                    history.push({
                      pathname: `/${value}`
                    });
                  }}
                  value={activeItem === '' ? 'zeiterfassung' : (activeItem.includes('zeiterfassung')) ? 'zeiterfassung' : activeItem}                  
                  fluid                  
                  selection
                  options={menueOptionsMobile}
                />
              </Menu.Item>
              <Menu.Item>
                { commonVersions.data && commonVersions.data.loginUsed ?
                <Menu.Menu>
                  <div className="ui dropdown icon item">
                    <i
                      onClick={() => noStyle ? setMyStyle("displayBlock") : setMyStyle("displayBlockNone")}
                      className="user icon">
                    </i>
                    <Menu.Menu position='right' className={myStyle}>
                      <Menu.Item
                        content={'Neues Passwort'}
                        icon='lock'
                        as={Link}
                        to='/NeuesPasswort'
                      />
                      <Menu.Item
                        name='logout'
                        icon='power off'
                        as={Link}
                        to='/logout'
                      >
                      </Menu.Item>
                    </Menu.Menu>
                  </div>
                </Menu.Menu>
                  :
                  <Menu.Menu>
                    <Menu.Item
                      name='logout'
                      icon='power off'
                      as={Link}
                      to='/logout'
                    >
                    </Menu.Item>
                  </Menu.Menu>
                  }
            </Menu.Item>
            </>
              :
              <Menu.Menu position='right'>
              <Menu.Item
              name='login'
              icon='power'
              as={Link}
              to='/login'
            >
                </Menu.Item>
              </Menu.Menu>
            }
            </Menu.Menu >
          </Responsive>

          {/* TABLET */}
          <Responsive
            as={React.Fragment}
            minWidth={ResponsiveBreakpoints.MinTablet}
            maxWidth={ResponsiveBreakpoints.MaxTablet}
          >
            <Menu.Menu position='right'>
              {benutzername && benutzername.data ? <>
                <Menu.Item style={{ padding: '0' }} className='menu-dropdown'>
                  <Dropdown
                    onChange={(e, { value, text }) => {
                      history.push({
                        pathname: `/${value}`
                      });
                    }}
                    value={activeItem === '' ? 'zeiterfassung' : (activeItem.includes('zeiterfassung')) ? 'zeiterfassung' : activeItem}
                    fluid
                    selection
                    options={menueOptionsMobile}
                  />
                </Menu.Item>
                <Menu.Item>
                  {commonVersions.data && commonVersions.data.loginUsed ?
                    <Menu.Menu>
                      <div className="ui dropdown icon item">
                        <i
                          onClick={() => noStyle ? setMyStyle("displayBlock") : setMyStyle("displayBlockNone")}
                          className="user icon">
                        </i>
                        <Menu.Menu position='right' className={myStyle}>
                          <Menu.Item
                            content={'Neues Passwort'}
                            icon='lock'
                            as={Link}
                            to='/NeuesPasswort'
                          />
                          <Menu.Item
                            name='logout'
                            icon='power off'
                            as={Link}
                            to='/logout'
                          >
                          </Menu.Item>
                        </Menu.Menu>
                      </div>
                    </Menu.Menu>
                    :
                    <Menu.Menu>
                      <Menu.Item
                        name='logout'
                        icon='power off'
                        as={Link}
                        to='/logout'
                      >
                      </Menu.Item>
                    </Menu.Menu>
                  }
                </Menu.Item>
              </>
                :
                <Menu.Menu position='right'>
                  <Menu.Item
                    name='login'
                    icon='power'
                    as={Link}
                    to='/login'
                  >
                  </Menu.Item>
                </Menu.Menu>
              }
            </Menu.Menu >
          </Responsive>


          {desktopVersion ?
            <Responsive
              as={React.Fragment}
              minWidth={ResponsiveBreakpoints.MinTabletDesktop}
              maxWidth={ResponsiveBreakpoints.MaxTabletDesktop}
            >
              {benutzername && benutzername.data ? <>
                <Menu.Item
                  name='zeiterfassung'
                  as={Link}
                  to='/'
                  active={activeItem === 'zeiterfassung' || activeItem === ''}
                >
                  Zeiterfassung
                </Menu.Item>

                <Menu.Item
                  name='monatsuebersicht'
                  as={Link}
                  to='/monatsuebersicht'
                  active={activeItem === 'monatsuebersicht'}
                >
                  <> Monatsansicht </>
                </Menu.Item>
                {commonVersions.data && commonVersions.data.options && commonVersions.data.options.includes(EOptions.Projekte) &&
                  <Menu.Item
                    name='kontierungsuebersicht'
                    as={Link}
                    to='/kontierungsuebersicht'
                    active={activeItem === 'kontierungsuebersicht'}
                  >
                    <> Kontierungen </>
                  </Menu.Item>
                }

                <Menu.Item
                  name='abwesenheitsuebersicht'
                  as={Link}
                  to='/abwesenheitsuebersicht'
                  active={activeItem === 'abwesenheitsuebersicht'}
                >
                  <>Abwesenheiten</>
                </Menu.Item>
                <Menu.Item
                  name='abwesenheit'
                  as={Link}
                  to='/abwesenheit'
                  active={activeItem === 'abwesenheit'}
                >
                  Abwesenheitsantrag
                </Menu.Item>
                {commonVersions.data && commonVersions.data.options && commonVersions.data.options.includes(EOptions.RessourcenPlanung) &&
                  <Menu.Item
                    name='ressourcenuebersicht'
                    as={Link}
                    to='/ressourcenuebersicht'
                    active={activeItem === 'ressourcenuebersicht'}
                  >
                    <> Arbeitsplan </>
                  </Menu.Item>
                }
                {commonVersions.data && commonVersions.data.options && commonVersions.data.options.includes(EOptions.Leistungsnachweise) &&
                  <Menu.Item
                    name='leistungsnachweis'
                    as={Link}
                    to='/leistungsnachweis'
                    active={activeItem === 'leistungsnachweis'}
                  >
                    <> Leistungsnachweis </>
                  </Menu.Item>
                }
                {commonVersions.data && commonVersions.data.loginUsed ?
                  <Menu.Menu position='right'>
                    <div className="ui dropdown icon item">
                      <i
                        onClick={() => noStyle ? setMyStyle("displayBlock") : setMyStyle("displayBlockNone")}
                        className="user icon">
                      </i>
                      <Menu.Menu position='right' className={myStyle}>
                        <Menu.Item
                          content={'Neues Passwort'}
                          icon='lock'
                          as={Link}
                          to='/NeuesPasswort'
                        />
                        <Menu.Item
                          name='logout'
                          icon='power off'
                          as={Link}
                          to='/logout'
                        >
                        </Menu.Item>
                      </Menu.Menu>
                    </div>
                    <Menu.Menu position='left'>
                      <Menu.Item
                        content={benutzername.data && benutzername.data.includes("DOCTYPE") ? "" : benutzername.data}
                      />
                    </Menu.Menu>

                  </Menu.Menu>
                  :
                  <Menu.Menu position='right'>
                    <Menu.Item
                      content={benutzername.data && benutzername.data.includes("DOCTYPE") ? "" : benutzername.data}
                      icon='user'
                    />
                    <Menu.Item
                      name='logout'
                      icon='power off'
                      as={Link}
                      to='/logout'
                    >
                    </Menu.Item>
                  </Menu.Menu>
                }
                :

              </> :
                <Menu.Menu position='right'>

                  <Menu.Item
                    name='login'
                    icon='power'
                    as={Link}
                    to='/login'
                  >
                  </Menu.Item>
                </Menu.Menu>
              }
            </Responsive>
            :
            <Responsive
              as={React.Fragment}
              minWidth={ResponsiveBreakpoints.MinTabletDesktop}
              maxWidth={ResponsiveBreakpoints.MaxTabletDesktop}
            >
              <Menu.Menu position='right'>
                {benutzername && benutzername.data ? <>
                  <Menu.Item style={{ padding: '0' }} className='menu-dropdown'>
                    <Dropdown
                      onChange={(e, { value, text }) => {
                        history.push({
                          pathname: `/${value}`
                        });
                      }}
                      value={activeItem === '' ? 'zeiterfassung' : (activeItem.includes('zeiterfassung')) ? 'zeiterfassung' : activeItem}
                      fluid
                      selection
                      options={menueOptionsTablet}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    {commonVersions.data && commonVersions.data.loginUsed ?
                      <Menu.Menu>
                        <div className="ui dropdown icon item">
                          <i
                            onClick={() => noStyle ? setMyStyle("displayBlock") : setMyStyle("displayBlockNone")}
                            className="user icon">
                          </i>
                          <Menu.Menu position='right' className={myStyle}>
                            <Menu.Item
                              content={'Neues Passwort'}
                              icon='lock'
                              as={Link}
                              to='/NeuesPasswort'
                            />
                            <Menu.Item
                              name='logout'
                              icon='power off'
                              as={Link}
                              to='/logout'
                            >
                            </Menu.Item>
                          </Menu.Menu>
                        </div>
                      </Menu.Menu>
                      :
                      <Menu.Menu>
                        <Menu.Item
                          name='logout'
                          icon='power off'
                          as={Link}
                          to='/logout'
                        >
                        </Menu.Item>
                      </Menu.Menu>
                    }
                  </Menu.Item>
                </>
                  :
                  <Menu.Menu position='right'>
                    <Menu.Item
                      name='login'
                      icon='power'
                      as={Link}
                      to='/login'
                    >
                    </Menu.Item>
                  </Menu.Menu>
                }
              </Menu.Menu >
            </Responsive>
          }

          {/* DESKTOP */}
          <Responsive
            as={React.Fragment}
            minWidth={ResponsiveBreakpoints.MinDesktop}
          >
          {benutzername && benutzername.data ? <>
            <Menu.Item
              name='zeiterfassung'
              as={Link}
              to='/'
              active={activeItem === 'zeiterfassung' || activeItem === ''}
            >Zeiterfassung</Menu.Item>

            <Menu.Item
              name='monatsuebersicht'
              as={Link}
              to='/monatsuebersicht'
              active={activeItem === 'monatsuebersicht'}
            >Monatsansicht</Menu.Item>
            {commonVersions.data && commonVersions.data.options && commonVersions.data.options.includes(EOptions.Projekte) &&
              <Menu.Item
                name='kontierungsuebersicht'
                as={Link}
                to='/kontierungsuebersicht'
                active={activeItem === 'kontierungsuebersicht'}
              >Kontierungen</Menu.Item>
            }
            <Menu.Item
              name='abwesenheitsuebersicht'
              as={Link}
              to='/abwesenheitsuebersicht'
              active={activeItem === 'abwesenheitsuebersicht'}
            >Abwesenheiten</Menu.Item>
            <Menu.Item
              name='abwesenheit'
              as={Link}
              to='/abwesenheit'
              active={activeItem === 'abwesenheit'}
            >Abwesenheitsantrag</Menu.Item>
            {commonVersions.data && commonVersions.data.options && commonVersions.data.options.includes(EOptions.RessourcenPlanung) &&
              <Menu.Item
                name='ressourcenuebersicht'
                as={Link}
                to='/ressourcenuebersicht'
                active={activeItem === 'ressourcenuebersicht'}
              >Arbeitsplan</Menu.Item>
            }
            {commonVersions.data && commonVersions.data.options && commonVersions.data.options.includes(EOptions.Leistungsnachweise) &&
            <Menu.Item
              name='leistungsnachweis'
              as={Link}
              to='/leistungsnachweis'
              active={activeItem === 'leistungsnachweis'}
            >Leistungsnachweis</Menu.Item>
            }
            {commonVersions.data && commonVersions.data.loginUsed ?
              <Menu.Menu position='right'>
                <div className="ui dropdown icon item">
                  <i
                    onClick={() =>  noStyle ? setMyStyle("displayBlock") : setMyStyle("displayBlockNone") }
                    className="user icon">
                  </i>
                  <Menu.Menu position='right' className={myStyle}>
                    <Menu.Item
                      content={'Neues Passwort'}
                      icon='lock'
                      as={Link}
                      to='/NeuesPasswort'
                    />
                    <Menu.Item
                      name='logout'
                      icon='power off'
                      as={Link}
                      to='/logout'
                    >
                    </Menu.Item>
                  </Menu.Menu>
                </div>
                <Menu.Menu position='left'>
                  <Menu.Item
                    content={benutzername.data && benutzername.data.includes("DOCTYPE") ? "" : benutzername.data}
                  />
                </Menu.Menu>
              </Menu.Menu>
              :
              <Menu.Menu position='right'>
                <Menu.Item
                  content={benutzername.data && benutzername.data.includes("DOCTYPE") ? "" : benutzername.data}
                  icon='user'
                />
                <Menu.Item
                  name='logout'
                  icon='power off'
                  as={Link}
                  to='/logout'
                >
                </Menu.Item>
              </Menu.Menu>
            }
          </> :
            <Menu.Menu position='right'>
              <Menu.Item
                name='login'
                icon='power'
                as={Link}
                to='/login'
              >
              </Menu.Item>
            </Menu.Menu>
          }
          </Responsive>
        </Container>
    </Menu>
  )
}
