/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    VersionViewModel,
    VersionViewModelFromJSON,
    VersionViewModelToJSON,
} from '../models';

export interface GetVersionGETRequest {
    clearCache?: boolean;
}

/**
 * 
 */
export class CommonApi extends runtime.BaseAPI {

    /**
     * Resettet den Cache
     */
    async clearCacheGETRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Common/ClearCache`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resettet den Cache
     */
    async clearCacheGET(): Promise<void> {
        await this.clearCacheGETRaw();
    }

    /**
     * Liefert Versions-Informationen
     */
    async getVersionGETRaw(requestParameters: GetVersionGETRequest): Promise<runtime.ApiResponse<VersionViewModel>> {
        const queryParameters: any = {};

        if (requestParameters.clearCache !== undefined) {
            queryParameters['clearCache'] = requestParameters.clearCache;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Common/GetVersion`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VersionViewModelFromJSON(jsonValue));
    }

    /**
     * Liefert Versions-Informationen
     */
    async getVersionGET(requestParameters: GetVersionGETRequest): Promise<VersionViewModel> {
        const response = await this.getVersionGETRaw(requestParameters);
        return await response.value();
    }

}
