/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Einteilung für einen Arbeitstag (SimpleView)
 * @export
 * @interface Einteilung
 */
export interface Einteilung {
    /**
     * Name des Projektes
     * @type {string}
     * @memberof Einteilung
     */
    projektName?: string | null;
    /**
     * Adresse des Projektes
     * @type {string}
     * @memberof Einteilung
     */
    projektAdresse?: string | null;
    /**
     * Telefonnummer des Projektekontaktes
     * @type {string}
     * @memberof Einteilung
     */
    projektTelefon?: string | null;
    /**
     * Ansprechsperson des Projektes
     * @type {string}
     * @memberof Einteilung
     */
    projektKontakt?: string | null;
    /**
     * Von- und Biszeit
     * @type {string}
     * @memberof Einteilung
     */
    arbeitszeit?: string | null;
}

export function EinteilungFromJSON(json: any): Einteilung {
    return EinteilungFromJSONTyped(json, false);
}

export function EinteilungFromJSONTyped(json: any, ignoreDiscriminator: boolean): Einteilung {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projektName': !exists(json, 'projektName') ? undefined : json['projektName'],
        'projektAdresse': !exists(json, 'projektAdresse') ? undefined : json['projektAdresse'],
        'projektTelefon': !exists(json, 'projektTelefon') ? undefined : json['projektTelefon'],
        'projektKontakt': !exists(json, 'projektKontakt') ? undefined : json['projektKontakt'],
        'arbeitszeit': !exists(json, 'arbeitszeit') ? undefined : json['arbeitszeit'],
    };
}

export function EinteilungToJSON(value?: Einteilung | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projektName': value.projektName,
        'projektAdresse': value.projektAdresse,
        'projektTelefon': value.projektTelefon,
        'projektKontakt': value.projektKontakt,
        'arbeitszeit': value.arbeitszeit,
    };
}


