import * as React from 'react';
import { Button, Header, Input, Segment, Table, Grid, Dropdown, Popup, Icon } from 'semantic-ui-react';
import { ProjektKontierungModified, ProjekteModel, TagStempel } from '../generated';
import ProjektKontierungApi from '../api/ProjektKontierungApi';
import { ProjektRow } from "./ProjektRow";
import { numberToStringTime, stringTimeToNumber } from './Helper'
import { Field, FieldProps } from 'formik';
import { useQuery } from 'react-query';
import { TaetigkeitenModel } from '../generated/models/TaetigkeitenModel';

interface KontierungProps {
  isReadOnly: boolean;
  offeneMinuten: Date;
  sonstigeMinuten: Date;
  datum: Date;
  vonDatum?: Date | null | undefined;
  bisDatum?: Date | null | undefined;
  model: TagStempel;
  notToday: boolean;
  dirty: boolean;
  rows: number;
  rowsUsed: number;
  setValues: (values: React.SetStateAction<TagStempel>, shouldValidate?: boolean) => void;
  setfieldvalue: (field: string, value: any, shouldValidate?: boolean) => void;
  onEntfernen: () => void;
}

export const Kontierung: React.FC<KontierungProps> = (props) => {
  const [projektModel, setProjektModel] = React.useState<ProjekteModel>({});
  const [projekt, setProjekt] = React.useState("0");
  const [taetigkeiten, setTaetigkeiten] = React.useState<TaetigkeitenModel[]>([]);
  const [taetigkeit, setTaetigkeit] = React.useState<TaetigkeitenModel>({});
  const [taetigkeitErforderlich, setTaetigkeitErforderlich] = React.useState<boolean>(false);
  let initModel: ProjektKontierungModified = { projektID: "0", taetigkeitAnmerkungErforderlich: undefined };
  const projekteAuswahlModel: {
    key: string;
    text: string;
    value: string;
  }[] = [{ key: '0', text: 'Bitte auswählen', value: '0' }];
  const projekteDropDown = useQuery(["getProjekteGET", props.datum], () =>
    ProjektKontierungApi.getProjekteGET({ date: props.datum })
  );
  if (projekteDropDown.data) {
    projekteDropDown.data.forEach((projekt) => {
      projekteAuswahlModel.push({
        key: projekt.id!,
        text: projekt.bezeichnung!,
        value: projekt.id!
      });
    });
  }
  const projekteAuswahl = (values: any) => {
    return (
      <>
        <Header as="h5">Projekt</Header>
        <Dropdown
          search
          onChange={(e, { value }) => {
            if (value) {
              setProjekt(value.toString());
              initModel.projektID = value.toString();
              setTaetigkeit({});
              let projektTaetigkeiten: TaetigkeitenModel[] = [];
              projekteDropDown.data && projekteDropDown.data.forEach((projekt) => {
                if (projekt.id === value.toString()) {
                  setProjektModel({
                    beschreibung: projekt.beschreibung, anmerkungErforderlich: projekt.anmerkungErforderlich,
                    bezeichnung: projekt.bezeichnung, id: value.toString()
                  })
                  initModel.projektname = projekt.bezeichnung;
                  initModel.anmerkungErforderlich = projekt.anmerkungErforderlich;
                  projekt.taetigkeiten && projekt.taetigkeiten.forEach((taetigkeit) => {
                    projektTaetigkeiten.push
                      (
                        {
                          anmerkungErforderlich: taetigkeit.anmerkungErforderlich,
                          favorit: taetigkeit.favorit,
                          beschreibung: taetigkeit.beschreibung,
                          bezeichnung: taetigkeit.bezeichnung,
                          id: taetigkeit.id,
                          file: taetigkeit.file,
                          contentType: taetigkeit.contentType
                        }
                      );
                  });
                  if (projekt.taetigkeiten && projekt.taetigkeiten.length > 1) {
                    setTaetigkeitErforderlich(true);
                  }
                  else {
                    let project = projekt.taetigkeiten && projekt.taetigkeiten.find && projekt.taetigkeiten.find(f => (f.id !== undefined || f.id === null || f.id === undefined));
                    if (project) {
                      setTaetigkeit(
                        {
                          anmerkungErforderlich: project.anmerkungErforderlich,
                          beschreibung: project.beschreibung,
                          bezeichnung: project.bezeichnung,
                          favorit: project.favorit,
                          id: project.id,
                          contentType: project.contentType,
                          file: project.file
                        });
                    }
                    setTaetigkeitErforderlich(false);
                  }
                }
              });
              setTaetigkeiten(projektTaetigkeiten);
            }
          }}
          value={projekt}
          fluid
          selection
          options={projekteAuswahlModel}
        />
      </>
    );
  };
  const taetigkeitenAuswahl = (values: any) => {
    return (
      <div> <Header as="h5">Tätigkeit
      </Header>
        <div className={taetigkeitErforderlich ? 'errorBorder' : ''} style={{ border: 'solid lightgrey 1px' }}>
          {taetigkeiten.map(pt => {
            return (<React.Fragment key={pt.id}>
              <Popup
                content={() => {
                  return (<div>
                    {pt.beschreibung && pt.bezeichnung ?
                      <div> {pt.bezeichnung} <br /> {pt.beschreibung}</div> :
                      <div>{pt.bezeichnung ? pt.bezeichnung : 'Allgemein'}</div>}
                  </div>)
                }}
                trigger={
                  pt.file && pt.file.length > 0 ?
                    <Button
                      content={(<img
                        alt={pt.bezeichnung ? pt.bezeichnung : "Tätigkeit"}
                        className={'taetigkeiticon'}
                        src={`data:${pt.contentType};base64,${pt.file}`}
                      />
                      )}
                      className={(pt.id === taetigkeit.id) && (taetigkeitErforderlich === false) ? 'taetigkeiticonbutton taetigkeitchosenrow' : 'taetigkeiticonbutton'}
                      size={'mini'}
                      type={'button'}
                      secondary
                      onClick={() => {
                        setTaetigkeit({
                          anmerkungErforderlich: pt.anmerkungErforderlich, beschreibung: pt.beschreibung, bezeichnung: pt.bezeichnung,
                          id: pt.id, file: pt.file, contentType: pt.contentType, favorit: pt.favorit
                        });
                        initModel.anmerkung = values.anmerkung;

                        setTaetigkeitErforderlich(false);
                      }}
                    />
                    :
                    pt.bezeichnung
                      ?
                      <Button
                        content={pt.bezeichnung.slice(0, 3)}
                        className={(pt.id === taetigkeit.id) && (taetigkeitErforderlich === false) ? 'taetigkeiticonbutton taetigkeitchosenrow' : 'taetigkeiticonbutton'}
                        size={'mini'}
                        type={'button'}
                        secondary
                        onClick={() => {
                          setTaetigkeit({
                            anmerkungErforderlich: pt.anmerkungErforderlich, beschreibung: pt.beschreibung, bezeichnung: pt.bezeichnung,
                            id: pt.id, file: pt.file, contentType: pt.contentType, favorit: pt.favorit
                          });
                          initModel.anmerkung = values.anmerkung;

                          setTaetigkeitErforderlich(false);
                        }}
                      />
                      :
                      <Button
                        content={(<Icon
                          className={'taetigkeiticon no-file-icon'}
                          name="world"
                        />)}
                        className={(pt.id === taetigkeit.id) && (taetigkeitErforderlich === false) ? 'taetigkeiticonbutton taetigkeitchosenrow' : 'taetigkeiticonbutton'}
                        size={'mini'}
                        type={'button'}
                        secondary
                        onClick={() => {
                          setTaetigkeit({
                            anmerkungErforderlich: pt.anmerkungErforderlich, beschreibung: pt.beschreibung, bezeichnung: pt.bezeichnung,
                            id: pt.id, file: pt.file, contentType: pt.contentType, favorit: pt.favorit
                          });
                          initModel.anmerkung = values.anmerkung;

                          setTaetigkeitErforderlich(false);
                        }}
                      />
                } />
            </React.Fragment>)
          }
          )}

        </div>
      </div>
    );
  };
  const Hinzufuegen = ({ setValues }: { setValues: (values: React.SetStateAction<TagStempel>, shouldValidate?: boolean) => void; }) => {
    return (
      <div>
        <h3>&nbsp;</h3>
        <Button
          secondary
          className={'row-button mini'}
          data-tooltip={"Hinzufügen"}
          floated="right"
          icon="add"
          type="button"
          disabled={((projekt === "0") || taetigkeitErforderlich)}
          onClick={() => {
            let newTaetigkeiten: TaetigkeitenModel[] = []
            if (taetigkeiten) {
              taetigkeiten.forEach((pt: TaetigkeitenModel) => {
                if (((pt.id !== taetigkeit.id) || ((pt.id !== taetigkeit.id) && (taetigkeit.id !== undefined)))) {
                  let newTaetigkeit: TaetigkeitenModel =
                  {
                    id: pt.id,
                    bezeichnung: pt.bezeichnung,
                    beschreibung: pt.beschreibung,
                    anmerkungErforderlich: pt.anmerkungErforderlich,
                    contentType: pt.contentType,
                    favorit: pt.favorit,
                    file: pt.file,
                    taetigkeitAendern: pt.taetigkeitAendern
                  };
                  newTaetigkeiten.push(newTaetigkeit);
                }
              });
            }
            if (newTaetigkeiten) {
              newTaetigkeiten.sort((a, b) => a!.bezeichnung!.localeCompare(b!.bezeichnung!));
            }
            initModel.taetigkeitID = taetigkeit.id;
            initModel.taetigkeitName = taetigkeit.bezeichnung;
            initModel.taetigkeitBeschreibung = taetigkeit.beschreibung;
            initModel.anzahlMinuten = 0;
            initModel.anmerkung = "";
            initModel.favorit = taetigkeit.favorit;
            initModel.projektID = projektModel.id;
            initModel.projektBeschreibung = projektModel.beschreibung;
            initModel.projektname = projektModel.bezeichnung;
            initModel.contentType = taetigkeit.contentType;
            initModel.file = taetigkeit.file;
            initModel.anmerkungErforderlich = ((taetigkeit.anmerkungErforderlich !== null) && (taetigkeit.anmerkungErforderlich !== undefined))
              ? taetigkeit.anmerkungErforderlich : projektModel.anmerkungErforderlich;
            initModel.datum = props.datum;
            initModel.projektTaetigkeiten = newTaetigkeiten;
            setValues((prev) => {
              prev.zeitstempel && prev.zeitstempel.projektKontierungen && prev.zeitstempel.projektKontierungen.push(initModel);
              return prev;
            });
            initModel = {};
            setProjekt("0");
            setTaetigkeiten([]);
            setProjektModel({ id: "0" });
            setTaetigkeit({});
          }}
        />
      </div>
    );
  };

  let restZeit = "";
  let tagesSaldo = 0;
  const validateBilanz = (value: string) => {
    var numberValue = stringTimeToNumber(value);
    if (numberValue !== 0) {
      return true
    }
    return false;
  }
  const tagSaldoBerechnen = (values: ProjektKontierungModified[]) => {
    let summeKontiert = 0;
    values.map(v => {
      if (typeof v.anzahlMinuten === 'string') {
        summeKontiert += stringTimeToNumber(v.anzahlMinuten)
      } else {
        summeKontiert += (v.anzahlMinuten ?? 0)
      }
      return summeKontiert;
    })
    let sonstige = (props.sonstigeMinuten.getHours() * 60) + props.sonstigeMinuten.getMinutes();
    let tagsaldo = (((props.offeneMinuten.getHours() * 60) + props.offeneMinuten.getMinutes()) + sonstige);
    let bilanz = summeKontiert - tagsaldo
    tagesSaldo = tagsaldo;
    restZeit = numberToStringTime(bilanz);
    return restZeit;
  }

  return (
    <div>
      <br />
      {((props.model && props.model.zeitstempel && props.model.zeitstempel.projektKontierungen
        && props.model.zeitstempel.projektKontierungen.length === 0) && (projekteDropDown.data && projekteDropDown.data.length === 0)) ? <> </> :
        props.model.zeitstempel?.projektKontierungen !== undefined && props.model.zeitstempel?.projektKontierungen !== null &&
        <div>
          <Segment style={{ paddingLeft: "0", paddingRight: "0" }}>
             <div>
              <Grid rows="2" style={{ paddingLeft: "1em" }}>
                <Grid.Row style={{ paddingBottom: "0" }}>
                  <Grid.Column>
                    <div>
                      <h3 style={{ textAlign: "center" }}>Kontierung</h3>
                    </div>
                  </Grid.Column>
                  </Grid.Row>
                  {!props.isReadOnly ?
                    <Grid.Row style={{ paddingTop: "0" }}>
                      <Grid.Column mobile='16' tablet='6' computer='6'>
                        {React.createElement(projekteAuswahl, props.model.zeitstempel.projektKontierungen)}
                      </Grid.Column>
                      <Grid.Column mobile='16' tablet='9' computer='9'>
                        {taetigkeiten.length > 0 ? React.createElement(taetigkeitenAuswahl, props.model.zeitstempel.projektKontierungen) : <> </>}
                      </Grid.Column>
                      <Grid.Column mobile='16' tablet='1' computer='1'>
                        {<div><Hinzufuegen setValues={props.setValues} /></div>}
                      </Grid.Column>
                    </Grid.Row>
                    : <Grid.Row style={{ paddingTop: "1em" }}> </Grid.Row>

                  }
              </Grid>

              <Table striped celled attached='top'>
                <Table.Header>
                  <Table.Row textAlign="left">
                    <Table.HeaderCell collapsing>Projekt</Table.HeaderCell>
                    <Table.HeaderCell collapsing>Tätigkeiten</Table.HeaderCell>
                    <Table.HeaderCell collapsing>Anmerkung</Table.HeaderCell>
                    <Table.HeaderCell collapsing
                      className={props.notToday && validateBilanz(tagSaldoBerechnen(props.model.zeitstempel.projektKontierungen)) ? "errorBorder"
                        : !props.notToday && validateBilanz(tagSaldoBerechnen(props.model.zeitstempel.projektKontierungen)) && !tagSaldoBerechnen(props.model.zeitstempel.projektKontierungen).includes('-') ? "errorBorder"
                          : ""}>
                      Tagesbilanz {tagSaldoBerechnen(props.model.zeitstempel.projektKontierungen
                        ? props.model.zeitstempel.projektKontierungen : [])}
                    </Table.HeaderCell>
                    <Table.HeaderCell collapsing> </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {
                    props.model && props.model.zeitstempel && props.model.zeitstempel.projektKontierungen
                    && props.model.zeitstempel.projektKontierungen.map((p, index: number) => (
                      <ProjektRow
                        key={index}
                        datum={props.datum}
                        isReadOnly={(props.isReadOnly === true ? true : (p.readOnly !== undefined) && p.readOnly === true ? true : false)}
                        projekt={p}
                        projekte={props.model && props.model.zeitstempel && props.model.zeitstempel.projektKontierungen
                          ? props.model.zeitstempel.projektKontierungen : []}
                        index={index}
                        restZeit={restZeit}
                        tagesSaldo={tagesSaldo}
                        vonDatum={props.vonDatum}
                        bisDatum={props.bisDatum}
                        rows={props.rows}
                        rowsUsed={props.rowsUsed}
                        setValues={props.setValues}
                        setfieldvalue={props.setfieldvalue}
                      />
                    ))
                  }
                  <Table.Row className="border-top">
                    <Table.Cell colSpan="3" textAlign="left" >
                      <Header size="tiny" content="Tagesbilanz" />
                    </Table.Cell>
                    <Table.Cell>
                      <Field name="Tagesbilanz">
                        {(field: FieldProps) => {
                          return <div className={props.notToday && validateBilanz(tagSaldoBerechnen(props.model.zeitstempel!.projektKontierungen!)) ? "errorBorder"
                            : !props.notToday && validateBilanz(tagSaldoBerechnen(props.model.zeitstempel!.projektKontierungen!))
                              && !tagSaldoBerechnen(props.model.zeitstempel!.projektKontierungen!).includes('-') ? "errorBorder"
                              : ""}>
                            <Input
                              name="TagesBilanzStunden"
                              fluid
                              type="text"
                              value={tagSaldoBerechnen(props.model.zeitstempel!.projektKontierungen!)}
                              transparent
                            />
                          </div>
                        }}
                      </Field>
                    </Table.Cell>
                    <Table.Cell> </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Grid>
                <Grid.Row> </Grid.Row>
                <Grid.Row columns="2" style={{ paddingTop: "0" }}>
                  <Grid.Column style={{ paddingLeft: "2.6em" }}>
                    {props.isReadOnly ? <> </> :
                      <Button
                        color={"red"}
                        content={'Entfernen'}
                        className={'icon-button'}
                        data-tooltip={"Alle Kontierungen löschen"}
                        icon="trash"
                        type="button"
                        disabled={false}
                        onClick={props.onEntfernen}
                      />
                    }
                  </Grid.Column>
                  <Grid.Column>
                    {props.isReadOnly ? <> </> :
                      <Button
                        primary
                        content={'Kontieren'}
                        className={'icon-button'}
                        data-tooltip={"Kontieren"}
                        floated="right"
                        icon="save"
                        type="submit"
                        disabled={props.dirty}
                      />
                    }
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
            
          </Segment>
        </div>
      }
    </div>
  )
};

