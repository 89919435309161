/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AbwesenheitsUebersichtModel,
    AbwesenheitsUebersichtModelFromJSON,
    AbwesenheitsUebersichtModelToJSON,
    KontierungsuebersichtModel,
    KontierungsuebersichtModelFromJSON,
    KontierungsuebersichtModelToJSON,
    MitarbeiterModel,
    MitarbeiterModelFromJSON,
    MitarbeiterModelToJSON,
    MonatsUebersichtModel,
    MonatsUebersichtModelFromJSON,
    MonatsUebersichtModelToJSON,
    NeuesPasswortModel,
    NeuesPasswortModelFromJSON,
    NeuesPasswortModelToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    RessourcenModel,
    RessourcenModelFromJSON,
    RessourcenModelToJSON,
} from '../models';

export interface GetAbwesenheitsuebersichtGETRequest {
    jahr?: number;
    monat?: number;
    showAll?: boolean;
}

export interface GetKontierungsuebersichtGETRequest {
    jahr?: number;
    monat?: number;
}

export interface GetMobileAbwesenheitsuebersichtGETRequest {
    jahr?: number;
    monat?: number;
    showAll?: boolean;
}

export interface GetMobilePlanungsuebersichtGETRequest {
    woche?: Date;
    naechsteWoche?: boolean;
    vorWoche?: boolean;
}

export interface GetMonatsuebersichtGETRequest {
    jahr?: number;
    monat?: number;
}

export interface GetPlanungsuebersichtGETRequest {
    woche?: Date;
    naechsteWoche?: boolean;
    vorWoche?: boolean;
}

export interface PasswortAendernPOSTRequest {
    neuesPasswortModel?: NeuesPasswortModel;
}

/**
 * 
 */
export class MitarbeiterApi extends runtime.BaseAPI {

    /**
     * API für die Abwesenheitsübersicht
     */
    async getAbwesenheitsuebersichtGETRaw(requestParameters: GetAbwesenheitsuebersichtGETRequest): Promise<runtime.ApiResponse<AbwesenheitsUebersichtModel>> {
        const queryParameters: any = {};

        if (requestParameters.jahr !== undefined) {
            queryParameters['jahr'] = requestParameters.jahr;
        }

        if (requestParameters.monat !== undefined) {
            queryParameters['monat'] = requestParameters.monat;
        }

        if (requestParameters.showAll !== undefined) {
            queryParameters['showAll'] = requestParameters.showAll;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Mitarbeiter/GetAbwesenheitsuebersicht`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AbwesenheitsUebersichtModelFromJSON(jsonValue));
    }

    /**
     * API für die Abwesenheitsübersicht
     */
    async getAbwesenheitsuebersichtGET(requestParameters: GetAbwesenheitsuebersichtGETRequest): Promise<AbwesenheitsUebersichtModel> {
        const response = await this.getAbwesenheitsuebersichtGETRaw(requestParameters);
        return await response.value();
    }

    /**
     * Liefert den vollen Namen des eingeloggten Benutzers
     */
    async getBenutzerVollnameGETRaw(): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Mitarbeiter/GetBenutzerVollname`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Liefert den vollen Namen des eingeloggten Benutzers
     */
    async getBenutzerVollnameGET(): Promise<string> {
        const response = await this.getBenutzerVollnameGETRaw();
        return await response.value();
    }

    /**
     * API für die Kontierungsübersicht
     */
    async getKontierungsuebersichtGETRaw(requestParameters: GetKontierungsuebersichtGETRequest): Promise<runtime.ApiResponse<KontierungsuebersichtModel>> {
        const queryParameters: any = {};

        if (requestParameters.jahr !== undefined) {
            queryParameters['jahr'] = requestParameters.jahr;
        }

        if (requestParameters.monat !== undefined) {
            queryParameters['monat'] = requestParameters.monat;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Mitarbeiter/GetKontierungsuebersicht`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => KontierungsuebersichtModelFromJSON(jsonValue));
    }

    /**
     * API für die Kontierungsübersicht
     */
    async getKontierungsuebersichtGET(requestParameters: GetKontierungsuebersichtGETRequest): Promise<KontierungsuebersichtModel> {
        const response = await this.getKontierungsuebersichtGETRaw(requestParameters);
        return await response.value();
    }

    /**
     * Liefert Daten von Mitarbeiter
     */
    async getMitarbeiterGETRaw(): Promise<runtime.ApiResponse<MitarbeiterModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Mitarbeiter/GetMitarbeiter`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MitarbeiterModelFromJSON(jsonValue));
    }

    /**
     * Liefert Daten von Mitarbeiter
     */
    async getMitarbeiterGET(): Promise<MitarbeiterModel> {
        const response = await this.getMitarbeiterGETRaw();
        return await response.value();
    }

    /**
     * API für die Abwesenheitsübersicht
     */
    async getMobileAbwesenheitsuebersichtGETRaw(requestParameters: GetMobileAbwesenheitsuebersichtGETRequest): Promise<runtime.ApiResponse<AbwesenheitsUebersichtModel>> {
        const queryParameters: any = {};

        if (requestParameters.jahr !== undefined) {
            queryParameters['jahr'] = requestParameters.jahr;
        }

        if (requestParameters.monat !== undefined) {
            queryParameters['monat'] = requestParameters.monat;
        }

        if (requestParameters.showAll !== undefined) {
            queryParameters['showAll'] = requestParameters.showAll;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Mitarbeiter/GetMobileAbwesenheitsuebersicht`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AbwesenheitsUebersichtModelFromJSON(jsonValue));
    }

    /**
     * API für die Abwesenheitsübersicht
     */
    async getMobileAbwesenheitsuebersichtGET(requestParameters: GetMobileAbwesenheitsuebersichtGETRequest): Promise<AbwesenheitsUebersichtModel> {
        const response = await this.getMobileAbwesenheitsuebersichtGETRaw(requestParameters);
        return await response.value();
    }

    /**
     * API für die Planungsuebersicht
     */
    async getMobilePlanungsuebersichtGETRaw(requestParameters: GetMobilePlanungsuebersichtGETRequest): Promise<runtime.ApiResponse<Array<RessourcenModel>>> {
        const queryParameters: any = {};

        if (requestParameters.woche !== undefined) {
            queryParameters['woche'] = (requestParameters.woche as any).toISOString();
        }

        if (requestParameters.naechsteWoche !== undefined) {
            queryParameters['naechsteWoche'] = requestParameters.naechsteWoche;
        }

        if (requestParameters.vorWoche !== undefined) {
            queryParameters['vorWoche'] = requestParameters.vorWoche;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Mitarbeiter/GetMobilePlanungsuebersicht`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RessourcenModelFromJSON));
    }

    /**
     * API für die Planungsuebersicht
     */
    async getMobilePlanungsuebersichtGET(requestParameters: GetMobilePlanungsuebersichtGETRequest): Promise<Array<RessourcenModel>> {
        const response = await this.getMobilePlanungsuebersichtGETRaw(requestParameters);
        return await response.value();
    }

    /**
     * API für die Monatsübersicht
     */
    async getMonatsuebersichtGETRaw(requestParameters: GetMonatsuebersichtGETRequest): Promise<runtime.ApiResponse<MonatsUebersichtModel>> {
        const queryParameters: any = {};

        if (requestParameters.jahr !== undefined) {
            queryParameters['jahr'] = requestParameters.jahr;
        }

        if (requestParameters.monat !== undefined) {
            queryParameters['monat'] = requestParameters.monat;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Mitarbeiter/GetMonatsuebersicht`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MonatsUebersichtModelFromJSON(jsonValue));
    }

    /**
     * API für die Monatsübersicht
     */
    async getMonatsuebersichtGET(requestParameters: GetMonatsuebersichtGETRequest): Promise<MonatsUebersichtModel> {
        const response = await this.getMonatsuebersichtGETRaw(requestParameters);
        return await response.value();
    }

    /**
     * API für die Planungsuebersicht
     */
    async getPlanungsuebersichtGETRaw(requestParameters: GetPlanungsuebersichtGETRequest): Promise<runtime.ApiResponse<Array<RessourcenModel>>> {
        const queryParameters: any = {};

        if (requestParameters.woche !== undefined) {
            queryParameters['woche'] = (requestParameters.woche as any).toISOString();
        }

        if (requestParameters.naechsteWoche !== undefined) {
            queryParameters['naechsteWoche'] = requestParameters.naechsteWoche;
        }

        if (requestParameters.vorWoche !== undefined) {
            queryParameters['vorWoche'] = requestParameters.vorWoche;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Mitarbeiter/GetPlanungsuebersicht`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RessourcenModelFromJSON));
    }

    /**
     * API für die Planungsuebersicht
     */
    async getPlanungsuebersichtGET(requestParameters: GetPlanungsuebersichtGETRequest): Promise<Array<RessourcenModel>> {
        const response = await this.getPlanungsuebersichtGETRaw(requestParameters);
        return await response.value();
    }

    /**
     * Ändert das Passwort des eingeloggten Users
     */
    async passwortAendernPOSTRaw(requestParameters: PasswortAendernPOSTRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Mitarbeiter/PasswortAendern`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NeuesPasswortModelToJSON(requestParameters.neuesPasswortModel),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Ändert das Passwort des eingeloggten Users
     */
    async passwortAendernPOST(requestParameters: PasswortAendernPOSTRequest): Promise<void> {
        await this.passwortAendernPOSTRaw(requestParameters);
    }

}
