import * as React from 'react';
import { Route } from 'react-router';
import { WithLayout } from './components/Layout';
import { ToastProvider } from "react-toast-notifications";

import './custom.css';
import './fonts.css';
import 'semantic-ui-css/semantic.min.css';

import { Zeiterfassung } from './components/Zeiterfassung';
import { Kontierungsuebersicht } from './components/Kontierung/Kontierungsuebersicht';
import { Monatsuebersicht } from './components/Monatsuebersicht';
import { Info } from './components/Info';
import { Abwesenheit } from './components/Abwesenheit';
import { Abwesenheitsuebersicht } from './components/Abwesenheitsubersicht';
import { ErrorBoundary } from './components/ErrorBoundary';
import { ReactQueryConfigProvider } from 'react-query';
import { ToastMessage } from "./components/ToastMessage";
import { CatchApiErrors } from "./components/CatchApiErrors";
import { ToastContainer } from "./components/ToastContainer";
import { Redirect, Switch } from 'react-router-dom';
import { AuthenticatedRoute, AuthProvider, Callback, Logout, LogoutCallback, SilentRenew } from './authProvider';
import { AbwesenheitsuebersichtM } from './components/Mobile/AbwesenheitsubersichtM';
import { Ressourcenuebersicht } from './components/Ressourcenuebersicht';
import { RessourcenuebersichtM } from './components/Mobile/RessourcenuebersichtM';
import { ZeiterfassungS } from './components/Mobile/ZeiterfassungS';
import { NeuesPasswort } from './components/User/NeuesPasswort';
import { Leistungsnachweis } from './components/Leistungsnachweis';
import { handbuch_routes } from './utils/HandbuchLoader';

export default class App extends React.Component {
  static displayName = App.name;

  render() {
    return (
      <ErrorBoundary>
        <ReactQueryConfigProvider config={{
          queries: {
            refetchOnWindowFocus: false,
            retry: 0,
            staleTime: 500
          }
        }} >
          <ToastProvider
            autoDismissTimeout={6000}
            components={{ Toast: ToastMessage, ToastContainer: ToastContainer }}
            placement="top-center"
          >
            <AuthProvider>
            <CatchApiErrors>
              <Switch>
                <Route exact={true} path="/signin-oidc" component={Callback} />
                <Route exact={true} path="/logout" component={Logout} />
                <Route exact={true} path="/logout/callback" component={LogoutCallback} />
                {/*<Route exact={true} path="/register" component={Register} />*/}
                <Route exact={true} path="/silentrenew" component={SilentRenew} />
                {/*<AuthenticatedRoute path="/dashboard" component={PrivatePage} />*/}
                  {/*<Route path="/" component={PublicPage} />*/}
                  <Route path='/neuesPasswort' component={WithLayout(NeuesPasswort)} />

                <AuthenticatedRoute path='/zeiterfassung/:datum?' component={WithLayout(Zeiterfassung)} />
                <AuthenticatedRoute path='/kontierungsuebersicht' component={WithLayout(Kontierungsuebersicht)} />
                <Route path='/info' component={WithLayout(Info)} />
                  <AuthenticatedRoute path='/abwesenheit' component={WithLayout(Abwesenheit)} />
                  <AuthenticatedRoute path='/leistungsnachweis' component={WithLayout(Leistungsnachweis)} />
                <AuthenticatedRoute path='/monatsuebersicht' component={WithLayout(Monatsuebersicht, true)} />
                  <AuthenticatedRoute path='/abwesenheitsuebersicht' component={WithLayout(Abwesenheitsuebersicht, true)} />
                  <AuthenticatedRoute path='/abwesenheiten' component={WithLayout(AbwesenheitsuebersichtM)} />
                  <AuthenticatedRoute path='/ressourcenuebersicht' component={WithLayout(Ressourcenuebersicht)} />
                  <AuthenticatedRoute path='/arbeitsplan' component={WithLayout(RessourcenuebersichtM)} />
                  <AuthenticatedRoute path='/stempeln' component={WithLayout(ZeiterfassungS)} />
                <Route path='/index.html' component={() => <Redirect to="/" />} />

                {handbuch_routes.map((route, index) => <AuthenticatedRoute path={[route.path, `/${route.path}`]} key={`hbroute${index}`} component={route.component} />)} {/* THE /${route.path} IS A TEMPORARY FIX - DO NOT EDIT!!! */}

                <AuthenticatedRoute component={WithLayout(Zeiterfassung)} />
                

              </Switch>
              </CatchApiErrors>
            </AuthProvider>
          </ToastProvider>
        </ReactQueryConfigProvider>
      </ErrorBoundary>
    );
  }
}

