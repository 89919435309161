/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EAZTyp,
    EAZTypFromJSON,
    EAZTypFromJSONTyped,
    EAZTypToJSON,
    ELookup,
    ELookupFromJSON,
    ELookupFromJSONTyped,
    ELookupToJSON,
} from './';

/**
 * View-Model: Dropdown für Abwesenheiten
 * @export
 * @interface AbwesenheitsTypenModel
 */
export interface AbwesenheitsTypenModel {
    /**
     * Key als Bezeichnung
     * @type {string}
     * @memberof AbwesenheitsTypenModel
     */
    key?: string | null;
    /**
     * 
     * @type {EAZTyp}
     * @memberof AbwesenheitsTypenModel
     */
    value?: EAZTyp;
    /**
     * 
     * @type {ELookup}
     * @memberof AbwesenheitsTypenModel
     */
    tagesstempelID?: ELookup;
}

export function AbwesenheitsTypenModelFromJSON(json: any): AbwesenheitsTypenModel {
    return AbwesenheitsTypenModelFromJSONTyped(json, false);
}

export function AbwesenheitsTypenModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AbwesenheitsTypenModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'value': !exists(json, 'value') ? undefined : EAZTypFromJSON(json['value']),
        'tagesstempelID': !exists(json, 'tagesstempelID') ? undefined : ELookupFromJSON(json['tagesstempelID']),
    };
}

export function AbwesenheitsTypenModelToJSON(value?: AbwesenheitsTypenModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'value': EAZTypToJSON(value.value),
        'tagesstempelID': ELookupToJSON(value.tagesstempelID),
    };
}


