import * as React from 'react';
import { useQuery } from 'react-query';
import MitarbeiterApi from '../../api/MitarbeiterApi';
import { Grid, Segment, Button, HeaderSubheader, GridRow} from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import CommonApi from '../../api/CommonApi';
import { EOptions } from '../../generated';
import useDocumentTitle, { DOCUMENT_TITLES } from '../../hooks/useDocumentTitle';
import { Legende } from '../Legende';


interface RessourcenuebersichtMProps {
}

export const RessourcenuebersichtM: React.FC<RessourcenuebersichtMProps> = (props) => {
  useDocumentTitle(DOCUMENT_TITLES.arbeitsplan)
  let history = useHistory();
  const commonVersions = useQuery(["getVersionGET"],
    () => CommonApi.getVersionGET({ clearCache: false }));
  async function tagWechseln(vor: boolean, naechste: boolean) {
    let switchTo = startDate;
    if (vor) {
      switchTo = new Date(startDate.setDate(switchTo.getDate() - 7));
    }
    else {
      switchTo = new Date(startDate.setDate(switchTo.getDate() + 7));
    }
    await SetVorWoche(vor);
    await SetNaechsteWoche(naechste);
    await SetStartDate(switchTo);
    await ressourcenUebersichtM.refetch();
  }

  const [startDate, SetStartDate] = React.useState(new Date());
  const [naechsteWoche, SetNaechsteWoche] = React.useState(false);
  const [vorWoche, SetVorWoche] = React.useState(false);
  const ressourcenUebersichtM = useQuery(["getMobilePlanungsuebersichtGET", startDate, naechsteWoche, vorWoche], () =>
    MitarbeiterApi.getMobilePlanungsuebersichtGET({ woche: startDate, naechsteWoche: naechsteWoche, vorWoche: vorWoche })
  );
  if (!commonVersions.isLoading && commonVersions.data && commonVersions.data.options && !commonVersions.data.options.includes(EOptions.RessourcenPlanung)) {
    history.push({
      pathname: `/`
    });
  }
  return (
    <>
      <Segment loading={ressourcenUebersichtM.isLoading && commonVersions.isLoading}>
        <h3 style={{ textAlign: 'center' }}>
          <Button className={"prev-next hide-print"} icon={"angle left"} primary type={'button'} data-tooltip={"Zur Vorwoche wechseln"}
            onClick={async () => await tagWechseln(true, false)} /> &nbsp;
          Arbeitsplan (KW: {ressourcenUebersichtM.data && ressourcenUebersichtM.data[0] && ressourcenUebersichtM.data[0].kalenderwoche})
          &nbsp; <Button className={"prev-next hide-print"} icon={"angle right"} primary type={'button'} data-tooltip={"Zur nächsten Woche wechseln"}
            onClick={async () => await tagWechseln(false, true)} />
          <HeaderSubheader style={{ paddingTop: '0.3em' }}>{ressourcenUebersichtM.data && ressourcenUebersichtM.data[0] && ressourcenUebersichtM.data[0].zeitraum}</HeaderSubheader>
        </h3>

        {ressourcenUebersichtM.data && ressourcenUebersichtM.data && !ressourcenUebersichtM.data.find(f => f.errorMsg) ?
          <Grid celled={true}>
            <Grid.Row>
              <Grid.Column className={'shadow-print'} style={{ paddingLeft: '8px', paddingRight: '0' }} width='9'><b>Projekte</b></Grid.Column>
              {ressourcenUebersichtM.data[0].tage && ressourcenUebersichtM.data[0].tage.map(days => {
                return (<>
                  {
                    <Grid.Column className={'shadow-print'} style={{ paddingLeft: '0', paddingRight: '0', textAlign: 'center' }} width='1'>{days.substring(0, 2)}</Grid.Column>
                  }
                </>)
              })}
            </Grid.Row>
            {ressourcenUebersichtM.data.map((projektdata) => {
              return (
                <Grid.Row>
                  {
                    <Grid.Column className={'shadow-print'} style={{ paddingLeft: '8px', paddingRight: '0' }} width='9'>{projektdata.projektname}</Grid.Column>
                  }
                  {projektdata.einteilung && projektdata.einteilung.map(tage => {
                    return (<>
                      <Grid.Column className={'shadow-print'} style={{ paddingLeft: '0', paddingRight: '0', backgroundColor: tage.vonZeit ? projektdata.farbe : "", textAlign: 'center' }} width='1'>{tage.code && tage.code !== '#' ? tage.code[0] : ''}</Grid.Column>
                    </>
                    )
                  })}
                </Grid.Row>)
            }
            )}
            </Grid>
          : <>
            {!ressourcenUebersichtM.isLoading && ressourcenUebersichtM.data && ressourcenUebersichtM.data.find(f => f.errorMsg) && ressourcenUebersichtM.data.map(error => {
              return (<>
                {
                  <h3 style={{ textAlign: 'center' }}>{error.errorMsg} </h3>
                }</>)
            })}
          </>}
      </Segment>
      {ressourcenUebersichtM.data && ressourcenUebersichtM.data && !ressourcenUebersichtM.data.find(f => f.errorMsg) &&
            ressourcenUebersichtM.data[0].tage && ressourcenUebersichtM.data[0].tage.map((days, index) => {
              return (<>
                {<Segment style={{ borderTop: "0.2em solid #0094aa !important"}}>
                  <h3 style={{ textAlign: 'center' }}>{days}.</h3>
                  {ressourcenUebersichtM.data && ressourcenUebersichtM.data.find(f => f.einteilung![index].code!.length !== 1) ?
                    ressourcenUebersichtM.data.map((projektdata) => {
                        return ((projektdata!.einteilung![index].code === '#') ?
                          <></> :
                          <Grid>
                            <Grid.Row>
                              {
                                <Grid.Column width='9'>{projektdata.projektname}</Grid.Column>
                              }
                              {projektdata && projektdata.einteilung && projektdata.einteilung[index] &&
                                projektdata.einteilung[index].code && projektdata.einteilung[index].code!.length > 1 ?
                                <Grid.Column width='6'>{projektdata.einteilung && projektdata.einteilung[index] && projektdata.einteilung[index].code && projektdata.einteilung[index].code![0]} </Grid.Column>
                                : <Grid.Column width='6'>{projektdata!.einteilung![index].vonZeit} - {projektdata!.einteilung![index].bisZeit}</Grid.Column>
                              }
                            </Grid.Row>
                            {(projektdata.projektKontakt || projektdata.projektTelefon) &&
                              <Grid.Row>
                                <Grid.Column width='9'>{projektdata.projektKontakt ? projektdata.projektKontakt : ''}</Grid.Column>
                                <Grid.Column width='7'>{projektdata.projektTelefon ? projektdata.projektTelefon : ''}</Grid.Column>
                              </Grid.Row>
                            }
                            {projektdata.projektAdresse &&
                              <GridRow>
                                <Grid.Column width='16'>{projektdata.projektAdresse}</Grid.Column>
                              </GridRow>
                            }
                          </Grid>)
                      }
                      )
                    : <h4 style={{ textAlign: 'center' }}>Keine Einteilung vorhanden</h4>}
                </Segment>}
              </>)
            })}

      <Segment>
        <Legende codes={["A - Genehmigte Abwesenheiten", "F - Frei / Betriebsfrei / Feiertag"]} />
      </Segment>
      <Grid className={`hide-print`}>
        <Grid.Column width="13"> </Grid.Column>
        <Grid.Column width="1"> <Button floated='left' size='tiny' secondary onClick={() => window.print()} icon="print" className={`row-button hide-print`} /> </Grid.Column>
      </Grid>
      </>
  )
}