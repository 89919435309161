/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ArbeitsTagModel,
    ArbeitsTagModelFromJSON,
    ArbeitsTagModelFromJSONTyped,
    ArbeitsTagModelToJSON,
} from './';

/**
 * Model: Arbeitstag eines Mitarbeiters
 * @export
 * @interface MonatsUebersichtModel
 */
export interface MonatsUebersichtModel {
    /**
     * Arbeitstage des gewählten Monats)
     * @type {number}
     * @memberof MonatsUebersichtModel
     */
    anwesenheitsTage?: number;
    /**
     * Fehltage des gewählten Monats
     * @type {number}
     * @memberof MonatsUebersichtModel
     */
    fehlendeTage?: number;
    /**
     * Krankentage des gewählten Monats
     * @type {number}
     * @memberof MonatsUebersichtModel
     */
    krankheitsTage?: number;
    /**
     * Gebrauchte Urlaubstage des gewählten Monats
     * @type {number}
     * @memberof MonatsUebersichtModel
     */
    urlaubstage?: number;
    /**
     * Freietage des gewählten Monats
     * @type {number}
     * @memberof MonatsUebersichtModel
     */
    freieTage?: number;
    /**
     * Zeitausgleichstage des gewählten Monats
     * @type {number}
     * @memberof MonatsUebersichtModel
     */
    zeitausgleichsTage?: number;
    /**
     * Resturlaub lt. Vormonat
     * @type {number}
     * @memberof MonatsUebersichtModel
     */
    restUrlaubVorMonat?: number;
    /**
     * Resturlaub lt. Monat
     * @type {number}
     * @memberof MonatsUebersichtModel
     */
    restUrlaub?: number;
    /**
     * Geleistet Überstunden zu 50%
     * @type {string}
     * @memberof MonatsUebersichtModel
     */
    geleisteteUeberstunden50?: string | null;
    /**
     * Geleistet Überstunden zu 100%
     * @type {string}
     * @memberof MonatsUebersichtModel
     */
    geleisteteUeberstunden100?: string | null;
    /**
     * Summe gelisteter Überstunden
     * @type {string}
     * @memberof MonatsUebersichtModel
     */
    geleisteteUeberstundenGesamt?: string | null;
    /**
     * Ausbezahlte Überstunden zu 50%
     * @type {string}
     * @memberof MonatsUebersichtModel
     */
    ausbezahlteUeberstunden50?: string | null;
    /**
     * Ausbezahlte Überstunden zu 100%
     * @type {string}
     * @memberof MonatsUebersichtModel
     */
    ausbezahlteUeberstunden100?: string | null;
    /**
     * Summe ausbezahlter Überstunden
     * @type {string}
     * @memberof MonatsUebersichtModel
     */
    ausbezahlteUeberstundenGesamt?: string | null;
    /**
     * Stunden Differenz des Vormonats
     * @type {string}
     * @memberof MonatsUebersichtModel
     */
    plusMinusVorMonat?: string | null;
    /**
     * Gesamte Stunden Differenz
     * @type {string}
     * @memberof MonatsUebersichtModel
     */
    plusMinusGesamt?: string | null;
    /**
     * Stunden Differenz des gewählten Monats
     * @type {string}
     * @memberof MonatsUebersichtModel
     */
    plusMinusAktuell?: string | null;
    /**
     * Übertrag Stunden Differenz des gewählten Monats
     * @type {string}
     * @memberof MonatsUebersichtModel
     */
    plusMinusUebertrag?: string | null;
    /**
     * Arbeitszeiten für gewählten Monat
     * @type {Array<ArbeitsTagModel>}
     * @memberof MonatsUebersichtModel
     */
    monatsAnsicht?: Array<ArbeitsTagModel> | null;
}

export function MonatsUebersichtModelFromJSON(json: any): MonatsUebersichtModel {
    return MonatsUebersichtModelFromJSONTyped(json, false);
}

export function MonatsUebersichtModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonatsUebersichtModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'anwesenheitsTage': !exists(json, 'anwesenheitsTage') ? undefined : json['anwesenheitsTage'],
        'fehlendeTage': !exists(json, 'fehlendeTage') ? undefined : json['fehlendeTage'],
        'krankheitsTage': !exists(json, 'krankheitsTage') ? undefined : json['krankheitsTage'],
        'urlaubstage': !exists(json, 'urlaubstage') ? undefined : json['urlaubstage'],
        'freieTage': !exists(json, 'freieTage') ? undefined : json['freieTage'],
        'zeitausgleichsTage': !exists(json, 'zeitausgleichsTage') ? undefined : json['zeitausgleichsTage'],
        'restUrlaubVorMonat': !exists(json, 'restUrlaubVorMonat') ? undefined : json['restUrlaubVorMonat'],
        'restUrlaub': !exists(json, 'restUrlaub') ? undefined : json['restUrlaub'],
        'geleisteteUeberstunden50': !exists(json, 'geleisteteUeberstunden50') ? undefined : json['geleisteteUeberstunden50'],
        'geleisteteUeberstunden100': !exists(json, 'geleisteteUeberstunden100') ? undefined : json['geleisteteUeberstunden100'],
        'geleisteteUeberstundenGesamt': !exists(json, 'geleisteteUeberstundenGesamt') ? undefined : json['geleisteteUeberstundenGesamt'],
        'ausbezahlteUeberstunden50': !exists(json, 'ausbezahlteUeberstunden50') ? undefined : json['ausbezahlteUeberstunden50'],
        'ausbezahlteUeberstunden100': !exists(json, 'ausbezahlteUeberstunden100') ? undefined : json['ausbezahlteUeberstunden100'],
        'ausbezahlteUeberstundenGesamt': !exists(json, 'ausbezahlteUeberstundenGesamt') ? undefined : json['ausbezahlteUeberstundenGesamt'],
        'plusMinusVorMonat': !exists(json, 'plusMinusVorMonat') ? undefined : json['plusMinusVorMonat'],
        'plusMinusGesamt': !exists(json, 'plusMinusGesamt') ? undefined : json['plusMinusGesamt'],
        'plusMinusAktuell': !exists(json, 'plusMinusAktuell') ? undefined : json['plusMinusAktuell'],
        'plusMinusUebertrag': !exists(json, 'plusMinusUebertrag') ? undefined : json['plusMinusUebertrag'],
        'monatsAnsicht': !exists(json, 'monatsAnsicht') ? undefined : (json['monatsAnsicht'] === null ? null : (json['monatsAnsicht'] as Array<any>).map(ArbeitsTagModelFromJSON)),
    };
}

export function MonatsUebersichtModelToJSON(value?: MonatsUebersichtModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'anwesenheitsTage': value.anwesenheitsTage,
        'fehlendeTage': value.fehlendeTage,
        'krankheitsTage': value.krankheitsTage,
        'urlaubstage': value.urlaubstage,
        'freieTage': value.freieTage,
        'zeitausgleichsTage': value.zeitausgleichsTage,
        'restUrlaubVorMonat': value.restUrlaubVorMonat,
        'restUrlaub': value.restUrlaub,
        'geleisteteUeberstunden50': value.geleisteteUeberstunden50,
        'geleisteteUeberstunden100': value.geleisteteUeberstunden100,
        'geleisteteUeberstundenGesamt': value.geleisteteUeberstundenGesamt,
        'ausbezahlteUeberstunden50': value.ausbezahlteUeberstunden50,
        'ausbezahlteUeberstunden100': value.ausbezahlteUeberstunden100,
        'ausbezahlteUeberstundenGesamt': value.ausbezahlteUeberstundenGesamt,
        'plusMinusVorMonat': value.plusMinusVorMonat,
        'plusMinusGesamt': value.plusMinusGesamt,
        'plusMinusAktuell': value.plusMinusAktuell,
        'plusMinusUebertrag': value.plusMinusUebertrag,
        'monatsAnsicht': value.monatsAnsicht === undefined ? undefined : (value.monatsAnsicht === null ? null : (value.monatsAnsicht as Array<any>).map(ArbeitsTagModelToJSON)),
    };
}


