import * as React from "react";
import MitarbeiterApi from "../api/MitarbeiterApi";
import CommonApi from "../api/CommonApi";
import ZeitstempelApi from "../api/ZeitstempelApi";
import { InfoBox } from "./InfoBoxen/InfoBox";
import { TagStempel, ZeitstempelRow, StempelErfassenV2POSTRequest, EUIMode, EOptions } from "../generated";
import { useQuery } from "react-query";
import useAsyncEdit from "../hooks/useAsyncEdit";
import { stringTimeToNumber } from "./Helper";

import { Formik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import useMessages from "../hooks/useMessages";
import { timeConvertToHoursAndMinutes, ZeiterfassungForm } from "./ZeiterfassungForm";
import { clearPositionWatch, watchPosition } from "../components/Zeiterfassung/Geolocation";
import useDocumentTitle, { DOCUMENT_TITLES } from "../hooks/useDocumentTitle";

interface IZeiterfassungProps { }

/** Handler/Converter */
const tagSaldoGearbeitet = (datum: Date, values: ZeitstempelRow[]) => {
  let zwischensumme: number = 0;
  for (let x = 0; x + 1 <= values.length; x += 1) {
    let dt1 = values && values[x] && values[x].von && values[x].von!.stempel ? values[x].von!.stempel! : undefined;
    let dt2 = values && values[x] && values[x].bis && values[x].bis!.stempel ? values[x].bis!.stempel! : undefined;
    if (dt1 && typeof dt1.getTime === 'function' && dt2 && typeof dt2.getTime === 'function') {
      let diff = (dt2?.getTime() - dt1?.getTime()) / 1000;
      zwischensumme += Math.abs(Math.round((diff /= 60)));
    }
  }
  return timeConvertToHoursAndMinutes(datum, zwischensumme);
};

export const Zeiterfassung: React.FC<IZeiterfassungProps> = (props) => {
  useDocumentTitle(DOCUMENT_TITLES.zeiterfassung)
  /** Fetch von Daten die Gebraucht werden */
  const commonVersions = useQuery(["getVersionGET"], () =>
    CommonApi.getVersionGET({ clearCache: false })
  );
  /**AsyncEdit Hook für Zeitstempel-Api */
  let paramDatum: any = useParams();
  let today = paramDatum && paramDatum['datum'] === undefined;
  const [datum, setDatum] = React.useState(paramDatum && paramDatum['datum'] ? new Date(paramDatum['datum'])
    : new Date(new Date().toISOString().slice(undefined, new Date().toISOString().indexOf('T'))));

  const msg = useMessages();
  let history = useHistory();

  /**
   * If GPS is enabled - tracks user location on page load and saves any changes to state 
   * when user clicks save, the current position is saved if the accuraccy is high enough
   * location-tracking is stopped when the components unmounts 
   */
  React.useEffect(() => {
    if (commonVersions.data?.options?.includes(EOptions.GPSDaten)) watchPosition(updatePosition)
  }, [commonVersions.data?.options])

  const [currentCoords, setCurrentCoords] = React.useState<GeolocationPosition | null>(null)
  const [locationWatchID, setLocationWatchID] = React.useState<number | null>(null)
  const updatePosition = (position: GeolocationPosition, watchID: number) => {
    setCurrentCoords(position)
    setLocationWatchID(watchID)
  }
  React.useEffect(() => {
    return () => {
      clearPositionWatch(locationWatchID)
    }
  }, [])

  const zeitstempelRowData = useAsyncEdit<
    TagStempel,
    StempelErfassenV2POSTRequest
  >({
    queryKey: `getZeitstempelTagGET/${datum}`,
    default: {},
    load: (id) => ZeitstempelApi.getZeitstempelTagGET({ date: datum }),
    save: async (values, prev) => {
      try {
        if (commonVersions.data?.options?.includes(EOptions.GPSDaten)) {
          if (currentCoords?.coords?.accuracy && currentCoords?.coords?.accuracy <= 65 && values.tagStempel.zeitstempel) {
            console.log("Saving current position")
            values.tagStempel.zeitstempel.latitude = currentCoords.coords.latitude;
            values.tagStempel.zeitstempel.longitude = currentCoords.coords.longitude;
          } else {
            console.log("Failed to save position, accuraccy:", currentCoords?.coords?.accuracy)
          }
        }
        const result = await ZeitstempelApi.stempelErfassenV2POST(values);
        msg.addMessage({ header: "Stempel erfolgreich gespeichert!" }, "success", true, true);
        return result;
      } catch (e) {
        return prev;
      }
    },
  });
  //redirect wenn simplemode aktiviert und zb.: normalmode als favorit gespeichert
  if (zeitstempelRowData && zeitstempelRowData.result && (zeitstempelRowData.result.uiMode !== undefined)
    && (zeitstempelRowData.result.uiMode === EUIMode.Simple)) {
    history.push({
      pathname: `/stempeln`
    });
  }

  const zeitstempel = React.useMemo<TagStempel>(() => zeitstempelRowData.result || {}, [zeitstempelRowData.result]);
  const saveZeitstempel = React.useCallback(async (v) => {
    zeitstempelRowData.save({ tagStempel: v, date: datum });
  }, [zeitstempelRowData, datum]);


  const benutzername = useQuery(["getBenutzerVollnameGET"], () =>
    MitarbeiterApi.getBenutzerVollnameGET()
  );

  function tagWechseln(vor: boolean) {
    //disable = true;
    let switchTo = new Date();
    let oldDate = new Date();
    if (vor) {
      oldDate = new Date(datum);
      switchTo = new Date(oldDate.setDate(datum.getDate() + 1));
    }
    else {
      oldDate = new Date(datum);
      switchTo = new Date(oldDate.setDate(datum.getDate() - 1));
    }
    if (history.location.pathname.includes("zeiterfassung")) {
      let dateString = switchTo.toISOString().slice(undefined, switchTo.toISOString().indexOf('T'));
      let changedDate = new Date(dateString);
      if (switchTo.getDate() !== changedDate.getDate() && vor) {
        switchTo = new Date(changedDate.setDate(datum.getDate() + 2));
        dateString = switchTo.toISOString().slice(undefined, switchTo.toISOString().indexOf('T'));
      }
      history.push({
        pathname: `${dateString}`,
        state: { date: switchTo },
      });
    }
    else {
      let dateString = switchTo.toISOString().slice(undefined, switchTo.toISOString().indexOf('T'));
      let changedDate = new Date(dateString);
      if (switchTo.getDate() !== changedDate.getDate() && vor) {
        switchTo = new Date(changedDate.setDate(datum.getDate() + 2));
        dateString = switchTo.toISOString().slice(undefined, switchTo.toISOString().indexOf('T'));
      }
      history.push({
        pathname: `zeiterfassung/${dateString}`,
        state: { date: switchTo },
      });
    }
    setDatum(switchTo);
  }


  const onSubmit = React.useCallback((values: TagStempel, action) => {
    var valid = true;
    values && values.zeitstempel && values.zeitstempel.stempelRow && values.zeitstempel.stempelRow.map(m => {
      if ((valid && m.bis && m.bis.typ && m.bis.typ.includes('Sonstig') && m.bis.info && ((m.bis.info === '') || !m.bis.info))) {
        msg.addMessage({ header: "Gehen-Stempel benötigt eine Begründung!" }, "error", true, true);
        valid = false;
      }
      else if (valid && m.von && m.von.typ && m.von.typ.includes('Sonstig') && m.von.info && ((m.von.info === '') || !m.von.info)) {
        msg.addMessage({ header: "Kommen-Stempel benötigt eine Begründung!" }, "error", true, true);
        valid = false;
      }
      else if (((valid && m.von && m.von.stempel && (typeof m.von.stempel.getTime !== 'function'))
        || (valid && m.bis && m.bis.stempel && (typeof m.bis.stempel.getTime !== 'function')))) {
        msg.addMessage({ header: "Bitte gültiges Zeitformat hh:mm wählen" }, "error", true, true);
        valid = false;
      }
      return valid; //Array Prototyp löst warnings aus ohne return innerhalb von map
    }
    );
    let errors: string[] = [];
    values.zeitstempel && values.zeitstempel.projektKontierungen &&
      values.zeitstempel.projektKontierungen.map(v => {
        if (typeof v.anzahlMinuten === 'string') {
          v.anzahlMinuten = stringTimeToNumber(v.anzahlMinuten)
        }
        if (
          ((((v.anzahlMinuten !== 0) && (v.anmerkungErforderlich !== undefined) && (v.anmerkungErforderlich === true)
            && ((v.taetigkeitAnmerkungErforderlich === undefined) || (v.taetigkeitAnmerkungErforderlich === true)))
            || ((v.anzahlMinuten !== 0) && (v.anmerkungErforderlich === false) && (v.taetigkeitAnmerkungErforderlich === true))
            || ((v.anzahlMinuten !== 0) && (v.anmerkungErforderlich === true) &&
              ((v.taetigkeitAnmerkungErforderlich === undefined) || (v.taetigkeitAnmerkungErforderlich === false))
              && (v.projektTaetigkeiten && !v.projektTaetigkeiten.find(f => f.taetigkeitAendern === true))))
            &&
            ((typeof v.anmerkung === 'string') && ((v.anmerkung === undefined) || (v.anmerkung.length === 0) || (v.anmerkung.length > 255))))
        ) {
          let addFehler = (v.anmerkung.length > 255) ? "zu lang." : "fehlt.";
          errors.push(`Fehler: Anmerkung bei ${v.projektname} ${addFehler}`);
        }
        if (v.anzahlMinuten === 0 && (typeof v.anmerkung === 'string') && v.anmerkung.length > 0) {
          errors.push(`Fehler: Kontierung fehlt bei ${v.projektname}`);
        }
        if (v.anzahlMinuten && v.anzahlMinuten > 1440) {
          errors.push(`Fehler: Kontierung zu hoch bei ${v.projektname}`);
        }
        if (values.deleteKontierungen && !v.readOnly) {
          v.anzahlMinuten = 0;
          v.anmerkung = "";
        }
        return errors; //Array Prototyp löst warnings aus ohne return innerhalb von map
      });
    if (errors.length !== 0) {
      msg.addMessage({ header: errors.map(m => `${m} \n`) }, "error", true, true);
    }
    if (!valid || errors.length !== 0) {

      action.setSubmitting(false);
      action.resetForm({ values: values });
    }
    if (valid && errors.length === 0) {
      saveZeitstempel(values);
      action.setSubmitting(false);
      action.resetForm();
    }
  }, [msg, saveZeitstempel])

  return (
    <div key={`div-${benutzername.data}`}
      className={
        commonVersions.data?.hostEnvironment === "Test" ? "brand" : ""
      }
    >
      <InfoBox
        key={`InfoBox-${benutzername.data}`}
        benutzername={benutzername.data ?? ""}
        tagSaldo={tagSaldoGearbeitet(datum, zeitstempel && zeitstempel.zeitstempel && zeitstempel.zeitstempel.stempelRow
          && zeitstempel.zeitstempel && zeitstempel.zeitstempel.stempelRow ? zeitstempel.zeitstempel.stempelRow : [])}
        info={zeitstempel && zeitstempel.info}
        loading={benutzername.isLoading || zeitstempelRowData.loading}
      />
      {!zeitstempelRowData.loading && benutzername.data && zeitstempel.formID &&
        <Formik key={zeitstempel.formID!}
          initialValues={zeitstempel}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({
            dirty,
            values,
            handleSubmit,
            setFieldValue,
            setValues,
            isSubmitting,
          }) => (
            <ZeiterfassungForm key={`ZeiterfassungsForm-${values.formID}`}
              tagWechseln={tagWechseln}
              loading={zeitstempelRowData.loading || benutzername.isLoading}
              dirty={dirty}
              readonly={!values.isEditable}
              datum={datum}
              values={values}
              rows={values && values.zeitstempel &&
                values.zeitstempel.stempelRow && values.zeitstempel.stempelRow.length ? values.zeitstempel.stempelRow.length : 1}
              handleSubmit={handleSubmit}
              setfieldvalue={setFieldValue}
              setValues={setValues}
              isSubmitting={isSubmitting}
              today={today}
            />
          )}

        </Formik>
      }
    </div>
  );
};
