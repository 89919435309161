﻿import { useQuery, useMutation, queryCache } from 'react-query';

export interface UseAsyncEditParams<T, TSave = T> {
  queryKey: string;
  id?: string | null;
  new?: boolean;
  default: T,
  load: (id?: string) => Promise<T>;
  save: (value: TSave, prevValue: T) => Promise<T | null>;
  invalidateQuery?: string;
}

export interface UseAsyncEditResult<T, TSave = T> {
  loading: boolean;
  save: (v: TSave) => void;
  reload: () => void;
  result: T;
  loadError?: Error | null;
  saveError?: Error | null;
}

/*
 * Hook für ähnliche laden und speichern Vorgänge einer Entität mit einer id
 */
export default function useAsyncEdit<T, TSave = T>(action: UseAsyncEditParams<T, TSave>): UseAsyncEditResult<T, TSave> {
  const queryKey = [action.queryKey, action.id];
  const load = useQuery([queryKey], async () => {
    var result = action.default;
    if (!action.new) {
      result = await action.load(action.id || undefined);
    }
    return result;
  });

  const [save, saveResult] = useMutation(async (v: TSave) => {
    const previousValue = queryCache.getQueryData<T>([queryKey])
    const result = await action.save(v, previousValue || ({} as T));
    queryCache.setQueryData([queryKey], result)

    queryCache.invalidateQueries([queryKey]);
    if (action.invalidateQuery) {
      queryCache.invalidateQueries(action.invalidateQuery)
    }

    return result;
  },
    {
      // Optimistically update the cache value on mutate, but store
      // the old value and return it so that it's accessible in case of
      // an error
      onMutate: data => {
        queryCache.cancelQueries([queryKey])

        const previousValue = queryCache.getQueryData([queryKey])
        return previousValue
      },
      // On failure, roll back to the previous value
      onError: (err, variables, previousValue) =>
        queryCache.setQueryData([queryKey], previousValue),
    }
  );
  return {
    loading: load.isLoading || saveResult.isLoading,
    save: save,
    reload: load.refetch,
    result: load.data || action.default,
    loadError: load.error as any,
    saveError: saveResult.error as any
  }
}