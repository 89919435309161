/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Info zu Abwesenheiten(Urlaubstage, Zeitsaldo, ...)
 * @export
 * @interface AbwesenheitsInfo
 */
export interface AbwesenheitsInfo {
    /**
     * IST +/- eines Mitarbeiters
     * @type {string}
     * @memberof AbwesenheitsInfo
     */
    stundenSaldoIST?: string | null;
    /**
     * IST Urlaubstage eines Mitarbeiters
     * @type {number}
     * @memberof AbwesenheitsInfo
     */
    urlaubstageIST?: number | null;
    /**
     * Verbrauchte Urlaubstage eines Mitarbeiters
     * @type {number}
     * @memberof AbwesenheitsInfo
     */
    urlaubstageVerbraucht?: number | null;
}

export function AbwesenheitsInfoFromJSON(json: any): AbwesenheitsInfo {
    return AbwesenheitsInfoFromJSONTyped(json, false);
}

export function AbwesenheitsInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AbwesenheitsInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stundenSaldoIST': !exists(json, 'stundenSaldoIST') ? undefined : json['stundenSaldoIST'],
        'urlaubstageIST': !exists(json, 'urlaubstageIST') ? undefined : json['urlaubstageIST'],
        'urlaubstageVerbraucht': !exists(json, 'urlaubstageVerbraucht') ? undefined : json['urlaubstageVerbraucht'],
    };
}

export function AbwesenheitsInfoToJSON(value?: AbwesenheitsInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stundenSaldoIST': value.stundenSaldoIST,
        'urlaubstageIST': value.urlaubstageIST,
        'urlaubstageVerbraucht': value.urlaubstageVerbraucht,
    };
}


