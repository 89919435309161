import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { Footer } from './Footer';
import '../print.css';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <div className="layout">
        <NavMenu />
        <Container className={"content" + (this.props.fullContent ? " fit-content" : "")}>
          {this.props.children}
        </Container>
        <Footer/>
      </div>
    );
  }
}

export const WithLayout = (Component, fullContent) => () => (<Layout fullContent={fullContent}><Component/></Layout>);