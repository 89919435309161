/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Liste aller Mitarbeiter mit zugeordneten Abwesenheiten
 * @export
 * @interface RessourcenUebersichtModel
 */
export interface RessourcenUebersichtModel {
    /**
     * Mitarbeiter ist Anwesend von
     * @type {string}
     * @memberof RessourcenUebersichtModel
     */
    vonZeit?: string | null;
    /**
     * Mitarbeiter ist Anwesend bis
     * @type {string}
     * @memberof RessourcenUebersichtModel
     */
    bisZeit?: string | null;
    /**
     * Mitarbeiter ist Abwesend
     * @type {string}
     * @memberof RessourcenUebersichtModel
     */
    code?: string | null;
}

export function RessourcenUebersichtModelFromJSON(json: any): RessourcenUebersichtModel {
    return RessourcenUebersichtModelFromJSONTyped(json, false);
}

export function RessourcenUebersichtModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RessourcenUebersichtModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vonZeit': !exists(json, 'vonZeit') ? undefined : json['vonZeit'],
        'bisZeit': !exists(json, 'bisZeit') ? undefined : json['bisZeit'],
        'code': !exists(json, 'code') ? undefined : json['code'],
    };
}

export function RessourcenUebersichtModelToJSON(value?: RessourcenUebersichtModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vonZeit': value.vonZeit,
        'bisZeit': value.bisZeit,
        'code': value.code,
    };
}


