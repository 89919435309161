import { Suspense, LazyExoticComponent, ReactNode } from "react"

const SuspenseWrapper = ({children}:{children:ReactNode})=>{
    return (
        <Suspense fallback="Loading...">
            {children}
        </Suspense>
        );
}

export const WithSuspense = (Component:LazyExoticComponent<() => JSX.Element>) => () => (<SuspenseWrapper><Component/></SuspenseWrapper>);