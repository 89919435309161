/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LeistungsnachweisModel,
    LeistungsnachweisModelFromJSON,
    LeistungsnachweisModelToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    ProjektKontierungModified,
    ProjektKontierungModifiedFromJSON,
    ProjektKontierungModifiedToJSON,
    ProjekteModel,
    ProjekteModelFromJSON,
    ProjekteModelToJSON,
    TagStempel,
    TagStempelFromJSON,
    TagStempelToJSON,
} from '../models';

export interface AddSignaturePOSTRequest {
    leistungsnachweisID?: string;
    projectID?: string;
    name?: string;
    email?: string;
    signature?: Blob;
}

export interface EditLeistungsnachweisPOSTRequest {
    leistungsnachweisModel?: LeistungsnachweisModel;
}

export interface EditProjektKontierungPOSTRequest {
    projektKontierungModified: Array<ProjektKontierungModified>;
    date?: Date;
    del?: boolean;
}

export interface GetLeistungsnachweisGETRequest {
    projektID?: string;
}

export interface GetProjekteGETRequest {
    date?: Date;
}

/**
 * 
 */
export class ProjektKontierungApi extends runtime.BaseAPI {

    /**
     * Controller zum Hinzufügen einer Signatur für den Leistungsnachweis
     */
    async addSignaturePOSTRaw(requestParameters: AddSignaturePOSTRequest): Promise<runtime.ApiResponse<LeistungsnachweisModel>> {
        const queryParameters: any = {};

        if (requestParameters.leistungsnachweisID !== undefined) {
            queryParameters['leistungsnachweisID'] = requestParameters.leistungsnachweisID;
        }

        if (requestParameters.projectID !== undefined) {
            queryParameters['projectID'] = requestParameters.projectID;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.signature !== undefined) {
            formParams.append('signature', requestParameters.signature as any);
        }

        const response = await this.request({
            path: `/api/ProjektKontierung/AddSignature`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LeistungsnachweisModelFromJSON(jsonValue));
    }

    /**
     * Controller zum Hinzufügen einer Signatur für den Leistungsnachweis
     */
    async addSignaturePOST(requestParameters: AddSignaturePOSTRequest): Promise<LeistungsnachweisModel> {
        const response = await this.addSignaturePOSTRaw(requestParameters);
        return await response.value();
    }

    /**
     * Controller zum Hinzufügen / Editieren eines Leistungsnachweis
     */
    async editLeistungsnachweisPOSTRaw(requestParameters: EditLeistungsnachweisPOSTRequest): Promise<runtime.ApiResponse<LeistungsnachweisModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ProjektKontierung/EditLeistungsnachweis`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LeistungsnachweisModelToJSON(requestParameters.leistungsnachweisModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LeistungsnachweisModelFromJSON(jsonValue));
    }

    /**
     * Controller zum Hinzufügen / Editieren eines Leistungsnachweis
     */
    async editLeistungsnachweisPOST(requestParameters: EditLeistungsnachweisPOSTRequest): Promise<LeistungsnachweisModel> {
        const response = await this.editLeistungsnachweisPOSTRaw(requestParameters);
        return await response.value();
    }

    /**
     * Controller zum Hinzufügen einer Projektkontierung
     */
    async editProjektKontierungPOSTRaw(requestParameters: EditProjektKontierungPOSTRequest): Promise<runtime.ApiResponse<TagStempel>> {
        if (requestParameters.projektKontierungModified === null || requestParameters.projektKontierungModified === undefined) {
            throw new runtime.RequiredError('projektKontierungModified','Required parameter requestParameters.projektKontierungModified was null or undefined when calling editProjektKontierungPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString();
        }

        if (requestParameters.del !== undefined) {
            queryParameters['del'] = requestParameters.del;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ProjektKontierung/EditProjektKontierung`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.projektKontierungModified.map(ProjektKontierungModifiedToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TagStempelFromJSON(jsonValue));
    }

    /**
     * Controller zum Hinzufügen einer Projektkontierung
     */
    async editProjektKontierungPOST(requestParameters: EditProjektKontierungPOSTRequest): Promise<TagStempel> {
        const response = await this.editProjektKontierungPOSTRaw(requestParameters);
        return await response.value();
    }

    /**
     * Liefert wenn vorhanden den zu bearbeitenden Leistungsnachweis bzw den Abgeschlossenen Nachweis für heute  ansonsten wird ein Leeres Formular zur Verfügung gestellt.
     */
    async getLeistungsnachweisGETRaw(requestParameters: GetLeistungsnachweisGETRequest): Promise<runtime.ApiResponse<LeistungsnachweisModel>> {
        const queryParameters: any = {};

        if (requestParameters.projektID !== undefined) {
            queryParameters['projektID'] = requestParameters.projektID;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ProjektKontierung/GetLeistungsnachweis`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LeistungsnachweisModelFromJSON(jsonValue));
    }

    /**
     * Liefert wenn vorhanden den zu bearbeitenden Leistungsnachweis bzw den Abgeschlossenen Nachweis für heute  ansonsten wird ein Leeres Formular zur Verfügung gestellt.
     */
    async getLeistungsnachweisGET(requestParameters: GetLeistungsnachweisGETRequest): Promise<LeistungsnachweisModel> {
        const response = await this.getLeistungsnachweisGETRaw(requestParameters);
        return await response.value();
    }

    /**
     * Liefert alle verfügbaren Projekte eines Benutzers
     */
    async getProjekteGETRaw(requestParameters: GetProjekteGETRequest): Promise<runtime.ApiResponse<Array<ProjekteModel>>> {
        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ProjektKontierung/GetProjekte`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjekteModelFromJSON));
    }

    /**
     * Liefert alle verfügbaren Projekte eines Benutzers
     */
    async getProjekteGET(requestParameters: GetProjekteGETRequest): Promise<Array<ProjekteModel>> {
        const response = await this.getProjekteGETRaw(requestParameters);
        return await response.value();
    }

}
