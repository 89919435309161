import CookieStorage from './CookieStorage';
import storageAvailable from './storageAvailable';

export default class StorageFactory {

  static getStorage = () =>
    storageAvailable('sessionStorage')
        ? sessionStorage
        : storageAvailable('localStorage')
            ? localStorage
        : new CookieStorage();
}