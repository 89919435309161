import useMessages from "../hooks/useMessages";
import useEventListener from "../hooks/useEventListener";
import { FETCH_API_ERROR, FETCH_API_WARNING } from "../api/ApiErrorFetch";

interface ICatchApiErrorsProps {
  children: JSX.Element;
}

export const CatchApiErrors = (props: ICatchApiErrorsProps) => {

  const msg = useMessages();
  useEventListener(
    FETCH_API_ERROR,
    (e: any) =>  msg.addMessage({ header: e.detail }, "error")
  );
  useEventListener(
    FETCH_API_WARNING,
    (e: any) =>  msg.addMessage({ header: e.detail }, "warning")
  );

  return props.children;
};
