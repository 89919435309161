export function watchPosition(callback: Function) {
  const geo = navigator.geolocation;
  if (!geo) {
    return { error: 'Geolocation has not been acknowledged by the user or is not supported.' };
  }
  console.log(("watching position"))
  const watchID = navigator.geolocation.watchPosition(
    position => {
      callback(position, watchID)
    },
    error => {
      console.log("Rejecting", error)
    },
    { maximumAge: 0, timeout: 5000, enableHighAccuracy: true }
  )
}

export function clearPositionWatch(watchID: number | null) {
  if (watchID) navigator.geolocation.clearWatch(watchID);
}