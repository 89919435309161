import * as React from 'react';
import { useQuery } from 'react-query';
import MitarbeiterApi from '../api/MitarbeiterApi';
import { Button, Segment, Table, HeaderSubheader, Grid } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import CommonApi from '../api/CommonApi';
import { EOptions } from '../generated/models';
import useDocumentTitle, { DOCUMENT_TITLES } from '../hooks/useDocumentTitle';

interface RessourcenuebersichtProps {
}

export const Ressourcenuebersicht: React.FC<RessourcenuebersichtProps> = (props) => {
  useDocumentTitle(DOCUMENT_TITLES.arbeitsplan)
  let history = useHistory();
  const commonVersions = useQuery(["getVersionGET"],
    () => CommonApi.getVersionGET({ clearCache: false }));
  async function tagWechseln(vor: boolean, naechste: boolean) {
    let switchTo = startDate;
    if (vor) {
      switchTo = new Date(startDate.setDate(switchTo.getDate() - 7));
    }
    else {
      switchTo = new Date(startDate.setDate(switchTo.getDate() + 7));
    }
    await SetVorWoche(vor);
    await SetNaechsteWoche(naechste);
    await SetStartDate(switchTo);
    await ressourcenUebersicht.refetch();
  }

  const [startDate, SetStartDate] = React.useState(new Date());
  const [naechsteWoche, SetNaechsteWoche] = React.useState(false);
  const [vorWoche, SetVorWoche] = React.useState(false);
  const ressourcenUebersicht = useQuery(["getPlanungsuebersichtGET", startDate, naechsteWoche, vorWoche], () =>
    MitarbeiterApi.getPlanungsuebersichtGET({ woche: startDate, naechsteWoche: naechsteWoche, vorWoche: vorWoche })
  );

  if (!commonVersions.isLoading && commonVersions.data && commonVersions.data.options && !commonVersions.data.options.includes(EOptions.RessourcenPlanung)) {
    history.push({
      pathname: `/`
    });
  }

  return (
    <>
      <Segment loading={ressourcenUebersicht.isLoading && commonVersions.isLoading}>
        <h3 style={{ textAlign: 'center' }}>
          <Button className={"prev-next hide-print"} icon={"angle left"} primary type={'button'} data-tooltip={"Zur Vorwoche wechseln"}
            onClick={async () => await tagWechseln(true, false)} /> &nbsp;
          Arbeitsplan (KW: {ressourcenUebersicht.data && ressourcenUebersicht.data[0] && ressourcenUebersicht.data[0].kalenderwoche})          
          &nbsp; <Button className={"prev-next hide-print"} icon={"angle right"} primary type={'button'} data-tooltip={"Zur nächsten Woche wechseln"}
            onClick={async () => await tagWechseln(false, true)} />
          &nbsp;          
          <HeaderSubheader style={{paddingTop: '0.3em'}}>{ressourcenUebersicht.data && ressourcenUebersicht.data[0] && ressourcenUebersicht.data[0].zeitraum}</HeaderSubheader>
        </h3>
        {!ressourcenUebersicht.isLoading && ressourcenUebersicht.data && !ressourcenUebersicht.data.find(f => f.errorMsg) ?
          <Table>
            <Table.Header fullWidth>
              <Table.Row>
                <Table.HeaderCell>Projekte </Table.HeaderCell>
                {ressourcenUebersicht.data[0].tage && ressourcenUebersicht.data[0].tage.map(days => {
                  return (<>
                    {
                      <Table.HeaderCell>{days}.</Table.HeaderCell>
                    }

                  </>)
                })}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <>
                {ressourcenUebersicht.data.map((projektdata) => {
                  return (<Table.Row>
                    {
                      <Table.Cell width='2' verticalAlign='middle' className="ressourcen-cell-desktop" style={{ background: projektdata.farbe ?? "white"}}><p>{projektdata.projektname}</p>
                        <div className={'nowrap'}>{projektdata.projektKontakt} {projektdata.projektTelefon ? `( ${projektdata.projektTelefon} )` : ''}</div>
                        {projektdata.projektAdresse ? <div>{projektdata.projektAdresse} </div> : <></>}
                      </Table.Cell>
                    }
                    {projektdata.einteilung && projektdata.einteilung.map(tage => {
                      return (<>
                        {tage.code && tage.code !== '#' ?
                          <Table.Cell width='1' className="ressourcen-cell-desktop"><p>{tage.code && tage.code[0]}</p></Table.Cell>
                          : <Table.Cell width='1' className="ressourcen-cell-desktop" verticalAlign='middle' style={{ background: tage.vonZeit ? projektdata.farbe ?? "white" : "white"}}><p>{tage.vonZeit} - {tage.bisZeit}</p></Table.Cell>
                        }</>
                      )
                    })}
                  </Table.Row>)
                }
                )}
              </>
            </Table.Body>
          </Table>
          : <>
            {!ressourcenUebersicht.isLoading && ressourcenUebersicht.data && ressourcenUebersicht.data.find(f => f.errorMsg) && ressourcenUebersicht.data.map(error => {
              return (<>
                {
                  <h3 style={{ textAlign: 'center' }}>{error.errorMsg} </h3>
                }</>)
            })}</>}            
      </Segment>

      <Segment>
        <h3 style={{ textAlign: 'center' }}>Legende</h3>
        <Grid>
          <Grid.Column width="1"> </Grid.Column>
          <Grid.Column width="4">A - Genehmigte Abwesenheiten</Grid.Column>
          <Grid.Column width="1"> </Grid.Column>
          <Grid.Column width="4">F - Frei / Betriebsfrei / Feiertag</Grid.Column>
        </Grid>
      </Segment>

      </>
  )
}