/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EUIMode {
    None = 'None',
    Simple = 'Simple',
    Expert = 'Expert'
}

export function EUIModeFromJSON(json: any): EUIMode {
    return EUIModeFromJSONTyped(json, false);
}

export function EUIModeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EUIMode {
    return json as EUIMode;
}

export function EUIModeToJSON(value?: EUIMode | null): any {
    return value as any;
}

