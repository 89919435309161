/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * View-Model:Tätigketen
 * @export
 * @interface TaetigkeitenModel
 */
export interface TaetigkeitenModel {
    /**
     * Tätigkeit-ID
     * @type {string}
     * @memberof TaetigkeitenModel
     */
    id?: string | null;
    /**
     * Name der Tätigkeit
     * @type {string}
     * @memberof TaetigkeitenModel
     */
    bezeichnung?: string | null;
    /**
     * Beschreibung der Tätigkeit
     * @type {string}
     * @memberof TaetigkeitenModel
     */
    beschreibung?: string | null;
    /**
     * Anmerkung erforderlich?
     * @type {boolean}
     * @memberof TaetigkeitenModel
     */
    anmerkungErforderlich?: boolean | null;
    /**
     * Tätigkeit Favorit?
     * @type {boolean}
     * @memberof TaetigkeitenModel
     */
    favorit?: boolean;
    /**
     * Icon file
     * @type {string}
     * @memberof TaetigkeitenModel
     */
    file?: string | null;
    /**
     * Content type
     * @type {string}
     * @memberof TaetigkeitenModel
     */
    contentType?: string | null;
    /**
     * Tätigkeit ändern?
     * @type {boolean}
     * @memberof TaetigkeitenModel
     */
    taetigkeitAendern?: boolean;
}

export function TaetigkeitenModelFromJSON(json: any): TaetigkeitenModel {
    return TaetigkeitenModelFromJSONTyped(json, false);
}

export function TaetigkeitenModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaetigkeitenModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'bezeichnung': !exists(json, 'bezeichnung') ? undefined : json['bezeichnung'],
        'beschreibung': !exists(json, 'beschreibung') ? undefined : json['beschreibung'],
        'anmerkungErforderlich': !exists(json, 'anmerkungErforderlich') ? undefined : json['anmerkungErforderlich'],
        'favorit': !exists(json, 'favorit') ? undefined : json['favorit'],
        'file': !exists(json, 'file') ? undefined : json['file'],
        'contentType': !exists(json, 'contentType') ? undefined : json['contentType'],
        'taetigkeitAendern': !exists(json, 'taetigkeitAendern') ? undefined : json['taetigkeitAendern'],
    };
}

export function TaetigkeitenModelToJSON(value?: TaetigkeitenModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'bezeichnung': value.bezeichnung,
        'beschreibung': value.beschreibung,
        'anmerkungErforderlich': value.anmerkungErforderlich,
        'favorit': value.favorit,
        'file': value.file,
        'contentType': value.contentType,
        'taetigkeitAendern': value.taetigkeitAendern,
    };
}


