import { Responsive } from "semantic-ui-react";

//export enum ResponsiveBreakpoints  {
//  Desktop = (Responsive.onlyComputer.minWidth as number),
//  Tablet = (Responsive.onlyTablet.minWidth as number),
//  Mobile = (Responsive.onlyMobile.minWidth as number),
//  MaxDesktop = (Responsive.onlyComputer.maxWidth as number),
//  MaxTablet = (Responsive.onlyTablet.maxWidth as number),
//  MaxMobile = (Responsive.onlyMobile.maxWidth as number),
///*  MaxTabletAsDesktop = (Responsive.onlyTablet.maxWidth as number),*/
//  TabletAsDesktop = 1200
//}
export enum ResponsiveBreakpoints {
  MinDesktop = 1200,
  MinTabletDesktop = 1000,
  MaxTabletDesktop = 1199,

  MinTablet = (Responsive.onlyTablet.minWidth as number),
  MaxTablet = 999,

  MinMobile = (Responsive.onlyMobile.minWidth as number),
  MaxMobile = (Responsive.onlyMobile.maxWidth as number),
}