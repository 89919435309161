import * as React from "react";

export const MemoField = React.memo(({ component, ...props }: any) => {
  const FieldComponent = component;
  return <FieldComponent {...props} />
}, (prev, next) => {
  // only re-render field if props change
  var propsAreSame = areEqualShallow(prev, next, ["onChange"]); // onChange has different ref every render for some reason
  if (!propsAreSame) {
    //console.log(prev, next);
  }
  return propsAreSame;
});

function areEqualShallow(a: any, b: any, skip?: string[]) {
  for (var key in a) {
    if (skip && skip.includes(key)) {
      continue;
    }
    if (a[key] !== b[key]) {
      //console.log(key, a[key], b[key])
      return false;
    }
  }
  return true;
}

export default MemoField;