import * as React from 'react';
import { Grid, Segment, Button, Icon } from 'semantic-ui-react';
import { AbwesenheitsInfo, TagStempelInfo } from '../../generated';


interface InfoBoxProps {
  benutzername: string;
  tagSaldo?: Date;
  info?: TagStempelInfo;
  anfrageInfo?: AbwesenheitsInfo;
  loading?: boolean;
  isSimpleMode?: boolean;
}

export const InfoBox: React.FC<InfoBoxProps> = (props) => {
  let sonstige = props.info && props.info.sonstigeArbeitsZeit && props.info.sonstigeArbeitsZeit !== "00:00" ? true : false;
  let datum = new Date();
  return (
    <Segment loading={props.loading}>
      {props.benutzername && props.info ?
        <>
          <h3 style={props.isSimpleMode ? { textAlign: "center", paddingBottom: "0px" } : { textAlign: "center" }}>Willkommen, {props.benutzername}</h3>
          {props.isSimpleMode && props.info.einteilungen && props.info.einteilungen.length > 0 &&
            <h4 style={{marginTop: 'unset'}}>Eingeteilt für: </h4>}
          {props.isSimpleMode ?
            <Grid>
              {props.info.einteilungen && props.info.einteilungen.map(pm =>
                <>
                  <Grid.Column style={{paddingTop: 'unset', paddingBottom: 'unset'}} textAlign={'left'} mobile={'10'} computer={'12'}>                    
                    <p><b>{`${pm.projektName}`}</b></p>
                  </Grid.Column>
                  <Grid.Column style={{ paddingTop: 'unset', paddingBottom: 'unset' }} textAlign={'left'} mobile={'6'} computer={'4'}>
                    <p>{`${pm.arbeitszeit}`}</p>
                  </Grid.Column>
                  {pm.projektKontakt &&
                    <Grid.Column style={{ paddingTop: 'unset', paddingBottom: 'unset' }} textAlign={'left'} mobile={'10'} computer={'4'}>
                      <p><b>{`${pm.projektKontakt}`}</b></p>
                    </Grid.Column>
                  }
                  {pm.projektTelefon &&
                    <Grid.Column style={{ paddingTop: 'unset', paddingBottom: 'unset' }} textAlign={'left'} mobile={'6'} computer={'4'}>
                      <p><b>{`${pm.projektTelefon}`}</b></p>
                    </Grid.Column>
                  }
                  {pm.projektAdresse &&
                    <Grid.Column style={{ paddingTop: 'unset', paddingBottom: 'unset' }} textAlign={'left'} mobile={'16'} computer={'12'}>
                      <p><b>{`${pm.projektAdresse}`}</b></p>
                    </Grid.Column>
                  }
                </>)
              }
              </Grid>
          :
            <Grid>
              <Grid.Column textAlign={'left'} mobile={'8'} computer={sonstige ? '5' : '6'}>
              {props.info && props.info.normalArbeizszeit &&
                <p><b>Normalarbeitszeit:&nbsp;&nbsp;&nbsp; {`${props.info.normalArbeizszeit}`}</b></p>
              }
            </Grid.Column>
              <Grid.Column textAlign={'left'} mobile={'8'} computer={sonstige ? '5' : '7'}>
              {props.info && props.info.sollArbeitszeit &&
                  <p><b>Geleistete&nbsp;IST:&nbsp; {`${props.info.istArbeitsZeit}`}&nbsp;von&nbsp;{`${props.info.sollArbeitszeit}`}</b> {props.info.ohnePause && <Button className="buttonTooltip" secondary icon="warning" data-tooltip={props.info.ohnePause} />}</p>
                   
              }
            </Grid.Column>
              {sonstige ? <>              
                <Grid.Column textAlign={'left'} mobile={'8'} computer={'3'}>
                <p><b>Sonstig&nbsp;IST:&nbsp;  +{props!.info!.sonstigeArbeitsZeit}</b></p>
              </Grid.Column>
              </> : <></>
              }
              <Grid.Column textAlign={'left'} mobile={'8'} computer={sonstige ? '3' : '3'}>
              {props.info && props.info.tagesSaldo &&
                <p><b>Tagessaldo:&nbsp;{`${props.info.tagesSaldo}`}</b></p>
              }
              </Grid.Column>
          </Grid>
          }
          </>
        : props.anfrageInfo ?
          <>
            <h3 style={{ textAlign: "center" }}>Abwesenheit für {datum.getFullYear()}</h3>
            <Grid>
              <Grid.Column mobile={'4'} computer={'5'}>
                <p className="center"><b>{`Resturlaub: ${props.anfrageInfo.urlaubstageIST}`}</b></p>
              </Grid.Column>
              <Grid.Column mobile={'6'} computer={'6'}>
                <p className="center"><b>{`Urlaub verbraucht: ${props.anfrageInfo.urlaubstageVerbraucht}`}</b></p>
              </Grid.Column>
              <Grid.Column mobile={'6'} computer={'5'}>
                <p className="center"><b>{`Stunden verfügbar: ${props.anfrageInfo.stundenSaldoIST}`}</b></p>
              </Grid.Column>
            </Grid>
            </>
            : <div style={{ textAlign: "center" }}> <h3>Kein Benutzer vorhanden!</h3>
              <p>Bitte wenden Sie sich an die Verwaltung um den Benutzer anzulegen.</p></div>
          }
    </Segment>
  )
}