import * as React from 'react';
import { Header, Table, TextArea, Button, Popup, Icon, Grid } from 'semantic-ui-react';
import { Field, FieldProps } from 'formik';
import { ProjektKontierungModified } from '../generated/models/ProjektKontierungModified';
import { KontierungInput } from './Kontierung/KontierungInput';
import { TaetigkeitenModel } from '../generated/models/TaetigkeitenModel';
import { TagStempel } from '../generated/models/TagStempel';

interface ProjektRowProps {
  projekt: ProjektKontierungModified;
  projekte: ProjektKontierungModified[];
  index: number;
  restZeit: string;
  tagesSaldo: number;
  datum: Date;
  vonDatum: Date | null | undefined;
  bisDatum: Date | null | undefined;
  isReadOnly: boolean;
  rows: number;
  rowsUsed: number;
  setValues: (values: React.SetStateAction<TagStempel>, shouldValidate?: boolean) => void;
  setfieldvalue: (field: string, value: any, shouldValidate?: boolean) => void;
}

export const ProjektRow: React.FC<ProjektRowProps> = (props) => {
  let buttonCount = 1; // break buttons after 3
  async function setChanged(refreshCount: number, projekt: TaetigkeitenModel) {
   //Alte tätigkeit die markiert war und wieder auf original setzen
    let oldPT = props!.projekt!.projektTaetigkeiten!.find(f => f.taetigkeitAendern);
    if (oldPT !== undefined) {
      props.setValues((prev) => {
        prev.zeitstempel!.projektKontierungen![props.index]!.projektTaetigkeiten!.find(f => f === oldPT)!.taetigkeitAendern = false;
        prev.zeitstempel!.projektKontierungen![props.index]!.taetigkeitAnmerkungErforderlich = props.projekt.anmerkungErforderlich;
        prev.zeitstempel!.projektKontierungen![props.index]!.taetigkeitFavorit = props.projekte[props.index]!.favorit;
        return prev;
      });
    }
    if (projekt !== undefined && oldPT !== projekt) {
      props.setValues((prev) => {
        prev.zeitstempel!.projektKontierungen![props.index]!.projektTaetigkeiten!.find(f => f === projekt)!.taetigkeitAendern = true;
        prev.zeitstempel!.projektKontierungen![props.index]!.taetigkeitAnmerkungErforderlich = projekt.anmerkungErforderlich ? true : false;
        prev.zeitstempel!.projektKontierungen![props.index]!.taetigkeitFavorit = projekt.favorit;
        return prev;
      });
    }
    else if (projekt !== undefined && oldPT === projekt) {
      props.setValues((prev) => {
        prev.zeitstempel!.projektKontierungen![props.index]!.taetigkeitAnmerkungErforderlich = props.projekt.anmerkungErforderlich;
        prev.zeitstempel!.projektKontierungen![props.index]!.taetigkeitFavorit = props.projekt.favorit;
        return prev;
      });
    }
    let name = `zeitstempel.projektKontierungen[${props.index}].taetigkeiten[${refreshCount}].taetigkeitAendern`;
    props.setfieldvalue(name, true);
  };
  function copyRow(projektRow: ProjektKontierungModified) {
    let newTaetigkeiten: TaetigkeitenModel[] = []
    if (projektRow.projektTaetigkeiten) {
      projektRow.projektTaetigkeiten.forEach((pt) => {
        let newTaetigkeit: TaetigkeitenModel =
        {
          id: pt.id,
          bezeichnung: pt.bezeichnung,
          beschreibung: pt.beschreibung,
          anmerkungErforderlich: pt.anmerkungErforderlich,
          contentType: pt.contentType,
          favorit: pt.favorit,
          file: pt.file,
          taetigkeitAendern: pt.taetigkeitAendern         
        };
        newTaetigkeiten.push(newTaetigkeit);
      });
    }
    let newRow: ProjektKontierungModified =
    {
      anmerkung: undefined,
      anmerkungErforderlich: projektRow.anmerkungErforderlich,
      anzahlMinuten: 0,
      contentType: projektRow.contentType,
      favorit: projektRow.favorit,
      file: projektRow.file,
      id: undefined,
      mitarbeiterID: projektRow.mitarbeiterID,
      projektBeschreibung: projektRow.projektBeschreibung,
      projektID: projektRow.projektID,
      projektname: projektRow.projektname,
      projektTaetigkeiten: newTaetigkeiten,
      taetigkeitBeschreibung: projektRow.taetigkeitBeschreibung,
      taetigkeitID: projektRow.taetigkeitID,
      taetigkeitName: projektRow.taetigkeitName,
      datum: projektRow.datum,
      taetigkeitAnmerkungErforderlich: projektRow.taetigkeitAnmerkungErforderlich,
      taetigkeitFavorit: projektRow.taetigkeitFavorit
    };
    props.setValues((prev) => {
      prev.zeitstempel!.projektKontierungen!.push(newRow);
      return prev;
    });
  };

  return (
    <Table.Row style={{ backgroundColor: (props.index % 2) ? 'lightgrey' : 'white' }} >
      {props.projekt && props.projekt.projektBeschreibung && props.projekt.projektBeschreibung !== null && props.projekt.projektBeschreibung !== undefined  ?
       <Popup
          content={() => { return (props.projekt.projektBeschreibung) }}
          trigger={
            <Table.Cell width='2'>
              <Header size="tiny">
                {props.projekt.projektname}
              </Header>
            </Table.Cell>}
        />
        : 
        <Table.Cell width='2'>
          <Header size="tiny">
            {props.projekt.projektname}
          </Header>
          </Table.Cell>
      }
      <Table.Cell width='1' className={'nowrap'}>
        <Popup
          content={() => {
            return (<div>
              {props.projekt.taetigkeitName && props.projekt.taetigkeitBeschreibung ?
                <div> {props.projekt.taetigkeitName} <br /> {props.projekt.taetigkeitBeschreibung}</div> :
                <div>{props.projekt.taetigkeitName ? props.projekt.taetigkeitName : 'Allgemein'}</div>}
            </div>)
          }}
          trigger={
            props.projekt.file && props.projekt.file.length > 0 ?
              <Button
                content={(<img
                  alt={props.projekt.taetigkeitName ? props.projekt.taetigkeitName : 'Tätigkeit' }
                  className={'taetigkeiticon'}
                  src={`data:${props.projekt.contentType};base64,${props.projekt.file}`}
                />
                )}
                className={props.projekt.projektTaetigkeiten &&
                  props.projekt.projektTaetigkeiten.find(f => f.taetigkeitAendern) ? 'taetigkeiticonbutton'
                  : 'taetigkeiticonbutton taetigkeitchosenrow'}
                size={'mini'}
                type={'button'}
                key={`${props.index}-${props.projekt.id}-${props.projekt.taetigkeitID}`}
                name={`${props.index}-${props.projekt.id}-${props.projekt.taetigkeitID}`}
                secondary
                onClick={() => {
                  let projekt = props!.projekte[props.index]!.projektTaetigkeiten
                    ? props!.projekte[props.index]!.projektTaetigkeiten!.find(f => f.taetigkeitAendern)
                    : undefined;
                  if (projekt !== undefined) {
                    setChanged(props.index, projekt);
                  }
                }}
              />
              :
              props.projekt.taetigkeitName
                ?
                <Button
                  content={props.projekt.taetigkeitName.slice(0, 3)}
                  className={props.projekt.projektTaetigkeiten &&
                    props.projekt.projektTaetigkeiten.find(f => f.taetigkeitAendern) ? 'taetigkeiticonbutton'
                    : 'taetigkeiticonbutton taetigkeitchosenrow'}
                  size={'mini'}
                  type={'button'}
                  key={`${props.index}-${props.projekt.id}-${props.projekt.taetigkeitID}`}
                  name={`${props.index}-${props.projekt.id}-${props.projekt.taetigkeitID}`}
                  secondary
                  onClick={() => {
                    let projekt = props!.projekte[props.index]!.projektTaetigkeiten
                      ? props!.projekte[props.index]!.projektTaetigkeiten!.find(f => f.taetigkeitAendern)
                      : undefined;
                    if (projekt !== undefined) {
                      setChanged(props.index, projekt);
                    }
                  }}
                />
                :
                <Button
                  content={(<Icon
                    className={'taetigkeiticon no-file-icon'}
                    name="world"
                  />
                  )}
                  className={props.projekt.projektTaetigkeiten &&
                    props.projekt.projektTaetigkeiten.find(f => f.taetigkeitAendern) ? 'taetigkeiticonbutton' 
                    : 'taetigkeiticonbutton taetigkeitchosenrow'}
                  size={'mini'}
                  type={'button'}
                  key={`${props.index}-${props.projekt.id}-${props.projekt.taetigkeitID}`}
                  name={`${props.index}-${props.projekt.id}-${props.projekt.taetigkeitID}`}
                  secondary
                  onClick={() => {
                    let projekt = props!.projekte[props.index]!.projektTaetigkeiten
                      ? props!.projekte[props.index]!.projektTaetigkeiten!.find(f => f.taetigkeitAendern)
                      : undefined;
                    if (projekt !== undefined) {
                     setChanged(props.index, projekt);
                    }
                  }}
                />
          }
        />
        {props.projekt.projektTaetigkeiten && props.projekt.projektTaetigkeiten.map((pt) => {
          if (buttonCount === 4) {
            buttonCount = 2;
          }
          else {
            buttonCount += 1;
          }
          return (<React.Fragment key={pt.id}>
            {(buttonCount === 4) ? <br /> : ''}
            <Popup
              content={() => {
                return (<div>
                  {pt.beschreibung && pt.bezeichnung ?
                    <div> {pt.bezeichnung} <br /> {pt.beschreibung}</div> :
                    <div>{pt.bezeichnung ? pt.bezeichnung : 'Allgemein'}</div>}
                </div>)
              }}
              trigger={
                pt.file && pt.file.length > 0 ?
                  <Button
                    content={(<img
                      alt={pt.bezeichnung ? pt.bezeichnung : 'Tätigkeit'}
                      className={'taetigkeiticon'}
                      src={`data:${pt.contentType};base64,${pt.file}`}
                    />
                    )}
                    className={pt.taetigkeitAendern ? 'taetigkeiticonbutton taetigkeitchosenrow' : 'taetigkeiticonbutton'}
                    size={'mini'}
                    type={'button'}
                    key={`${props.index}-${props.projekt.id}-${pt.id}`}
                    name={`${props.index}-${props.projekt.id}-${pt.id}`}
                    secondary
                    onClick={() => {
                      setChanged(props.index, pt);
                    }}
                  />
                  :
                  pt.bezeichnung
                    ?
                    <Button
                      content={pt.bezeichnung.slice(0, 3)}
                      className={pt.taetigkeitAendern ? 'taetigkeiticonbutton taetigkeitchosenrow' : 'taetigkeiticonbutton'}
                      size={'mini'}
                      type={'button'}
                      key={`${props.index}-${props.projekt.id}-${pt.id}`}
                      name={`${props.index}-${props.projekt.id}-${pt.id}`}
                      secondary
                      onClick={() => {
                        setChanged(props.index, pt);
                      }}
                    />
                  :
                  <Button
                    content={(<Icon
                      className={'taetigkeiticon no-file-icon'}
                      name="world"
                    />)}
                      className={pt.taetigkeitAendern ? 'taetigkeiticonbutton taetigkeitchosenrow' : 'taetigkeiticonbutton'}
                    size={'mini'}
                    type={'button'}
                      key={`${props.index}-${props.projekt.id}-${pt.id}`}
                      name={`${props.index}-${props.projekt.id}-${pt.id}`}
                    secondary
                      onClick={() => {
                        setChanged(props.index, pt);
                    }}
                  />
            } />
          </React.Fragment>)}
        )}
        </Table.Cell>
      <Field>
        {(field: FieldProps) => {
          const name = `zeitstempel.projektKontierungen[${props.index}].anmerkung`;
          //`zeitstempel.stempelRow[${props.index}].${props.kommenGehen}.stempel`
          return (
            <Table.Cell width='12'>
              <TextArea
                style={(((props.projekt.anmerkungErforderlich !== undefined && props.projekt.anmerkungErforderlich
                  && props.projekt.taetigkeitAnmerkungErforderlich !== undefined && props.projekt.taetigkeitAnmerkungErforderlich)
                  || (props.projekt.anmerkungErforderlich !== undefined && props.projekt.anmerkungErforderlich === false
                    && props.projekt.taetigkeitAnmerkungErforderlich !== undefined && props.projekt.taetigkeitAnmerkungErforderlich === true)
                  || (props.projekt.taetigkeitAnmerkungErforderlich !== undefined && props.projekt.taetigkeitAnmerkungErforderlich)
                  || ((props.projekt.anmerkungErforderlich !== undefined && props.projekt.anmerkungErforderlich)
                  && props.projekt.projektTaetigkeiten && props.projekt.projektTaetigkeiten.find(f => f.taetigkeitAendern) === undefined))
                  && ((props.projekt.anmerkung === "") && (props.projekt.anzahlMinuten && (props.projekt.anzahlMinuten !== 0)
                    && (props.projekt.anzahlMinuten && (props.projekt.anzahlMinuten.toString() !== '00:00'))
                    && (props.projekt.anzahlMinuten && (props.projekt.anzahlMinuten.toString() !== '0'))
                  ))) ? { border: "solid 2px red" } : {}}
                name={name}
                fluid="true"
                readOnly={props.isReadOnly}
                placeholder={props.isReadOnly ? "" : "Anmerkung"}
                type="text"
                rows={"2"}
                value={props.projekt.anmerkung ?? ''}
                onChange={(e, data) => {
                  props.setfieldvalue(name, data.value);
                }}
                onBlur={() => {
                  props.setfieldvalue(name, props.projekt.anmerkung);
                }}
              />
            </Table.Cell>
          )
        }}
      </Field>
      <Field>
        {(field: FieldProps) => {
          const name = `zeitstempel.projektKontierungen[${props.index}].anzahlMinuten`;
          const fav = `${props.index}.${props.projekt.projektID}.${props.projekt.taetigkeitID}.favorit`;
          return (
            <Table.Cell width='3'>
              <div className='kontierung-block' style={{paddingBottom: '0.4em'}}>
                <KontierungInput datum={props.datum} projekt={props.projekt} fieldName={name} isReadOnly={props.isReadOnly} restZeit={props.restZeit}
                  index={props.index} projekte={props.projekte} vonDatum={props.vonDatum} bisDatum={props.bisDatum} setfieldvalue={props.setfieldvalue}
                  tagesSaldo={props.tagesSaldo} rows={props.rows} rowsUsed={props.rowsUsed}/>
                {props.isReadOnly ? <></> :
                    <Icon
                    className={(props.projekt.taetigkeitFavorit ? true
                      : props.projekt.projektTaetigkeiten && props.projekt.projektTaetigkeiten.find(f => f.taetigkeitAendern)
                        ? props.projekt.projektTaetigkeiten!.find(f => f.taetigkeitAendern)!.favorit :
                        props.projekt.favorit ? true : false) ? "row-icon full" : "row-icon"}
                    size='large'
                    name={(props.projekt.taetigkeitFavorit ? true
                      : props.projekt.projektTaetigkeiten && props.projekt.projektTaetigkeiten.find(f => f.taetigkeitAendern)
                        ? props.projekt.projektTaetigkeiten!.find(f => f.taetigkeitAendern)!.favorit :
                        props.projekt.favorit ? true : false) ? 'star' : 'star outline'}
                    onClick={() => {
                      let favChange = !(props.projekt.taetigkeitFavorit ? true
                        : props.projekt.projektTaetigkeiten && props.projekt.projektTaetigkeiten.find(f => f.taetigkeitAendern)
                          ? props.projekt.projektTaetigkeiten!.find(f => f.taetigkeitAendern)!.favorit :
                          props.projekt.favorit ? true : false);

                      //Schauen ob diese Row eine veränderte Tätigkeit hat...
                      let taetigkeit : TaetigkeitenModel | null | undefined = props.projekt.projektTaetigkeiten && props.projekt.projektTaetigkeiten.find(f => f.taetigkeitAendern);
                      //Wenn es eine veränderte Tätigkeit gibt müssen die Werte des Projekts geändert werden um die Darstellung richtig abzufragen.
                      if (taetigkeit !== null && taetigkeit !== undefined) {

                        props.projekt.projektTaetigkeiten!.find(f => f === taetigkeit)!.favorit = favChange;
                        props.projekte[props.index]!.projektTaetigkeiten!.find(f => f === taetigkeit)!.favorit = favChange;

                        props.projekt.taetigkeitFavorit = favChange;
                        props.projekte[props.index].taetigkeitFavorit = favChange;

                        let newFav = `${props.index}.${props.projekt.projektID}.${taetigkeit.id}.favorit`;
                        props.setfieldvalue(newFav, favChange);

                        //danach müssen alle Favoriten richtig gesetzt werden wo die haupttätigkeit gleich der gewählten tätigkeit ist.
                        props.projekte.map(pr => {
                          let index = props.projekte.findIndex(i => (i.projektID === props.projekt.projektID) && (i.taetigkeitID === taetigkeit!.id)
                            && i.favorit !== favChange && i.projektTaetigkeiten && !i.projektTaetigkeiten.find(f => f.taetigkeitAendern));

                          if (index !== -1 && index !== props.index) {
                            let duplFav = `${index}.${props.projekt.projektID}.${props.projekt.taetigkeitID}.favorit`;

                            props.projekte[index].favorit = favChange;

                            props.projekte[index].taetigkeitFavorit = favChange;

                            props.setfieldvalue(duplFav, favChange);
                          }

                          //danach müssen alle Favoriten richtig gesetzt werden wo die gewählte tätigkeit gleich der gewählten tätigkeit jedes anderen projektes ist.
                          let changedIndex = props.projekte.findIndex(i => (i.projektID === props.projekt.projektID) && (i.taetigkeitID !== taetigkeit!.id)
                            && i.projektTaetigkeiten!.find(f => (f.id === taetigkeit!.id) && (f.favorit !== favChange)  && (f.taetigkeitAendern)));

                          if (changedIndex !== -1 && changedIndex !== props.index) {

                            let duplFav = `${changedIndex}.${props.projekt.projektID}.${props.projekt.taetigkeitID}.favorit`;

                            props.projekte[changedIndex].projektTaetigkeiten!.find(f => f.id === taetigkeit!.id)!.favorit = favChange;
                            props.projekte[changedIndex].taetigkeitFavorit = favChange;

                            props.setfieldvalue(duplFav, favChange);
                          }
                          return pr; //Ohne return wird ein fehler ausgegeben in console/warnings
                        });
                      }                    
                        else {
                          props.projekt.favorit = favChange;
                          props.projekte[props.index].favorit = favChange;
                          props.projekt.taetigkeitFavorit = false;
                          props.projekte[props.index].taetigkeitFavorit = false;
                        props.setfieldvalue(fav, favChange);

                        props.projekte.map(pr => {
                          //Nur Favoriten von anderen Haupttätigkeiten ändern
                            let index = props.projekte.findIndex(i => (i.projektID === props.projekt.projektID) && (i.taetigkeitID === props.projekt.taetigkeitID)
                              && i.favorit !== favChange && i.projektTaetigkeiten
                              && i.projektTaetigkeiten.findIndex(f => f.taetigkeitAendern) < 0);

                            if (index !== -1 && index !== props.index) {
                              let duplFav = `${index}.${props.projekt.projektID}.${props.projekt.taetigkeitID}.favorit`;
                              props.projekte[index].favorit = favChange;
                              props.projekte[index].taetigkeitFavorit = false;
                              props.setfieldvalue(duplFav, favChange);
                          }
                          //Favoriten ändern wo changed Tätigkeite == der Haupttätigkeit ist.
                          let changedIndex = props.projekte.findIndex(i => (i.projektID === props.projekt.projektID) && (i.taetigkeitID !== props.projekt.taetigkeitID)
                            && i.projektTaetigkeiten!.find(f => (f.id === props.projekt.taetigkeitID) && (f.favorit !== favChange) && (f.taetigkeitAendern)));

                          if (changedIndex !== -1 && changedIndex !== props.index) {

                            let duplFav = `${changedIndex}.${props.projekt.projektID}.${props.projekt.taetigkeitID}.favorit`;

                            props.projekte[changedIndex].projektTaetigkeiten!.find(f => f.id === props.projekt.taetigkeitID)!.favorit = favChange;
                            props.projekte[changedIndex].taetigkeitFavorit = favChange;

                            props.setfieldvalue(duplFav, favChange);
                          }
                          return pr; //Ohne return wird ein fehler ausgegeben in console/warnings
                          });
                        } 
                    }}
                    />
                }
                </div>
            </Table.Cell>
          )
        }}
      </Field>
      <Field>
        {(field: FieldProps) => {
          const name = `zeitstempel.projektKontierungen[${props.index}].anzahlMinuten`;
          const fieldAnmerkung = `zeitstempel.projektKontierungen[${props.index}].anmerkung`;
          return (
          <Table.Cell width="1" className='nowrap'>
              {!props.isReadOnly && <>
                <Grid>
                  <Grid.Column computer="16" mobile="8" tablet="16" textAlign="left" style={{ paddingBottom: '0.1em', paddingTop: '0.4em' }}>
                <Button
                  secondary
                  content={<Icon name="add" className="taetigkeiticon no-file-icon"/>}
                  className={"taetigkeiticonbutton"}
                      data-tooltip={"Projekt duplizieren"}                      
                      size={"mini"}
                  type={'button'}
                      onClick={() => {
                    copyRow(props.projekt);
                  }}
                    />
                  </Grid.Column>
                  <Grid.Column computer="16" mobile="8" tablet="16" textAlign="right" style={{paddingBottom: '0.1em', paddingTop: '0.4em'}}>
                <Button
                  content={<Icon name="trash" className="taetigkeiticon no-file-icon" />}
                  color={"red"}
                  className={"taetigkeiticonbutton"}
                      data-tooltip={"Zeile leeren"}
                  size={"mini"}
                  type={'button'}
                      onClick={() => {
                        props.setfieldvalue(name, 0);
                        props.setfieldvalue(fieldAnmerkung, "");
              }}
                    />
                    </Grid.Column>
                  </Grid>
                </>}
          </Table.Cell>
        )}}
      </Field>
    </Table.Row>
  )
}
