/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ELookup,
    ELookupFromJSON,
    ELookupFromJSONTyped,
    ELookupToJSON,
} from './';

/**
 * Model: Arbeitstag eines Mitarbeiters
 * @export
 * @interface AbwesenheitsModel
 */
export interface AbwesenheitsModel {
    /**
     * Nachname
     * @type {string}
     * @memberof AbwesenheitsModel
     */
    nachname?: string | null;
    /**
     * Vorname
     * @type {string}
     * @memberof AbwesenheitsModel
     */
    vorname?: string | null;
    /**
     * Personalnummer
     * @type {string}
     * @memberof AbwesenheitsModel
     */
    personalNr?: string | null;
    /**
     * Mitarbeiter war Anwesend / Abwesend
     * @type {Array<string>}
     * @memberof AbwesenheitsModel
     */
    codes?: Array<string> | null;
    /**
     * Gesetzte Tagesstempel pro Wochentag
     * @type {Array<Array<ELookup>>}
     * @memberof AbwesenheitsModel
     */
    tagesstempel?: Array<Array<ELookup>> | null;
}

export function AbwesenheitsModelFromJSON(json: any): AbwesenheitsModel {
    return AbwesenheitsModelFromJSONTyped(json, false);
}

export function AbwesenheitsModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AbwesenheitsModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nachname': !exists(json, 'nachname') ? undefined : json['nachname'],
        'vorname': !exists(json, 'vorname') ? undefined : json['vorname'],
        'personalNr': !exists(json, 'personalNr') ? undefined : json['personalNr'],
        'codes': !exists(json, 'codes') ? undefined : json['codes'],
        'tagesstempel': !exists(json, 'tagesstempel') ? undefined : json['tagesstempel'],
    };
}

export function AbwesenheitsModelToJSON(value?: AbwesenheitsModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nachname': value.nachname,
        'vorname': value.vorname,
        'personalNr': value.personalNr,
        'codes': value.codes,
        'tagesstempel': value.tagesstempel,
    };
}


