import * as React from "react"
import { Segment, Container, List } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { MitarbeiterHauptlinks } from "../handbuch/util/links";

interface FooterProps {

}

export const Footer: React.FC<FooterProps> = () => {
  return (
    <Segment inverted vertical className="footer hide-print">
      <Container textAlign="left">
        <div style={{ display: 'inline-block'}}>
          <img src="/Logo.png" style={{ height: "4.55em", marginBottom: "0.5em" }} alt="cool timeWatch Logo"/>
        </div>
      </Container>
      <Container textAlign="left" style={{ paddingLeft: 'unset' }}>
        <div className='nowrap' style={{ display: 'inline-block', marginTop: '1.2em', paddingLeft: 'unset' }}>
          <h2>Keep <b className='slogan'>cool</b> and <b className='slogan'>watch</b> your <b className='slogan'>time</b>.</h2>
        </div>
        </Container>
      <Container textAlign="left" style={{ paddingLeft: 'unset' }}>
        <List horizontal inverted divided link size="small">
          <List.Item style={{ paddingLeft: 'unset' }}>
            © cool IT GmbH 2021 - 2024
            </List.Item>
          <List.Item as={Link} to="/info">
            Info
          </List.Item>
          <List.Item as={Link} to={MitarbeiterHauptlinks.index.path} target="blank">
            Hilfe
          </List.Item>
        </List>
      </Container>
    </Segment>
  )
}
