import * as React from "react";
import { TimePicker } from "../TimePicker"
import { dateToStringTime, extendStringTimeToDate, stringTimeToDate, stringToDateString, validateTimestring } from "../Helper";
import { Button, Dropdown, DropdownItemProps, Grid, Icon, Input, Popup } from "semantic-ui-react";
import { Field, FieldProps } from "formik";
import { EAZTyp, } from "../../generated/models/EAZTyp";
import { ZeitstempelModel } from "../../generated/models/ZeitstempelModel";
import { SonstigeTypenModel } from "../../generated";

interface IZeitStempelProps {
  sonstigeDropdown?: SonstigeTypenModel[] | null | undefined;
  zeitstempel: ZeitstempelModel;
  kommenGehen: string;
  eazTyp: EAZTyp;
  rows: number;
  indexKey: string;
  index: number;
  edit: boolean;
  kommen: boolean;
  gehen: boolean;
  datum: Date;
  setfieldvalue: (field:string, value:any, shouldValidate?: boolean) => void;
}

export const ZeitStempel: React.FC<IZeitStempelProps> = (props) => {
  let sonstigeOptions: DropdownItemProps[] = [];
  let lastIndex = props.sonstigeDropdown ? (props.sonstigeDropdown.length - 1) : 1;
  if (props.sonstigeDropdown && props.sonstigeDropdown.length > 1) {
    props.sonstigeDropdown.map((m, index) =>
      sonstigeOptions.push({ key: index, text: m.text, value: m.value! }));
  }
  else {
    sonstigeOptions.push({ key: 1, text: 'Normalarbeitszeit', value: '###' });
    sonstigeOptions.push({ key: 5, text: 'Sonstig', value: '|||' });
  }

  const [typChange, SetTypChange] = React.useState<number>(0); //Aktualisierung für changeTyp
  function changeTyp(stempel: ZeitstempelModel, gehen: boolean, kommen: boolean, value: string) {
    let reloading = typChange + 1;
    if (gehen) {
      if (stempel.typ!.includes(EAZTyp.Sonstig) && value === '###') {
        stempel.typ! = EAZTyp.Gehen;
        stempel.info! = '';
      }
      else {
        stempel.typ! = EAZTyp.Sonstig;
        stempel.info! = '';
      }
    }
    if (kommen) {
      if (stempel.typ!.includes(EAZTyp.Sonstig) && value === '###') {
        stempel.typ = EAZTyp.Kommen;
        stempel.info = '';
      }
      else {
        stempel.typ! = EAZTyp.Sonstig;
        stempel.info! = '';
      }
    }
    SetTypChange(reloading);
  }
  let readonly = props.edit;
  let hasError = (props.zeitstempel && props.zeitstempel.fehler && props.zeitstempel.fehler.length >= 0) ? true : false;
  return (
    <>
    <Grid key={`${props.indexKey}grid`}>
        <Grid.Row key={`${props.indexKey}row`} className='stempel-nopadding-bottom'>
        <Grid.Column key={`${props.indexKey}columnone`} mobile="16" tablet="8" computer="4">
            <div key={`${props.indexKey}divone`} style={{ float: 'left' }} className={"timeinput-block"}>
              <Field key={`${props.indexKey}fieldone`} disabled={readonly}>
                {(field: FieldProps) => {
                  return (
                    <Button
                      key={`${props.indexKey}button`}
                      content={props.eazTyp}
                      size={'mini'}
                      type={'button'}
                      className={"btn-grey"}
                      onClick={() => {
                        var kDate = new Date();
                        if (!readonly) {
                          props.setfieldvalue(`zeitstempel.stempelRow[${props.index}].${props.kommenGehen}.stempel`, kDate);
                        }
                      }}
                    />
                  )
                }}
              </Field>
            </div>
            <Field key={`${props.indexKey}fieldtwo`} disabled={readonly}>
            {() => {
              var value = stringToDateString(props.zeitstempel && props.zeitstempel.stempel, props.datum);
                var name = `zeitstempel.stempelRow[${props.index}].${props.kommenGehen}.stempel`;
                var validate = validateTimestring(value);
              var timeValue = dateToStringTime(
                props.zeitstempel && props.zeitstempel.stempel);
              return (
                <div key={`${props.indexKey}divPicker`} className={(hasError || !validate) ? "timeinput-block errorBorder" : "timeinput-block"}>
                  <TimePicker
                    key={`${props.indexKey}timepicker`}
                      disabled={readonly}
                      clearable={!readonly}
                    setfieldvalue={props.setfieldvalue}
                    onChange={(e, data) => props.setfieldvalue}
                    name={`zeitstempel.stempelRow[${props.index}].${props.kommenGehen}.stempel`}
                      date={props.datum}
                      value={timeValue}
                    />
                  <Input
                    key={`${props.indexKey}pickerinput`}
                    readOnly={readonly}
                    className={"noLeftBorder left-right-padding fill-up"}
                    name={`zeitstempel.stempelRow[${props.index}].${props.kommenGehen}.stempel`}
                    type="text"
                    placeholder="hh:mm"
                    value={value}
                    onBlur={
                      () => {
                        if (typeof value === 'string' && value.length > 0) {
                          var stringValue = extendStringTimeToDate(value);
                          validate = validateTimestring(stringValue);
                          if (validate) {
                            props.setfieldvalue(name, stringTimeToDate(stringValue, props.datum));
                          }
                        }
                      }}
                    onChange={(e, data) => {
                      var value = data.value;
                      if (value.length === 6) {
                        value = data.value.substring(0, 5);
                      }
                      validate = validateTimestring(value);
                      if (!validate) {
                        props.setfieldvalue(data.name, value);
                      }
                      else if (!data.value) {
                        props.setfieldvalue(data.name, null);
                      }
                      else {
                        props.setfieldvalue(data.name, stringTimeToDate(value, props.datum));
                      }
                    }}
                    />
               {!validate && value && value.length >= 5 ?
                    <div key={`${props.indexKey}divpopup`}>
                     <Popup
                        content={() => { return (<div>Bitte überprüfen Sie ihre Zeiteingabe. (zb.: 12:35)</div>) }}
                        key={`${props.kommenGehen}von.stempel.${props.indexKey}one`}
                        trigger={
                          <Icon
                            style={{ marginLeft: '0.2em' }}
                            name={!validate ? 'info circle' : undefined}>
                          </Icon>
                        }
                      />
                    </div> :
                    hasError ?
                      <div key={`${props.indexKey}error`}>
                          <Popup
                            content={() => { return (<div>{props.zeitstempel && props.zeitstempel.fehler}</div>) }}
                            key={`${props.kommenGehen}.stempel.${props.indexKey}`} 
                            trigger={
                            <Icon
                              style={{ marginLeft: '0.2em' }}
                              name={'info circle'}>
                            </Icon>
                          }
                        />
                      </div> : <div key={`${props.indexKey}divPickerelse`}> </div>
                    } 
                    </div>)
            }}
            </Field>
          </Grid.Column>
          <Grid.Column key={`${props.indexKey}columntwo`} mobile="16" tablet="8" computer="4">
          {readonly ?
              <Input
                key={`${props.indexKey}inputwo`}
              className={"fill-up"}
              value={sonstigeOptions.find(f => f.value === (props.zeitstempel && props.zeitstempel.info)) &&
                sonstigeOptions.find(f => f.value === (props.zeitstempel && props.zeitstempel.info))?.value ?
                sonstigeOptions.find(f => f.value === (props.zeitstempel && props.zeitstempel.info))?.value :
                props.zeitstempel && props.zeitstempel.typ!.includes(EAZTyp.Sonstig) ? sonstigeOptions[lastIndex].text :
                  sonstigeOptions[0].text}
              readOnly={readonly} />
            :
              <Dropdown
                key={`${props.indexKey}dd`}
              disabled={readonly}
              className={"fill-up"}
                name={`zeitstempel.stempelRow[${props.index}].${props.kommenGehen}.typ`}
              options={sonstigeOptions}
              value={sonstigeOptions.find(f => f.value === (props.zeitstempel && props.zeitstempel.info)) &&
                sonstigeOptions.find(f => f.value === (props.zeitstempel && props.zeitstempel.info))?.value ?
                sonstigeOptions.find(f => f.value === (props.zeitstempel && props.zeitstempel.info))?.value :
                props.zeitstempel && props.zeitstempel.typ!.includes(EAZTyp.Sonstig) ? sonstigeOptions[lastIndex].value :
                  sonstigeOptions[0].value}
              selection
              onChange={(event, { value }) => {
                value === '###' ? props.setfieldvalue(`zeitstempel.stempelRow[${props.index}].${props.kommenGehen}.info`, '') :
                  value === '|||' ? props.setfieldvalue(`zeitstempel.stempelRow[${props.index}].${props.kommenGehen}.info`, '') :
                    props.setfieldvalue(`zeitstempel.stempelRow[${props.index}].${props.kommenGehen}.info`, value);
                changeTyp(props.zeitstempel, false, true, value as string); // change function
                props.setfieldvalue(`zeitstempel.stempelRow[${props.index}].${props.kommenGehen}.typ`, props.zeitstempel.typ!);
              }}
            />
          }
          </Grid.Column>
          <Grid.Column key={`${props.indexKey}columnthree`} mobile="16" tablet="16" computer="8">
          <Field key={`${props.indexKey}fieldthree`}>
              {(field: FieldProps) => {
                let anmerkungErforderlich = ((hasError && props.zeitstempel.fehler && (props.zeitstempel.fehler.includes('Kernzeit'))) ||
                  (props.zeitstempel.typ!.includes('Sonstig') && ((props.zeitstempel.info === null) || (props.zeitstempel.info === undefined) || (props.zeitstempel.info.length <= 0)))) ? true : false;
              return <div key={`${props.indexKey}divanmerkung`} className={anmerkungErforderlich ? 'errorBorder kontierung-block' : 'kontierung-block'}>
                  <Input
                    key={`${props.indexKey}inputthree`}
                className={'fill-up'}
                    name={`zeitstempel.stempelRow[${props.index}].${props.kommenGehen}.info`}
                placeholder={`Info`}
                type="text"
                readOnly={readonly || (sonstigeOptions.find(f => f.value === (props.zeitstempel && props.zeitstempel.info) && f.value !== '###' && f.value !== '|||'))}
                value={props.zeitstempel && props.zeitstempel.info ? props.zeitstempel.info : ""}
                    onChange={(e, data) => {
                      props.setfieldvalue(data.name, data.value);
                }}
                />
                  {anmerkungErforderlich ?
                                       <Popup
                        content={() => { return (<div>Eine Begründung ist erforderlich.</div>) }}
                        key={`${props.kommenGehen}von.stempel.${props.indexKey}two`}
                        trigger={
                          <Icon
                            style={{ marginLeft: '0.2em', marginBottom: '0.3em' }}
                            name={'info circle'}>
                          </Icon>
                        }
                    />
                  : <div key={`${props.indexKey}divanmerkungerror`}></div>}
                  </div>
            }}
          </Field>
            </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};
