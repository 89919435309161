import * as React from "react";
import { Component } from "react";
import { Route } from "react-router-dom";
import AuthService from "./authService";

const AuthContext = React.createContext({
  signinRedirectCallback: () => ({}),
  logout: () => ({}),
  signoutRedirectCallback: () => ({}),
  isAuthenticated: () => ({}),
  signinRedirect: () => ({}),
  signinSilentCallback: () => ({}),
  createSigninRequest: () => ({}),
  getUser: () => ({})
});

export const AuthConsumer = AuthContext.Consumer;

export class AuthProvider extends Component {
  authService;
  constructor(props: any) {
    super(props);
    this.authService = new AuthService();
  }
    render() {
      return <AuthContext.Provider value={ this.authService as any }> { this.props.children } </AuthContext.Provider>;
    }
};

export const useAuth = () => React.useContext(AuthContext);

export const AuthenticatedRoute = ({ component, ...rest }: any) => {
  const renderFn = (Component: any) => (props: any) => (
    <AuthConsumer>
      {({ isAuthenticated, signinRedirect, getUser }) => {
        if (!!Component && isAuthenticated()) {
          (getUser() as any).then(console.log);
          return <Component {...props} />;
        } else {
          signinRedirect();
          return <span>loading.. </span>;
        }
      }}
    </AuthConsumer>
  );

  return <Route {...rest} render={renderFn(component)} />;
};

export const Callback = () => (
  <AuthConsumer>
    {({ signinRedirectCallback }) => {
      signinRedirectCallback();
      return <span>loading...</span>;
    }}
  </AuthConsumer>
);

export const Logout = () => (
  <AuthConsumer>
    {({ logout }) => {
      logout();
      return <span>..loading.. </span>;
    }}
  </AuthConsumer>
);

export const LogoutCallback = () => (
  <AuthConsumer>
    {({ signoutRedirectCallback }) => {
      signoutRedirectCallback();
      return <span>..loading... </span>;
    }}
  </AuthConsumer>
);

export const SilentRenew = () => (
  <AuthConsumer>
    {({ signinSilentCallback }) => {
      signinSilentCallback();
      return <span>...loading... </span>;
    }}
  </AuthConsumer>
);
