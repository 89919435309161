import * as React from "react";
import MitarbeiterApi from "../../api/MitarbeiterApi";
import { useMutation, useQuery } from "react-query";
import { Button,Grid, Segment } from "semantic-ui-react";
import { NeuesPasswortModel } from "../../generated";
import SimpleForm, { IFormField } from "../SimpleForm";
import * as Yup from "yup";
import useMessages from "../../hooks/useMessages";
import CommonApi from "../../api/CommonApi";
import { PasswordField } from "../PasswordField";
import { useHistory } from "react-router-dom";
import useDocumentTitle, { DOCUMENT_TITLES } from "../../hooks/useDocumentTitle";

interface INeuesPasswortDefault {
  altesPasswort: string;
  neuesPasswort: string;
  checkPasswort: string;
}


export const NeuesPasswort: React.FC<INeuesPasswortDefault> = (props) => {
  useDocumentTitle(DOCUMENT_TITLES.neuesPasswort)
  let history = useHistory();
  const commonVersions = useQuery(["getVersionGET"], () =>
    CommonApi.getVersionGET({ clearCache: false })
  );

  if (!commonVersions.isLoading && commonVersions.data && !commonVersions.data.loginUsed) {
    history.push({
      pathname: `/`
    });
  }

  let initModel: INeuesPasswortDefault = { altesPasswort: '', neuesPasswort: '', checkPasswort: '' };
  const [defaultPassword, setDefaultPassword] = React.useState<INeuesPasswortDefault>(initModel);
  const message = useMessages();
  const [changePassword] = useMutation(async (v: NeuesPasswortModel) => {
    const result = await MitarbeiterApi.passwortAendernPOST({
      neuesPasswortModel: {
        altesPasswort: v.altesPasswort,
        neuesPasswort: v.neuesPasswort
      }
    })
    setDefaultPassword(initModel);
    message.addMessage({ header: `Passwort erfolgreich gewechselt!` }, "success", true);
    return result;
  });

  const pwSchema = Yup.string()
    .required("Password is required")
    .notOneOf([Yup.ref("altesPasswort"), ""], "Bitte geben Sie ein neues Passwort ein!")
    .min(10, "Passwort muss mindestens 10 Zeichen enthalten")
    .max(20, "Passwort ist zu lang")
        .trim();
  const schema: IFormField[] = [
    {
      component: "div",
      props: {},
      fields: [
        {
          validation: Yup.string()
            .required("Bitte geben Sie ihr aktuelles Passwort ein"),
          props: {
            name: "altesPasswort",
            label: "Aktuelles Passwort",
            type: "password",
          },
          colSize: {
            lg: 12,
            md: 12,
            sm: 12,
            xs: 12
          }
        },
        {
          validation: pwSchema,
          component: PasswordField,
          props: {
            name: "neuesPasswort",
            label: "Neues Passwort",
            type: "password",
            autoComplete: "new-password",
            schema: pwSchema,
          },
          colSize: {
            lg: 12,
            md: 12,
            sm: 12,
            xs: 12
          }
        },
        {
          validation: Yup.string()
            .required("Bitte geben Sie ihr neues Passwort ein")
            .oneOf([Yup.ref("neuesPasswort"), ""], "Passwort nicht Identisch"),
          props: {
            name: "checkPasswort",
            label: "Passwort erneut eingeben",
            type: "password",
            autoComplete: "new-password",
          },
          colSize: {
            lg: 12,
            md: 12,
            sm: 12,
            xs: 12
          }
        }
      ]
    }
  ];

  return (
    <div className={
      commonVersions.data?.hostEnvironment === "Test" ? "brand" : ""
    }>
        <Grid >
          <Grid.Column mobile="1" computer="5"> </Grid.Column>
        <Grid.Column mobile="14" computer="6">
          <Segment>
            <h2>Passwort &auml;ndern</h2>
              <SimpleForm
                formSchema={schema}
                intialValues={defaultPassword}
                onSubmit={(v) => {
                    setDefaultPassword({ altesPasswort: v.altesPasswort, neuesPasswort: v.neuesPasswort, checkPasswort: v.checkPasswort });
                    changePassword({ altesPasswort: v.altesPasswort, neuesPasswort: v.neuesPasswort });
                }}
                renderSubmitButtons={({ submitForm }) => (
                  <>
                    <Button
                      floated="right"
                      icon="key"
                      size="medium"
                      content="Speichern"
                      onClick={submitForm}
                      secondary
                    />
                  </>
                )}
            />
          </Segment>
          </Grid.Column>
          <Grid.Column mobile="1" computer="5"> </Grid.Column>
        </Grid>

</div>
  );
};
