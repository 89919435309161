/* tslint:disable */
/* eslint-disable */
/**
 * coolTimeWatch API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EAZTyp {
    Anwesend = 'Anwesend',
    Kommen = 'Kommen',
    Gehen = 'Gehen',
    Zeitausgleich = 'Zeitausgleich',
    Frei = 'Frei',
    Urlaub = 'Urlaub',
    Feiertag = 'Feiertag',
    Krank = 'Krank',
    Sonderurlaub = 'Sonderurlaub',
    Betriebsfrei = 'Betriebsfrei',
    Sonstig = 'Sonstig',
    Null = 'Null',
    Montage = 'Montage',
    Dienstfrei = 'Dienstfrei',
    Tagesstempel = 'Tagesstempel',
    ReadOnly = 'ReadOnly'
}

export function EAZTypFromJSON(json: any): EAZTyp {
    return EAZTypFromJSONTyped(json, false);
}

export function EAZTypFromJSONTyped(json: any, ignoreDiscriminator: boolean): EAZTyp {
    return json as EAZTyp;
}

export function EAZTypToJSON(value?: EAZTyp | null): any {
    return value as any;
}

